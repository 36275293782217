<div>
<mat-toolbar class="top-toolbar">
  <mat-toolbar-row>
    <div>
      <a [routerLink]="'../'"><button mat-raised-button class="functional-button">
          <mat-icon class="header-mat-icon" title="Import Accruals">keyboard_arrow_left</mat-icon> Back
        </button></a>
    </div>
    <span class="header-page">{{replicateProgram ? 'Replicate Program' : 'Add Program'}}</span>
    <span class="example-spacer"></span>
    <div>
      <a [routerLink]="'../'"><button mat-raised-button class="functional-button"> Cancel</button></a>
      <button mat-raised-button class="functional-button" (click)="validateProgram();"
        [disabled]="!programForm.valid"> Save Draft</button>
        <button mat-raised-button class="functional-button-dark" *ngIf= "validateRouteForApprovalSaveRelease();" (click)="validateTotalExposure_OnSubmitAnother();"
        [disabled]="!programForm.valid"> {{buttonText ? 'Route for Approval' : 'Save and Release'}}</button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div class="main-cards">
  <form [formGroup]="programForm" (ngSubmit)="validateTotalExposure()">
    <mat-card class="card">
      <span class="span">
        <mat-icon class="matcard-icon" title="Program Details">assignment</mat-icon>
        <mat-card-header>Program Details </mat-card-header>
      </span>

      <mat-card-content>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>Program Name</mat-label>
            <input matInput maxlength="50"
              [ngClass]="{ 'is-invalid': (submitted && f.ShortName.errors) || f.ShortName.errors}" formControlName="ShortName"
              required>
            <mat-hint>Max 50 characters!</mat-hint>
            <mat-error *ngIf="f.ShortName.touched && f.ShortName.errors && !f.ShortName.errors.programNameExists">
              Program Name is required!</mat-error>
            <mat-error *ngIf="f.ShortName.errors && !f.ShortName.errors.required && f.ShortName.errors.programNameExists">
              Program Name already exists!</mat-error>
          </mat-form-field>
          <mat-form-field class="col-md-3 readonly">
            <mat-label>Program Code</mat-label>
            <input matInput formControlName="ProgramCode" readonly>
          </mat-form-field>
            <mat-form-field class="col-md-3 readonly" *ngIf="!isAdmin() || !replicateProgram ">
              <mat-label>Program Manager</mat-label>
              <input matInput formControlName="ProgramManager" readonly>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="isAdmin() && replicateProgram">
              <mat-label>Program Manager</mat-label>
              <mat-select  formControlName="ProgramManager" >
                <mat-option *ngFor="let programManager of programManagerList" [value]="programManager.userName">
                  {{programManager.userName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <br>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>Consistent Program Name</mat-label>
            <input type="text" placeholder="Search Program Name" aria-label="Number" matInput formControlName="ConsistentName"
              [matAutocomplete]="consistentName">
            <mat-autocomplete autoActiveFirstOption #consistentName="matAutocomplete">
              <mat-option *ngFor="let name of filteredconsistentNameList  | async" [value]="name">
                {{name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <br>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-12">
            <mat-label>Program Description</mat-label>
            <textarea matInput [ngClass]="{ 'is-invalid': submitted && f.ProgramDesc.errors }"
              formControlName="ProgramDesc"></textarea>
            <mat-error *ngIf="submitted && f.ProgramDesc.errors && f.ProgramDesc.errors.required">Program Description is
              required!</mat-error>
          </mat-form-field>
        </div>
        <br>
        <div class="dash"></div>
        <br>
        <div class="row">
          <div *ngIf="replicateProgram " class="col-md-6">
            <label>{{programCategory}}</label>
          </div>
          <section class="programTypeSection" *ngIf="!replicateProgram">
            <mat-checkbox class="col-md-12" [checked]="selected == 1" [disabled]="isProgramBrevant"
              formControlName="IsExternalAccruingProgram"
              (change)="selected = 1; externallyAccruingProgramTypeOptions($event.checked)">This program is externally
              accruing</mat-checkbox> <br>
            <span *ngIf="showExternalProgramTypeOptions">
              <mat-radio-group class="col-md-12" formControlName="ExternalAccruingProgramType" [disabled]="isProgramBrevant"
                (change)="externalAccruingOptionsChanged($event)">
                <mat-radio-button value="RoutingOnly">Routing Only (Marketing program)</mat-radio-button>
                <mat-radio-button value="ExpensePA">Expense P&A (Not a marketing program)</mat-radio-button>
              </mat-radio-group>
            </span>
            <br>
            <mat-checkbox class="col-md-12" [checked]="selected == 2" [disabled]="isProgramBrevant"
              (change)="selected = 2; AccruesUnderProgramTypeOptions($event.checked)">This program accrues under another
              program</mat-checkbox>
            <span *ngIf="showAccruesUnderAnotherProgramTypeOptions">
              <div class="row">
                <mat-form-field class="col-md-12" class="programTypeSectionFullwidth" mat-elevation-z0>
                  <input type="text" placeholder="Search Program ID or Name" aria-label="Number" matInput
                    formControlName="NonAccruingProgramTypeName" [matAutocomplete]="auto" [(ngModel)]="SearchProgram"
                    (ngModelChange)="onParentProgramSelectionChanged()">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option class="multipleLine" *ngFor="let option of filteredParentProgramsList | async"
                      [value]="option.shortName">
                      {{option.shortName}} <br />
                      <p>{{option.code}}</p>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </span>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <span class="span">
        <mat-icon class="matcard-icon" title="Program Attributes">apartment</mat-icon>
        <mat-card-header>Program Attributes </mat-card-header>
      </span>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-md-6 readonly" *ngIf="IsExternalAccruingOrStandardProgram">
            <mat-label>Company Name</mat-label>
            <input matInput formControlName="CompanyCode" readonly>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-6" *ngIf="!IsExternalAccruingOrStandardProgram">
            <mat-label>Company Name</mat-label>
            <mat-select [ngClass]="{ 'is-invalid': submitted && f.CompanyCode.errors }" formControlName="CompanyCode" (selectionChange)="onCompanyCodeChange()" required>
              <ng-container *ngFor="let companyCode of companyCodeList">
                <mat-option *ngIf="companyCode.active == true" [value]="companyCode.id">
                  {{companyCode.shortDesc}}
                </mat-option>
              </ng-container>         
            </mat-select>
            <mat-error *ngIf="f.CompanyCode.errors">Company Name is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>Program Channel</mat-label>
            <mat-select [ngClass]="{ 'is-invalid': submitted && f.Channel.errors }" formControlName="Channel">
              <mat-option *ngFor="let channel of channelList" [value]="channel.id">
                {{channel.shortDesc}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && f.Channel.errors && f.Channel.errors.required">Program Channel is required!
            </mat-error>
          </mat-form-field>
        </div> <br>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>Business Segment</mat-label>
            <mat-select [ngClass]="{ 'is-invalid': submitted && f.BusinessSegments.errors }"
              formControlName="BusinessSegments" multiple>
              <ng-container *ngFor="let businessSeg of businessSegmentList">
                <mat-option *ngIf="businessSeg.active == true" type="text" [value]="businessSeg.id">
                  <span [innerHTML]="businessSeg.shortDesc"></span>
                </mat-option>
              </ng-container>
            </mat-select>
            <mat-error *ngIf="submitted && f.BusinessSegments.errors && f.BusinessSegments.errors.required">Business
              Segment is required!</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-6">
            <mat-label>Program Type</mat-label>
            <mat-select [ngClass]="{ 'is-invalid': submitted && f.TypeDefinition.errors }"
              formControlName="TypeDefinition">
              <mat-option *ngFor="let typeDefinition of programTypeList" [value]="typeDefinition.id">
                {{typeDefinition.shortDesc}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && f.TypeDefinition.errors && f.TypeDefinition.errors.required">Program Type is
              required!</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <span class="span">
        <mat-icon class="matcard-icon" title="Program Dates">date_range</mat-icon>
        <mat-card-header>Program Dates </mat-card-header>
      </span>
      <mat-card-content>
        <div class="row">
          <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
            <mat-label>Start Date</mat-label>
            <input [min]="minStartDate" [max]="maxStartDate" [matDatepickerFilter]="onlyFirstDay" readonly
              (dateChange)="startDateChange()" matInput [matDatepicker]="picker1"
              [ngClass]="{ 'is-invalid': (submitted && f.BeginDate.errors) || f.BeginDate.errors }"
              formControlName="BeginDate" required>
            <mat-error *ngIf="f.BeginDate.errors?.required">Start Date is required!</mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 color="#1A81E1"></mat-datepicker>
            <mat-error *ngIf="f.BeginDate.errors?.matDatepickerMin || f.BeginDate.errors?.matDatepickerMax">Start Date
                is Invalid!</mat-error>
          </mat-form-field>

          <mat-form-field color="#1A81E1" appearance="fill" class="col-md-4">
            <mat-label>End Date</mat-label>
            <input matInput [min]="minEndDate" [max]="maxEndDate" [matDatepickerFilter]="onlyLastDay" readonly
              [matDatepicker]="picker2"
              [ngClass]="{ 'is-invalid': (submitted && f.EndDate.errors) || f.EndDate.errors }"
              formControlName="EndDate" required>
            <mat-error *ngIf="f.EndDate.errors?.required">End Date is required!</mat-error>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 color="#1A81E1"></mat-datepicker>
            <mat-error *ngIf="f.EndDate.errors?.matDatepickerMin || f.EndDate.errors?.matDatepickerMax">End Date is
                Invalid!</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>Program Year</mat-label>
            <mat-select [ngClass]="{ 'is-invalid': submitted && f.ProgramYear.errors }" formControlName="ProgramYear"
              required>
              <mat-option *ngFor="let yearValue of yearList" [value]="yearValue.id">
                {{yearValue.shortDesc}}
              </mat-option>
            </mat-select>
            <mat-error>Program Year is required!</mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <span class="span">
        <mat-icon class="matcard-icon" title="Financial Assumptions">account_balance</mat-icon>
        <mat-card-header *ngIf="!showParentProgramFA">Financial Assumptions </mat-card-header>
        <mat-card-header *ngIf="showParentProgramFA">Parent Program Financial Assumptions </mat-card-header>
        <label class="lblparentprog" *ngIf="showParentProgramFA && parentProgCode!=''">The Parent program (<a
            [routerLink]="" (click)="gotoParent()">{{parentProgCode}} </a>) is in <b>{{parentProgStatus}}</b>
          status</label>
      </span>
      <mat-card-content>
        <div class="row">
          <div class="col-md-12" >
            <mat-checkbox class="financial-override-checkbox"  formControlName="CalculatedTotals" hidden  *ngIf="isStandardOrParentTypeProgram &&  f.NonAccruingProgramTypeName.value == ''">Update Total Sales and Total Exposure</mat-checkbox>
          </div>
        </div>  
        <div class="row">
          <mat-form-field *ngIf="!isStandardOrParentTypeProgram" appearance="fill" class="col-md-6 ">
            <mat-label>Total Sales (Local Currency)</mat-label>
            <input (keypress)="restrictChar($event);" maxlength="13" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }"
              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales">
            <span matPrefix>$&nbsp;</span>
            <mat-error>Total Sales is required!</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isStandardOrParentTypeProgram &&  f.NonAccruingProgramTypeName.value == '' " appearance="fill" class="col-md-6 ">
            <mat-label>Total Sales (Local Currency)</mat-label>
            <input (keypress)="restrictChar($event);" maxlength="13" type="text" (keyup)="formStandardProgramTotalsChange();"
            [ngClass]="{ 'is-invalid': submitted && f.TotalSales.errors }"
            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalSales">
            <span matPrefix>$&nbsp;</span>
            <mat-error>Total Sales is required!</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isStandardOrParentTypeProgram && f.NonAccruingProgramTypeName.value != ''" class="col-md-6 readonly">
            <mat-label>Total Sales (Local Currency)</mat-label>
            <input matInput formControlName="TotalSales"
              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-6" *ngIf="!isStandardOrParentTypeProgram">
            <mat-label>Total Exposure (Local Currency)</mat-label>
            <input (keypress)="restrictChar($event);" maxlength="11" type="text"
              [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }"
              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput
              formControlName="TotalExposure">
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="submitted || f.TotalExposure.errors">Total Exposure is required!</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isStandardOrParentTypeProgram &&  f.NonAccruingProgramTypeName.value == '' " appearance="fill" class="col-md-6 ">
            <mat-label>Total Exposure (Local Currency)</mat-label>
            <input (keypress)="restrictChar($event);" maxlength="11" type="text" (keyup)="formStandardProgramTotalsChange();"
            [ngClass]="{ 'is-invalid': submitted && f.TotalExposure.errors }"
            [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" matInput formControlName="TotalExposure">
            <span matPrefix>$&nbsp;</span>
            <mat-error *ngIf="submitted || f.TotalExposure.errors">Total Exposure is required!</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="isStandardOrParentTypeProgram && f.NonAccruingProgramTypeName.value != ''" class="col-md-6 readonly">
            <mat-label>Total Exposure (Local Currency)</mat-label>
            <input matInput formControlName="TotalExposure"
              [cleave]="{numeral: true, numeralThousandsGroupStyle: 'thousand'}" readonly>
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="card">
      <span class="span">
        <mat-icon class="matcard-icon" title="Attachments">attach_file</mat-icon>
        <mat-card-header>Attachments </mat-card-header>
      </span>
      <mat-card-content>
        <app-file-upload formControlName="FileUpload"></app-file-upload>
      </mat-card-content>
    </mat-card>
  </form>
  <ngx-spinner class="spinner" size="default" type="ball-spin-clockwise">
    <p>Please Wait.</p>
  </ngx-spinner>
</div>
</div>