import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppComponent } from '../../app.component';
import { CoreRepository } from '../../core/core.repository';
import { EmailService } from '../../core/services/email/email.service';
import { StatusDefinitionIds, StatusDefinitionShortDesc, StatusDefinitionUIShortDesc } from '../../core/enums/statusDefinitionEnums';
import { ProgramTableTypeIds } from '../../core/enums/programTableTypeEnums';
import { FinancialAssumptionErrors, AccrualsMessages, AttachmentErrors } from '../../core/enums/messageEnums';
import { EmailDefinitionIds } from '../../core/enums/emailDefinitionEnums';
import { ActionMessages } from '../../core/enums/emailEnums';
import { ProgPortalContextService } from '../../core/services/progportalcontextservice';
import { DialogWarningMessageComponent, Messages } from '../../dialogs/dialog-warning-message/dialog-warning-message.component';
import { EmailOptions } from '../../core/services/email/models/email-options.model';
import { RouteParamsService } from '../../core/services/route-params.service';
import { CompanyCodeShortDesc } from '../../core/enums/companyCodeEnums';
import { HttpHeaders } from '@angular/common/http';
import { ProgramCategories } from '../../core/enums/programCategoryEnums';
import { BusinessSegmentIds, BusinessSegmentShortDesc } from '../../core/enums/businessSegmentEnums';
import { MatAccordion } from '@angular/material/expansion';
import { Location } from '@angular/common'
import { DialogChangeExceptionExplanationComponent } from '../../programs/dialog-changeexception-explanation/dialog-changeexception-explanation.component';
import { DialogConfirmDeleteProgramComponent} from '../../programs/dialog-confirm-delete-program/dialog-confirm-delete-program.component';

export interface ProgramApproversEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programApproverId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export interface DoaUsers {
  doaUserId: number;
  appUserId: number;
  userId: string;
  userName: string;
  lastName: string;
  firstName: string;
  email: string;
  approver: boolean;
}
export interface ProgramToProductManagerAssoc {
  id?: number;
  programId: number;
  productManagerId: number;
  openEnrolled: boolean;
  updateBy: string;
}
export interface Message {
  updateBy: string;
  insertDate: Date;
  message: string;
  doaRole: string;
  statusDefinitionId: number;
}

export interface Status {
  statusDefinition: string;
  appUser: string;
  role: string;
  insertDate: Date;
}

export interface ProgramManagersEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programManagerId: number;
  emailOptions?: any;
  changeExceptionId?: any;
  changeException: boolean;
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@Component({
  selector: 'app-admin-edit-program',
  templateUrl: './admin-edit-program.component.html',
  styleUrls: ['./admin-edit-program.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    }
  ]
})

export class AdminEditProgramComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  @ViewChild('accordion') Accordion: MatAccordion;
  displayedColumns: string[] = ['productManager', 'email', 'status', 'daysOldSinceEmailNotification'];
  programForm: FormGroup;
  submitted = false;
  statusList: any;
  appUserList: any;
  programManagerList: any;
  channelList: any;
  yearList: any;
  businessSegmentList: any;
  programTypeList: any;
  companyCodeList: any;
  consistentNameList: any[];
  filteredconsistentNameList: Observable<any[]>;
  cancelSubject = new Subject();
  prodmgrs: any[] = [];
  prodmgrids: any[] = [];
  prodmgremails: any[] = [];
  strprodmgrids: any;
  showProgramTypeOptions: boolean;
  parentProgramsList: any[];
  filteredParentProgramsList: Observable<any[]>;
  programControl = new FormControl();
  programId: number;
  externalAccruingProgramId: number;
  isStandardOrParentTypeProgram: boolean;
  showParentProgram: boolean = false;
  showExpandButton = false;
  SearchProgram: any;
  status: number;
  businessSegSelected: string[];
  buttonText: boolean = false;
  programRouteforApproval: boolean = false;
  histories = [];
  approvals = [];
  approvalsCount: number;
  showExternalProgramTypeOptions: boolean;
  showAccruesUnderAnotherProgramTypeOptions: boolean;
  selected = -1;
  selectedRadioBtn = -1;
  selectedCalculatedTotals = -1;
  ConsistentName: any;
  programConsistentName: any;
  progCode: any;
  programStatus: any;
  createdDate: Date;
  changeExceptionApprovedDate?: Date;
  changeExceptionCreatedDate?: Date;
  isNewChangeException: boolean;
  isNewExplanation: boolean;
  isNonDOANewExplanation: boolean;
  programYear: any;
  totalSales: any;
  totalExposure: any;
  startDate: Date;
  endDate: Date;
  previousVersionStartDate: Date;
  previousVersionEndDate: Date;
  explanation: string;
  message: any;
  fileDetailsList: any[] = [];
  deleteFiles: string[] = [];
  parentProgramCode: any;
  parentProgramStatus: any;
  parentProgramShortName: any;
  currentTotalSales: any;
  currentTotalExposure: any;
  parentProgramStatusDefinitionId: any;
  adminUserName: string;
  warningMessages: Messages[] = [];
  financialAssumptionErrors = FinancialAssumptionErrors;
  attachmentErrors = AttachmentErrors;
  saveAndProceed = true;
  programTableType: number;
  statusDefinitionId: any;
  companyCode: any;
  datesChangeStatusDefinitionId: number;
  sendDateChangeEmail: boolean;
  accrualsMessages = AccrualsMessages;
  statusDefinitionIds = StatusDefinitionIds;
  companyCodeShortDesc = CompanyCodeShortDesc;
  statusDefinitionShortDesc = StatusDefinitionShortDesc;
  statusDefinitionUIShortDescs = StatusDefinitionUIShortDesc;
  dropdownStatusDefinitions = dropdownStatusDefinitions;
  availableStatusDefinitions: StatusDefinition[] = [];
  childProgramCodes: any;
  routingApproversEmails: ProgramApproversEmail[] = [];
  approversEmails: any[] = [];
  programTableTypeIds = ProgramTableTypeIds;
  programCategories = ProgramCategories;
  emailDefinitionIds = EmailDefinitionIds;
  actionMessages = ActionMessages;
  approversEmailOptions = new EmailOptions();
  doaUsers: DoaUsers[] = [];
  doaApprovers: DoaApprovers[] = [];
  routingStartDate?: Date;
  routingCanceledDate?: Date;
  emailOptions = new EmailOptions();
  dateChangeEmailOptions = new EmailOptions();
  emailNotifications: EmailNotification[] = [];
  dataSourceEmailNotifications: MatTableDataSource<EmailNotification>;
  programToProductManagerAssoc: ProgramToProductManagerAssoc[] = [];
  updatedProdmgrids: any[] = [];
  parentProgCode: string = "";
  programShortName: any;
  isChangeException: boolean = false;
  isExistingChangeException: boolean = false;
  changeExceptionId?: number;
  isCalculatedTotals: boolean;
  isReadonly: boolean;
  calculatedTotalSales: number;
  calculatedTotalExposure: number;
  masterProgramSave: boolean = false;
  changeExceptionExplanation: boolean = false;
  messagePosted: boolean = false;
  programDesc: any;
  businessSegments: any;
  existingInActiveRouting: boolean;
  programManagersEmailOptions = new EmailOptions();
  programManagerAppUserId: number;
  programManagersEmail: ProgramManagersEmail[] = [];
  changeExceptionProgramsList: ChangeExceptionProgramDetails[] = [];
  masterProgramCode: string;
  isProgramAccrualsTotalSalesAndTotalExposureUpdate: boolean;
  showNonAccruingProgramRefresh: boolean = true;
  showHistoryTab: boolean = true;
  showPostButton: boolean = false;
  doaMaxApprovalOrder: number;
  messageList: Message[] = [];
  statusesList: Status[] = [];
  businessSegmentShortDesc = BusinessSegmentShortDesc;
  childBundleRoutingProgramList: any;
  bundleRoute:boolean;
  isFormControlChange: boolean;
  private previousUrl: string = '';
  private currentUrl: string = '';
  totalSalesAndTotalExposureList: any[]=[];
  selectedProgramStatus: string[] = [];
  adminProgram = new AdminProgram();
  deleteCheckResponse: string = '';

  
  constructor(private fb: FormBuilder,
    public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    public emailService: EmailService,
    private core: CoreRepository,
    private router: Router,
    public app: AppComponent,
    private route: ActivatedRoute,
    private routeParamsService: RouteParamsService,
    private location: Location
  ) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
  }

  ngOnInit(): void {
    let parmId = this.route.snapshot.paramMap.get('id');
    if (parmId) {
      this.progCode = parmId.toString().includes('-') ? parmId : parseInt(parmId);
    } 
    this.businessSegSelected = [];
    this.prodmgrs = [];
    this.programForm = this.fb.group({
      Id: [''],
      ProgramCode: ['--'],
      ShortName: ['', {
        validators: [Validators.required]
      }],
      ConsistentName: [''],
      Explanation: [''],
      ReadOnlyExplanation: [''],
      ProgramDesc: [''],
      TypeDefinition: [''],
      StatusDefinition: [''],
      ProgramStatus: [''],
      Channel: [''],
      AppUser: [this.core.getUsername()],
      ProgramYear: ['', Validators.required],
      TotalSales: [],
      TotalExposure: [],
      CurrentTotalSales: [],
      CurrentTotalExposure: [],
      CalculatedTotals: [''],
      ChangeException: [''],
      BeginDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      BundleRoute: [''],
      CreatedOn: [''],
      ApprovedOn: [''],
      NewProgramCode: [''],
      ProgramManager: [this.core.getUserAccountName()],
      BusinessSegments: [this.businessSegSelected],
      CompanyCode: [''],
      FileUpload: [''],
      DeleteFiles: [''],
      ExternalAccruingProgramType: [''],
      NonAccruingProgramType: [''],
      ProgramTypeOptions: '',
      ProgramTypeOptionsParent: [''],
      EmailOptions: [''],
      DateChangeEmailOptions: [''],
      DOAUsers: [[]],
      ProgramToProductManagerAssoc: [[]],
      ChildBundleRoutingPrograms: [[]]
    });
    
    this.programForm.controls.TotalExposure.valueChanges.subscribe(value => {
      let previousApprovedTotalExposure = this.f.TotalExposure.value.toString().includes(',') ? parseInt(this.programForm.controls.TotalExposure.value.replaceAll(',', '')) : this.programForm.controls.TotalExposure.value;
      let currentTotalExposure = 0;
      let diff;
      if (this.totalSalesAndTotalExposureList.length > 0) {
        currentTotalExposure = this.totalSalesAndTotalExposureList[0].totalExposure.toString().includes(',') ? parseInt(this.totalSalesAndTotalExposureList[0].totalExposure.replaceAll(',', '')) : this.totalSalesAndTotalExposureList[0].totalExposure;
      } else {
        currentTotalExposure = this.currentTotalExposure;
      }
    });
    this.binddropdowns();
    this.dataSourceEmailNotifications = new MatTableDataSource([]);
    this.isStandardOrParentTypeProgram = false;
  }


  async prepopulateProgram(progCode) {
    await this.context.getdatafromService(`/api/Program/List/ParentChildList/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.childBundleRoutingProgramList = response;
        this.childBundleRoutingProgramList = this.childBundleRoutingProgramList.filter(element => (element.isParent && !element.bundleRoute) || (!element.isParent && element.bundleRoute))
      }
    });

    this.showExternalProgramTypeOptions = false;
    this.context.getdatafromService(`/api/Program/Option/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.programForm.controls.Id.setValue(response.id);
        this.programForm.controls.ProgramCode.setValue(response.programCode);
        this.programForm.controls.ShortName.setValue(response.shortName);
        this.programForm.controls.ProgramManager.setValue(response.programManager);
        this.programForm.controls.ConsistentName.setValue(response.consistentName);
        this.programForm.controls.ProgramDesc.setValue(response.programDesc);
        var companyCode = this.companyCodeList.filter(c => c.id == response.companyCode);
        this.programForm.controls.CompanyCode.setValue(companyCode[0].shortDesc);
        if (response.businessSegments != BusinessSegmentIds.Mycogen) {
          this.businessSegmentList = this.businessSegmentList.filter(f => f.active == true);
        }
        var businessSegmentDesc = this.businessSegmentList.filter(b => b.id == response.businessSegments[0]);
        this.programForm.controls.BusinessSegments.setValue(businessSegmentDesc[0].shortDesc);
        this.programForm.controls.TypeDefinition.setValue(response.typeDefinition.id === 0 ? null : response.typeDefinition.shortDesc);
        this.programForm.controls.Channel.setValue(response.channel.id === 0 ? null : response.channel.shortDesc);
        this.programForm.controls.BeginDate.setValue(moment(new Date(response.beginDate)));
        this.programForm.controls.EndDate.setValue(moment(new Date(response.endDate)));
        this.programForm.controls.BundleRoute.setValue(response.bundleRoute);
        this.programForm.controls.ProgramYear.setValue(response.programYear.id);
        this.programForm.controls.TotalSales.setValue(response.totalSales);
        this.programForm.controls.TotalExposure.setValue(response.totalExposure);
        this.programForm.controls.ChangeException.setValue(false);
        this.programForm.controls.ExternalAccruingProgramType.setValue(response.externalAccruingProgramType);
        this.programForm.controls.NonAccruingProgramType.setValue(response.nonAccruingProgramType);
        this.programForm.controls.CalculatedTotals.setValue(response.CalculatedTotals);
        
        dropdownStatusDefinitions.forEach((element) => {
          this.availableStatusDefinitions.push(element);
        })
        const statusFound = this.availableStatusDefinitions.some(s => s.id == response.statusDefinition.id);
        if (!statusFound) {
          const addStatus: StatusDefinition = {
            id: response.statusDefinition.id,
            code: response.statusDefinition.code
          }
          this.availableStatusDefinitions.push(addStatus);
        }

        this.programForm.controls.StatusDefinition.setValue(response.statusDefinition.id);

        this.programForm.controls.ProgramStatus.setValue(response.statusDefinition.code);
        this.changeExceptionApprovedDate = null;
        this.changeExceptionCreatedDate = null;
        this.programShortName = response.shortName;
        this.programConsistentName = response.consistentName;
        this.programDesc = response.programDesc;
        this.programStatus = response.statusDefinition.code;
        this.businessSegments = response.businessSegments;
        this.createdDate = response.createdDate;
        this.fileDetailsList = response.fileDetailsList;
        this.programId = response.id;
        this.startDate = new Date(response.beginDate);
        this.endDate = new Date(response.endDate);
        this.programYear = response.programYear.id;
        this.calculatedTotalSales = response.calculatedTotalSales;
        this.calculatedTotalExposure = response.calculatedTotalExposure;
        this.totalSales = response.totalSales;
        this.totalExposure = response.totalExposure;
        this.parentProgramCode = response.parentProgramCode;
        this.parentProgramShortName = response.parentProgram != undefined && response.parentProgram !== null ? response.parentProgram.shortName : null;
        this.statusDefinitionId = response.statusDefinition.id;
        this.currentTotalExposure = response.totalExposure;
        this.currentTotalSales = response.totalSales;
        this.datesChangeStatusDefinitionId = response.statusDefinition.id;
        this.parentProgramStatusDefinitionId = response.parentProgramStatusDefinitionId;
        this.parentProgramStatus = this.statusDefinitionShortDesc['_' + response.parentProgramStatusDefinitionId];
        this.childProgramCodes = response.childProgramCodes;
        this.masterProgramCode = '';
        this.isNewChangeException = false;
        this.showParentProgram = false;
        this.doaMaxApprovalOrder = response.doaApproversList.reduce((op, item) => op = op > item.approvalOrder ? op : item.approvalOrder, 0);
        this.doaApprovers = response.doaApproversList;
        this.bundleRoute = response.bundleRoute;
        this.routingStartDate = response.routingStartDate;
        this.routingCanceledDate = response.routingCanceledDate;
        this.existingInActiveRouting = response.existingInActiveRouting;
        this.messageList = response.messageslist;
        this.statusesList = response.statusList;
        this.programManagerAppUserId = response.appUser.id;
        this.changeExceptionProgramsList = response.changeExceptionProgramDetails && response.changeExceptionProgramDetails.length > 0 ? response.changeExceptionProgramDetails : [];
        response.changeExceptionProgramDetails.forEach(cep => {
          if (cep.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            cep.approvedDate = new Date(cep.approvedDate).toLocaleDateString();
          }
          else {
            cep.approvedDate = '';
          }
        })
        if (this.messageList.length > 0) {
          this.showExpandButton = true;
        }
        if (response.statusDefinition.id == this.statusDefinitionIds.APPROVED) {
          this.programForm.controls.TotalExposure.setValue(response.totalExposure);
          this.programForm.controls.TotalSales.setValue(response.totalSales);          
        }
        let startDateCurrentYear = this.startDate.getFullYear();
        let startDateCurrentMonth = this.startDate.getMonth();
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
   
        //removed start date and end date validation for Change exception to allow users to edit CE's after the end date. 
        this.startDateChange(); 
        this.programForm.controls.BeginDate.clearValidators();
        this.programForm.controls.BeginDate.updateValueAndValidity();

        if (!this.yearList.some(w => w.id == this.programYear)) {
          this.yearList.push(response.programYear);
        }
        if (response.programProductManager) {
          this.prodmgrs = response.programProductManager.map(x => x.name);
          this.prodmgrids = response.programProductManager.map(x => x.id);
          this.prodmgremails = response.programProductManager.map(x => x.email);
        }
        if (this.core.getUserAccountName() == this.programForm.controls.ProgramManager.value) {
          this.showPostButton = true;
        }

        switch (response.programTableType) {
          case this.programTableTypeIds.Program:
            this.programTableType = this.programTableTypeIds.Program;
            this.isCalculatedTotals = !response.calculatedTotals;
            this.isReadonly = response.calculatedTotals;

            if (response.calculatedTotals) {
              this.programForm.controls.TotalSales.setValue(this.calculatedTotalSales);
              this.programForm.controls.TotalExposure.setValue(this.calculatedTotalExposure);
            }
            else {
              this.programForm.controls.TotalSales.setValue(this.totalSales);
              this.programForm.controls.TotalExposure.setValue(this.totalExposure);
            }
            break;
          case this.programTableTypeIds.ExternalAccruingProgram:
            this.programTableType = this.programTableTypeIds.ExternalAccruingProgram;
            if (response.externalAccruingProgramType == this.programCategories._1 || response.externalAccruingProgramType == this.programCategories._2) {
              this.selected = 1;
              this.showExternalProgramTypeOptions = true;
              if (response.externalAccruingProgramType == this.programCategories._1) {
                this.selectedRadioBtn = 1;
              }
              else if (response.externalAccruingProgramType == this.programCategories._2) {
                this.selectedRadioBtn = 2;
              }
            }
            this.buttonText = true;
            break;
          case this.programTableTypeIds.NonAccruingProgram:
            this.programTableType = this.programTableTypeIds.NonAccruingProgram;
            this.selected = 2;
            this.programForm.controls.ProgramTypeOptionsParent.setValue(response.parentProgram.changeExceptionCode !== '' && response.parentProgram.changeExceptionCode !== null ? response.parentProgram.changeExceptionCode + ' - ' + response.parentProgram.shortName : response.parentProgram.code + ' - ' + response.parentProgram.shortName);
            this.parentProgramCode = response.parentProgram.changeExceptionCode !== '' && response.parentProgram.changeExceptionCode !== null ? response.parentProgram.changeExceptionCode : response.parentProgram.code;
            if (this.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
              this.programForm.controls.TotalSales.setValue(response.parentProgram.previousTotalSales);
              this.programForm.controls.TotalExposure.setValue(response.parentProgram.previousTotalExposure);
            }
            this.showParentProgram = true;
            this.buttonText = true;
            if (this.parentProgramCode.toString().includes('-') || companyCode[0].shortDesc === CompanyCodeShortDesc._2) {
              this.showNonAccruingProgramRefresh = false;
            }
            break;
        }

        if (response.statusDefinition.id === this.statusDefinitionIds.ACCRUALS_COMPLETED || companyCode[0].shortDesc === CompanyCodeShortDesc._2) {
          this.buttonText = true;
        }
        this.checkStandardOrParentTypeProgram();

        switch (response.statusDefinition.id) {
          case this.statusDefinitionIds.DENIED:
            this.masterProgramSave = false;
            break;
          case this.statusDefinitionIds.APPROVAL_ROUTING:
            this.masterProgramSave = false;
            break;
          case this.statusDefinitionIds.APPROVED:
            this.masterProgramSave = false;
            this.buttonText = true;
            this.isReadonly = response.calculatedTotals;
            break;
          case this.statusDefinitionIds.ACCRUALS_PENDING:
            this.masterProgramSave = true;
            this.buttonText = false;
            break;
          case this.statusDefinitionIds.ACCRUALS_COMPLETED:
            this.masterProgramSave = true;
            this.buttonText = true;
            break;
          case this.statusDefinitionIds.DRAFT:
            this.masterProgramSave = true;
            break;
        }
        if (response.totalSalesAndTotalExposure) { this.totalSalesAndTotalExposureList = response.totalSalesAndTotalExposure; }
        this.bindProductMangersAccrualsStatus();
      }
      this.spinner.hide();
    });
  }

  changeExceptionPrepopulateProgram(progCode) {
    this.showExternalProgramTypeOptions = false;
    this.context.getdatafromService(`/api/Program/ChangeException/Details/${this.progCode}`).toPromise().then((response) => {
      if (response != null) {
        this.programForm.controls.Id.setValue(response.id);
        this.programForm.controls.ProgramStatus.setValue(response.statusDefinition.shortDesc);
        this.programForm.controls.Explanation.setValue(response.explanation);
        this.programForm.controls.ReadOnlyExplanation.setValue(response.explanation);
        this.programForm.controls.CreatedOn.setValue(new Date(response.createdDate).toLocaleDateString());
        this.programForm.controls.ApprovedOn.setValue(response.changeExceptionApprovedDate != null ? new Date(response.changeExceptionApprovedDate).toLocaleDateString() : '');
        this.programForm.controls.ProgramCode.setValue(response.code);
        this.programForm.controls.ShortName.setValue(response.shortName);
        this.programForm.controls.ProgramManager.setValue(response.programManager);
        this.programForm.controls.ConsistentName.setValue(response.consistentName);
        this.programForm.controls.ProgramDesc.setValue(response.desc);
        this.programForm.controls.BundleRoute.setValue(false);
        var companyCode = this.companyCodeList.filter(c => c.id == response.companyCode);
        this.programForm.controls.CompanyCode.setValue(companyCode[0].shortDesc);
        if (response.businessSegments != BusinessSegmentIds.Mycogen) {
          this.businessSegmentList = this.businessSegmentList.filter(f => f.active == true);
        }
        var businessSegmentDesc = this.businessSegmentList.filter(b => b.id == response.businessSegments[0]);
        this.programForm.controls.BusinessSegments.setValue(businessSegmentDesc[0].shortDesc);
        this.programForm.controls.TypeDefinition.setValue(response.typeDefinition.shortDesc);
        this.programForm.controls.Channel.setValue(response.channel.shortDesc);
        this.programForm.controls.BeginDate.setValue(moment(new Date(response.beginDate)));
        this.programForm.controls.EndDate.setValue(moment(new Date(response.endDate)));
        this.programForm.controls.ProgramYear.setValue(response.programYear.id);
        this.programForm.controls.TotalSales.setValue(response.currentTotalSales);
        this.programForm.controls.TotalExposure.setValue(response.currentTotalExposure);
        this.programForm.controls.ChangeException.setValue(true);
        this.programForm.controls.ExternalAccruingProgramType.setValue(response.externalAccruingProgramType);
        this.programForm.controls.NonAccruingProgramType.setValue(response.nonAccruingProgramType);
        this.programForm.controls.CalculatedTotals.setValue(response.CalculatedTotals);
        
        dropdownStatusDefinitions.forEach((element) => {
          this.availableStatusDefinitions.push(element);
        })
        const statusFound = this.availableStatusDefinitions.some(s => s.id == response.statusDefinition.id);
        if (!statusFound) {
          const addStatus: StatusDefinition = {
            id: response.statusDefinition.id,
            code: response.statusDefinition.code
          }
          this.availableStatusDefinitions.push(addStatus);
        }

        this.programForm.controls.StatusDefinition.setValue(response.statusDefinition.id);
        this.programForm.controls.ProgramStatus.setValue(response.statusDefinition.code);
        this.programShortName = response.shortName;
        this.programConsistentName = response.consistentName;
        this.programStatus = response.statusDefinition.code;
        this.programDesc = response.desc;
        this.businessSegments = response.businessSegments;
        this.createdDate = response.createdDate;
        this.explanation = response.explanation != null ? response.explanation : '';
        this.changeExceptionId = response.id;
        this.changeExceptionApprovedDate = response.changeExceptionApprovedDate;
        this.changeExceptionCreatedDate = response.changeExceptionCreatedDate;
        this.fileDetailsList = response.fileName;
        this.programId = response.masterProgramId;
        this.startDate = new Date(response.beginDate);
        this.endDate = new Date(response.endDate);        
        this.parentProgramCode = response.parentProgramCode;
        this.parentProgramShortName = response.parentProgramShortName;
        this.statusDefinitionId = response.statusDefinition.id;
        this.datesChangeStatusDefinitionId = response.statusDefinition.id;
        this.companyCode = companyCode[0].shortDesc;
        this.parentProgramStatusDefinitionId = response.parentProgramStatusDefinitionId;
        this.parentProgramStatus = this.statusDefinitionShortDesc['_' + response.parentProgramStatusDefinitionId];
        this.childProgramCodes = response.childProgramCodes;
        this.programYear = response.programYear.id;
        this.calculatedTotalSales = response.calculatedTotalSales;
        this.calculatedTotalExposure = response.calculatedTotalExposure;
        this.currentTotalSales = response.currentTotalSales;
        this.currentTotalExposure = response.currentTotalExposure;
        this.isChangeException = true;
        this.isExistingChangeException = true;
        this.isNewChangeException = false;
        this.routingCanceledDate = response.routingCanceledDate;
        this.existingInActiveRouting = response.existingInActiveRouting;
        this.masterProgramCode = response.code.split('-')[0];
        this.showParentProgram = false;
        //this.doaMaxApprovalOrder =response.doaApproversList.reduce((op, item) => op = op > item.approvalOrder ? op : item.approvalOrder, 0);
        //this.doaApprovers = response.doaApproversList;
        this.routingStartDate = response.routingStartDate;
        this.messageList = response.messageslist;
        this.statusesList = response.statusList;
        this.childBundleRoutingProgramList = [];

        this.changeExceptionProgramsList = response.changeExceptionProgramDetails && response.changeExceptionProgramDetails.length > 0 ? response.changeExceptionProgramDetails : [];
        response.changeExceptionProgramDetails.forEach(cep => {
          if (cep.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
            cep.approvedDate = new Date(cep.approvedDate).toLocaleDateString();
          }
          else {
            cep.approvedDate = '';
          }
        })
        if (this.messageList.length > 0) {
          this.showExpandButton = true;
        }
        let startDateCurrentYear = this.startDate.getFullYear();
        let startDateCurrentMonth = this.startDate.getMonth();
        let currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth();

        //removed start date and end date validation for Change exception to allow users to edit CE's after the end date. 
        this.startDateChange();
        this.programForm.controls.BeginDate.clearValidators();
        this.programForm.controls.BeginDate.updateValueAndValidity();
      
        if (!this.yearList.some(w => w.id == this.programYear)) {
          this.yearList.push(response.programYear);
        }
        if (response.programProductManager) {
          this.prodmgrs = response.programProductManager.map(x => x.name);
          this.prodmgrids = response.programProductManager.map(x => x.id);
          this.prodmgremails = response.programProductManager.map(x => x.email);
        }
        if (this.core.getUserAccountName() == this.programForm.controls.ProgramManager.value) {
          this.showPostButton = true;
        }   
        switch (response.programTableType) {
          case this.programTableTypeIds.Program:
            this.programTableType = this.programTableTypeIds.Program;
            this.isCalculatedTotals = !response.calculatedTotals;
            if (!this.isCalculatedTotals) {
              this.programForm.controls.CurrentTotalSales.setValue(this.calculatedTotalSales);
              this.programForm.controls.CurrentTotalExposure.setValue(this.calculatedTotalExposure);
            }
            this.isReadonly = response.calculatedTotals;
            this.previousVersionStartDate = new Date(response.previousVersionBeginDate);
            this.previousVersionEndDate = new Date(response.previousVersionEndDate);
            break;
          case this.programTableTypeIds.ExternalAccruingProgram:
            this.programTableType = this.programTableTypeIds.ExternalAccruingProgram;
            if (response.externalAccruingProgramType == this.programCategories._1 || response.externalAccruingProgramType == this.programCategories._2) {
              this.selected = 1;
              this.showExternalProgramTypeOptions = true;
              if (response.externalAccruingProgramType == this.programCategories._1) {
                this.selectedRadioBtn = 1;
              }
              else if (response.externalAccruingProgramType == this.programCategories._2) {
                this.selectedRadioBtn = 2;
              }
            }
            this.buttonText = true;
            break;
          case this.programTableTypeIds.NonAccruingProgram:
            this.programTableType = this.programTableTypeIds.NonAccruingProgram;
            this.showParentProgram = true;
            if (this.parentProgramCode.toString().includes('-') || companyCode[0].shortDesc === CompanyCodeShortDesc._2) {
              this.showNonAccruingProgramRefresh = false;
            }
            this.buttonText = true;
            break;
        }

        if (response.statusDefinition.id === this.statusDefinitionIds.ACCRUALS_COMPLETED || companyCode[0].shortDesc === CompanyCodeShortDesc._2) {
          this.buttonText = true;
        }
        this.checkStandardOrParentTypeProgram();


        switch (response.statusDefinition.id) {
          case this.statusDefinitionIds.DENIED:
            this.masterProgramSave = false;
            break;
          case this.statusDefinitionIds.APPROVAL_ROUTING:
            this.masterProgramSave = false;
            break;
          case this.statusDefinitionIds.APPROVED:
            this.programForm.controls.TotalSales.setValue(response.currentTotalSales);
            this.programForm.controls.TotalExposure.setValue(response.currentTotalExposure);
            this.masterProgramSave = false;
            this.changeExceptionExplanation = true;
            this.isReadonly = response.calculatedTotals;
            break;
          case this.statusDefinitionIds.ACCRUALS_PENDING:
            this.masterProgramSave = false;
            this.buttonText = false;
            this.changeExceptionExplanation = true;
            break;
          case this.statusDefinitionIds.ACCRUALS_COMPLETED:
            this.masterProgramSave = false;
            this.buttonText = true;
            this.changeExceptionExplanation = true;
            break;
          case this.statusDefinitionIds.DRAFT:
            this.masterProgramSave = false;
            this.buttonText = true;
            this.changeExceptionExplanation = true;
            break;
        }
        if (response.totalSalesAndTotalExposure) { this.totalSalesAndTotalExposureList = response.totalSalesAndTotalExposure; }
        this.bindProductMangersAccrualsStatus();
      }
      this.spinner.hide();
    });
  }

  formControlChange() {
    this.isFormControlChange = true;
    if (this.progCode.toString().includes('-')) {
      this.isChangeException = true;
    }
    else {
        this.isChangeException = false;
    }
    if (this.programForm.controls.ShortName.value && this.programForm.controls.ShortName.value.trim() != this.programShortName.trim()) {
      this.programNameValidator()
    }

    let currentTotalExposure = this.programForm.controls.TotalExposure.value != null ? this.programForm.controls.TotalExposure.value.toString().includes(',') ? parseInt(this.programForm.controls.TotalExposure.value.replaceAll(',', '')) : this.programForm.controls.TotalExposure.value : null;
    let currentTotalSales = this.programForm.controls.TotalSales.value != null ? this.programForm.controls.TotalSales.value.toString().includes(',') ? parseInt(this.programForm.controls.TotalSales.value.replaceAll(',', '')) : this.programForm.controls.TotalSales.value : null;
    currentTotalExposure = currentTotalExposure === "" ? null : currentTotalExposure;
    currentTotalSales = currentTotalSales === "" ? null : currentTotalSales;

    if (this.programForm.controls.ShortName.value != this.programShortName ||
      this.programForm.controls.ConsistentName.value != this.programConsistentName ||
      this.programForm.controls.ProgramDesc.value != this.programDesc ||
      this.programForm.controls.ProgramStatus.value != this.programStatus ||
      this.startDate.toDateString() != new Date(this.programForm.controls.BeginDate.value).toDateString() ||
      this.endDate.toDateString() != new Date(this.programForm.controls.EndDate.value).toDateString() ||
      this.programForm.controls.ProgramYear.value != this.programYear ||
      currentTotalSales != this.currentTotalSales ||
      currentTotalExposure != this.currentTotalExposure || 
      this.isNewExplanation || this.programForm.controls.Explanation.value != this.explanation ||
      (this.programForm.controls.CalculatedTotals.value != this.isCalculatedTotals && this.programTableType === this.programTableTypeIds.Program)) {
      this.masterProgramSave = true;
    } // No field is changed
    else { 
      this.masterProgramSave = false;
    }
    
    if (this.progCode.toString().includes('-')) {
      this.f.ChangeException.setValue(true);
    }
    var filesUpload = this.programForm.controls.FileUpload.value;
    if (this.deleteFiles.length > 0) {
      if (this.statusDefinitionId === this.statusDefinitionIds.APPROVED && !this.isNewExplanation) {
        this.masterProgramSave = true;
      }
    }
    else if (filesUpload && Array.isArray(filesUpload)) {
      if (filesUpload.length > 0) {
        if (this.statusDefinitionId === this.statusDefinitionIds.APPROVED && !this.isNewExplanation) {
          this.masterProgramSave = true;
        }
      }
    }
  }


  onlyFirstDay = (d): boolean => {
    d = d.toDate();
    const date = d.getDate();
    var firstDay = new Date(d.getFullYear(), d.getMonth(), 1).getDate();
    // all other dates except first date of month are disabled.
    return firstDay == date;
  }

  onlyLastDay = (d): boolean => {
    d = d.toDate();
    const date = d.getDate();
    var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    // all other dates except last date of month are disabled.
    return lastDay == date;
  }

  consistentNameFilter(value: any): any[] {
    const filterValue = value ? value.toLowerCase() : value;
    return this.consistentNameList.filter(consistentNameList => consistentNameList.toLowerCase().includes(filterValue) || String(consistentNameList.code).includes(filterValue));
  }

  ngAfterViewInit() {
    if (this.dataSourceEmailNotifications !== undefined) {
      if (this.dataSourceEmailNotifications.data.length > 0) {
        this.dataSourceEmailNotifications.paginator = this.paginator;
        this.dataSourceEmailNotifications.sort = this.sort;
      }
    }
  }

  checkStandardOrParentTypeProgram() {
    if (this.childProgramCodes !== null) {
      if ((!this.showExternalProgramTypeOptions && this.programForm.controls.CompanyCode.value !== 'CANADA') || this.showParentProgram) {
        this.isStandardOrParentTypeProgram = true;
      }
      else {
        this.isStandardOrParentTypeProgram = false;
      }
    }
    else {
      if ((!this.showExternalProgramTypeOptions && this.parentProgramCode !== null) || this.showParentProgram) {
        this.isStandardOrParentTypeProgram = true;
      }
      else {
        this.isStandardOrParentTypeProgram = false;
      }
    }
  }
  programNameValidator() {
    let programNameList = [];
    programNameList.push(this.programForm.controls.ShortName.value);
    const headers = new HttpHeaders().set('content-type', 'application/json');

    return this.context.postdatatoservice("/api/Program/Option/GetIsProgramNameExists", JSON.stringify(programNameList), headers)
      .toPromise().then((response) => {
        if (response) {
          this.programForm.controls.ShortName.setErrors({ errors: true });
        }
      })
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  canDeleteProgram(): boolean {
    return this.core.canDeleteProgram;
  }

  canViewProgram(): boolean {
    return this.core.canViewProgram;
  }

  get f() { return this.programForm.controls; }

  toFormData<T>(formValue: T) {
    const formData = new FormData();
    this.buildFormData(formData, formValue, null);
    const fileUploadData = formValue['FileUpload'];
    if (fileUploadData) {
      formData.delete('FileUpload');
      if (fileUploadData && Array.isArray(fileUploadData)) { // check for arrays
        for (const v of fileUploadData) {
          formData.append('FileUpload', v);
        }
      }
    }
    formData.delete('EmailOptions');
    if (this.programForm.controls.EmailOptions.value) {
      formData.append('EmailOptions', JSON.stringify(this.programForm.controls.EmailOptions.value));
    }
    formData.delete('DateChangeEmailOptions');
    if (this.programForm.controls.DateChangeEmailOptions.value) {
      formData.append('DateChangeEmailOptions', JSON.stringify(this.programForm.controls.DateChangeEmailOptions.value));
    }
    return formData;
  }

  buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !moment.isDate(data) && !moment.isMoment(data)) {
      Object.keys(data).forEach(key => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  }

  isAdmin(): boolean {
    return this.core.isAdmin;
  }

  verifyDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteProgramComponent, {
      data: { confirm: false }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.confirm) {
        this.deleteProgram();
      }
    })
  }

  deleteProgram() {        
    this.context.getdatafromService(`/api/Program/Option/VerifyOkToDelete/${this.progCode.toString()}/${this.programTableType}`).toPromise().then((response) => {
      if (response != null) {
        this.deleteCheckResponse = response;
        if (this.deleteCheckResponse != 'Ok')
        {
          this.openErrorSnackBar(this.deleteCheckResponse, 'x', 4000);
        }
        else
        {
          const headers = new HttpHeaders().set('content-type', 'application/json');
          this.context.postdatatoservice(`/api/Program/Table/DeleteProgram/${this.progCode.toString()}/${this.programTableType}`).toPromise().then((response) => {
            if (response != null) {
              if (response[0].rowsAffected == 1) {
                this.openSuccessSnackBar('Program has been deleted', 'x', 3000);
                this.router.navigate(['administration/search-programs']);      
              } 
              else
              {
                this.openErrorSnackBar('Error deleting program', 'x', 4000);
              }
            }
            else 
            {
              this.openErrorSnackBar('Error deleting program', 'x', 4000);
            }
          })
        }        
      }
    })
  } 

  onSubmit(statusDefinitionId) {
    this.programForm.controls.AppUser.setValue(this.programForm.controls.ProgramManager.value);
    if (this.programTableType === this.programTableTypeIds.Program) {
      this.setStandardProgramValidators();
      this.programForm.markAllAsTouched();
    }

    this.programForm.controls.StatusDefinition.setValue(statusDefinitionId);
    if (this.programForm.controls.ConsistentName.value == undefined) {
      this.programForm.controls.ConsistentName.setValue("");
    }
    if (this.programForm.controls.ProgramDesc.value == null) {
      this.programForm.controls.ProgramDesc.setValue("");
    }
    if (this.programForm.controls.Channel.value == 0) {
      this.programForm.controls.Channel.setValue("");
    }
    if (this.programForm.controls.TypeDefinition.value == 0) {
      this.programForm.controls.TypeDefinition.setValue("");
    }
    if (this.isProgramAccrualsTotalSalesAndTotalExposureUpdate && !this.programForm.controls.CalculatedTotals.value && this.statusDefinitionId !== this.statusDefinitionIds.APPROVED) {
      this.updateProgramTotalSalesAndTotalExposure();
    }
    if (this.sendDateChangeEmail && statusDefinitionId !== this.statusDefinitionIds.DRAFT) {
      this.buildDateChangeProductManagerEmail();
       this.updateProgram();
    }
    else {
      if (this.isChangeException && this.programTableType === this.programTableTypeIds.Program && this.previousVersionEndDate && this.previousVersionStartDate) {
        if (((this.endDate.toDateString() === new Date(this.programForm.controls.EndDate.value).toDateString())
          && (this.startDate.toDateString() === new Date(this.programForm.controls.BeginDate.value).toDateString())) && ((this.previousVersionEndDate.toDateString() !== new Date(this.programForm.controls.EndDate.value).toDateString())
            || (this.previousVersionStartDate.toDateString() !== new Date(this.programForm.controls.BeginDate.value).toDateString())) && statusDefinitionId !== this.statusDefinitionIds.APPROVAL_ROUTING) {
              this.getProgramStatus(true,false);
        }
        else {
          this.updateProgram();
        }
      }
      else {
        this.updateProgram();
      }
    }
  }

  updateProgram() {
    if (!this.isFormControlChange) {
      if (this.programForm.controls.ProgramStatus.value != this.programStatus) {
      
        this.selectedProgramStatus = Object.keys(StatusDefinitionIds).filter(x => StatusDefinitionIds[x] == this.programForm.controls.ProgramStatus.value);
        if (this.selectedProgramStatus.length > 0){
          this.programForm.controls.StatusDefinition.setValue(this.selectedProgramStatus[0]);        
        }
      }

      this.programForm.controls.DeleteFiles.setValue(this.deleteFiles);
      this.submitted = true;
      if (this.programForm.valid && this.saveAndProceed) {
        //var companyCode = this.companyCodeList.filter(c => c.shortDesc == this.programForm.controls.CompanyCode.value);
        //this.programForm.controls.CompanyCode.setValue(companyCode[0].id);
        if (this.programForm.controls.ProgramTypeOptionsParent.value) {
          this.programForm.controls.ProgramTypeOptionsParent.setValue(this.parentProgramCode);
        }
        
        this.setDatesForUpdate();
        this.adminProgram.id = this.programForm.controls.Id.value;
        this.adminProgram.programCode = this.programForm.controls.ProgramCode.value;
        this.adminProgram.shortName = this.programForm.controls.ShortName.value;
        this.adminProgram.consistentName = this.programForm.controls.ConsistentName.value;
        this.adminProgram.programDesc = this.programForm.controls.ProgramDesc.value;
        this.adminProgram.statusDefinition = this.programForm.controls.StatusDefinition.value;
        this.adminProgram.programYear = this.programForm.controls.ProgramYear.value;
        this.adminProgram.totalSales = this.programForm.controls.TotalSales.value;
        this.adminProgram.totalExposure = this.programForm.controls.TotalExposure.value;
        this.adminProgram.changeException = this.programForm.controls.ChangeException.value;
        this.adminProgram.programCategory = this.programTableType.toString();
        this.adminProgram.calculatedTotals = this.programForm.controls.CalculatedTotals.value;
        this.adminProgram.explanation = this.programForm.controls.Explanation.value;

        const headers = new HttpHeaders().set('content-type', 'application/json');
        this.context.postdatatoservice("/api/Program/Table/AdminUpdate", JSON.stringify(this.adminProgram), headers).toPromise().then((response) => {
          if (response !== null) {
            if (this.programTableType === this.programTableTypeIds.Program && ((this.endDate.toDateString() != new Date(this.programForm.controls.EndDate.value).toDateString())
              || (this.startDate.toDateString() != new Date(this.programForm.controls.BeginDate.value).toDateString()))) {

              this.updateProgramTotalSalesAndTotalExposure();
            }
            else if (this.isChangeException && this.programTableType === this.programTableTypeIds.Program && this.previousVersionEndDate && this.previousVersionStartDate) {
              if (((this.endDate.toDateString() === new Date(this.programForm.controls.EndDate.value).toDateString())
                && (this.startDate.toDateString() === new Date(this.programForm.controls.BeginDate.value).toDateString())) && ((this.previousVersionEndDate.toDateString() !== new Date(this.programForm.controls.EndDate.value).toDateString())
                  || (this.previousVersionStartDate.toDateString() !== new Date(this.programForm.controls.BeginDate.value).toDateString())) && this.programForm.controls.StatusDefinition.value !== this.statusDefinitionIds.APPROVAL_ROUTING) {
                this.updateProgramTotalSalesAndTotalExposure();
              }
            }
            if (response.id === this.statusDefinitionIds.APPROVAL_ROUTING) {
              if (this.isNewChangeException) {
                this.progCode = this.f.NewProgramCode.value;
              }
              this.sendEmailNotificationToApprovers(this.programId, this.progCode, this.programTableType)
            }
            else {
              var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
              if (response.id === this.statusDefinitionIds.DRAFT && this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
                const headers = new HttpHeaders().set('content-type', 'application/json');
                let changeExceptionId = 0;
                changeExceptionId = this.changeExceptionId === 0 ? this.changeExceptionId = null : this.changeExceptionId;
                this.buildProgramManagerEmail(this.progCode)
                this.programManagersEmail.push({ programId: this.programId, programType: this.programTableType, emailDefinitionId: this.emailDefinitionIds.ADMIN_TO_PROGRAMMGR, programManagerId: this.programManagerAppUserId, emailOptions: JSON.parse(JSON.stringify(this.programManagersEmailOptions)), changeExceptionId: changeExceptionId, changeException: false },);
                if (this.programManagersEmail.length > 0) {
                  this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.programManagersEmail), headers)
                    .toPromise().then((response) => {
                      if (response != null) {
                        this.programManagersEmail = [];
                      }
                    })
                }
              }
              this.openSuccessSnackBar('Form Updated Successfully', 'x', 3000);
            }
          }
          this.prodmgrids = [];
          this.router.navigate(['administration/search-programs']);
        },
          msg => { // Error
            this.openErrorSnackBar('Error in Editing the Form', 'x', 3000);
          })
        .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
      } else { // Error that might occur before submission if validation fails
        this.openErrorSnackBar('Error in Editing the Form', 'x', 3000);
      }
    }
  }

  setDatesForUpdate() {
    var beginDate = moment(this.programForm.controls.BeginDate.value).add(24, 'hours').format("YYYY-MM-DD HH:mm:ss");
    var endDate = moment(this.programForm.controls.EndDate.value).add(24, 'hours').format("YYYY-MM-DD HH:mm:ss");
    var beginDateDate = parseInt(beginDate.substring(8,10))
    if (beginDateDate == 1) {
      this.adminProgram.beginDate = beginDate.toString();
      this.adminProgram.endDate = endDate.toString();      
    }
    else if (beginDateDate > 27) 
    {
      var firstday = moment(beginDate).add(1, 'days').format("YYYY-MM-DD HH:mm:ss");
      var lastday = moment(endDate).add(1, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.adminProgram.beginDate = firstday.toString();
      this.adminProgram.endDate = lastday.toString();      
    }   
    else
    {
      var firstday = moment(beginDate).add(-1, 'days').format("YYYY-MM-DD HH:mm:ss");
      var lastday = moment(endDate).add(-1, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.adminProgram.beginDate = firstday.toString();
      this.adminProgram.endDate = lastday.toString();  
    } 
  }

  buildProgramManagerEmail(progCode) {
    if (progCode === undefined || progCode === '') {
      progCode = 'NewProgramCode';
    }
    this.getUserName(this.core.getUserAccountName())
    var description = "<b>"+ this.adminUserName + " (ADMIN)</b> has saved a draft of a new program  <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + progCode + "</b>) so you can edit the new program as needed, or take the appropriate action.";
    var nextSteps = "<li>Click on the link below to Edit the new program and take any action that is needed.</li>";
    var buttonLinkUrl = 'programs/edit-program/' + progCode;
    var buttonText = "Go to Edit page";
    var emailSubject = "ADMIN has saved a draft of a new program.";
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    var toAddress = [appUser[0].email];
    var ccAddress = [this.core.getUsername()];
    this.programManagersEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1,description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
  }

  checkProgramValidators() {
    this.programForm.get('ProgramDesc').setValidators([Validators.required]);
    this.programForm.get('ProgramDesc').updateValueAndValidity();
    this.programForm.get('CompanyCode').setValidators([Validators.required]);
    this.programForm.get('CompanyCode').updateValueAndValidity();
    if (this.programForm.controls.ExternalAccruingProgramType.value != this.programCategories._1) {
      if (this.programTableType === this.programTableTypeIds.Program) {
        this.setStandardProgramValidators();
      }
      else {
        if (!this.isChangeException && !this.isNewChangeException) {
          this.programForm.get('TotalSales').setValidators([Validators.required]);
          this.programForm.get('TotalSales').updateValueAndValidity();
          this.programForm.get('TotalExposure').setValidators([Validators.required]);
          this.programForm.get('TotalExposure').updateValueAndValidity();
        }    
        else {
          this.programForm.get('TotalSales').setValidators([Validators.required]);
          this.programForm.get('TotalSales').updateValueAndValidity();
          this.programForm.get('TotalExposure').setValidators([Validators.required]);
          this.programForm.get('TotalExposure').updateValueAndValidity();
        }    
      }
    }
    this.programForm.markAllAsTouched();
  }

  setStandardProgramValidators() {
    if (!this.isChangeException && this.isStandardOrParentTypeProgram && this.programTableType === this.programTableTypeIds.Program && !this.isCalculatedTotals) {
      this.programForm.get('TotalSales').setValidators([Validators.required]);
      this.programForm.get('TotalSales').updateValueAndValidity();
      this.programForm.get('TotalExposure').setValidators([Validators.required]);
      this.programForm.get('TotalExposure').updateValueAndValidity();
    }
    else if ((this.isChangeException || this.isNewChangeException) && this.isStandardOrParentTypeProgram && this.programTableType === this.programTableTypeIds.Program && !this.isCalculatedTotals) {
      this.programForm.get('TotalSales').setValidators([Validators.required]);
      this.programForm.get('TotalSales').updateValueAndValidity();
      this.programForm.get('TotalExposure').setValidators([Validators.required]);
      this.programForm.get('TotalExposure').updateValueAndValidity();
    }
  }

  buildDateChangeProductManagerEmail() {
    let splittedProgramCode;
    let programCode = this.programForm.controls.ProgramCode.value;
    if (programCode.toString().includes('-')) {
      splittedProgramCode = programCode.toString().split('-');
      programCode = splittedProgramCode[0].toString().concat('-####');
    }
    else {
      if (this.isNewChangeException) {
        programCode = programCode.toString().concat('-####');
      }
    }

    var startDates = '';
    var endDates = '';

    if (this.isChangeException && (this.statusDefinitionId === this.statusDefinitionIds.DRAFT || this.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING)) {
      startDates = "<li>Previous Start Date: " + String(this.previousVersionStartDate.getMonth() + 1).padStart(2, '0') + "/" + this.startDate.getFullYear() + "</li>" +
        "<li>New Start Date: " + String(new Date(this.programForm.controls.BeginDate.value).getMonth() + 1).padStart(2, '0') + "/" + new Date(this.programForm.controls.BeginDate.value).getFullYear() + "</li>";
      endDates = "<li>Previous End Date: " + String(this.previousVersionEndDate.getMonth() + 1).padStart(2, '0') + "/" + this.endDate.getFullYear() + "</li>" +
        "<li>New End Date: " + String(new Date(this.programForm.controls.EndDate.value).getMonth() + 1).padStart(2, '0') + "/" + new Date(this.programForm.controls.EndDate.value).getFullYear() + "</li>";
    }
    else {
      startDates = "<li>Previous Start Date: " + String(this.startDate.getMonth() + 1).padStart(2, '0') + "/" + this.startDate.getFullYear() + "</li>" +
        "<li>New Start Date: " + String(new Date(this.programForm.controls.BeginDate.value).getMonth() + 1).padStart(2, '0') + "/" + new Date(this.programForm.controls.BeginDate.value).getFullYear() + "</li>";
      endDates = "<li>Previous End Date: " + String(this.endDate.getMonth() + 1).padStart(2, '0') + "/" + this.endDate.getFullYear() + "</li>" +
        "<li>New End Date: " + String(new Date(this.programForm.controls.EndDate.value).getMonth() + 1).padStart(2, '0') + "/" + new Date(this.programForm.controls.EndDate.value).getFullYear() + "</li>";
    }


    
    var description = "Your program <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>)'s start and/or end month and year have been changed impacting your Products and Accruals.";
    var nextSteps = "<li>Click on the link below to re-submit your updated Products and Accruals for the new months.</li>";
    var buttonLinkUrl = "accruals";
    var buttonText = "Go to Program Accruals";
    var emailSubject = "Updated program Start-End Dates!";
    var toAddress = [];
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
      }
    }

    this.dateChangeEmailOptions = this.emailService.buildActionDateChangeEmailTemplate(description, startDates, endDates, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    this.programForm.controls.DateChangeEmailOptions.setValue(JSON.parse(JSON.stringify(this.dateChangeEmailOptions)));
  }

  buildApproversEmail(approverEmail, programCode) {
    this.approversEmails = [];
    this.approversEmails.push(approverEmail);
    var emailProgramTypeText = "program";
    var parentProgram = "";
    if (this.isChangeException || this.isNewChangeException) {
      emailProgramTypeText = "change exception";
    }

    var description = "A program manager has released a " + emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " + emailProgramTypeText + ".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
        this.getUserName(this.core.getUserAccountName())
        description = "<b>" + this.adminUserName + " (ADMIN)</b> has released a " + emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
      }
    }

    if (this.parentProgramCode) {
      parentProgram = this.parentProgramShortName + " (" + this.parentProgramCode + ")";
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, null, parentProgram);
    }
    else {
      this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress);
    }
  }

  buildBundleRoutedApproversEmail(approverEmail, programCode) {
    this.approversEmails = [];
    this.approversEmails.push(approverEmail);
    var bundledProgramList = "";
    var emailProgramTypeText = "program";
    if (this.isChangeException || this.isNewChangeException) {
      emailProgramTypeText = "change exception";
    }
    if (this.childBundleRoutingProgramList.length > 0) {
      this.childBundleRoutingProgramList.forEach(element => {
        if (!element.isParent && element.bundleRoute) {
          bundledProgramList += "<li>" + element.shortName + " (" + element.code + ")</li>";
        }
      });
    }
    var description = "A program manager has released a  " + emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
    var nextSteps = "<li>Click on the link below to review the " + emailProgramTypeText + ".</li><li>Post comments to the Program Manager for discussion.</li><li>Click Approve button to continue the approval process.</li>";
    var buttonLinkUrl = 'programs/review-program/' + programCode;;
    var buttonText = "Go to Review page";
    var emailSubject = "New " + emailProgramTypeText + " needs your Approval!";
    var toAddress = this.approversEmails;
    var ccAddress;
    var appUser = this.appUserList.filter(au => au.userName === this.programForm.controls.ProgramManager.value && au.active);
    ccAddress = appUser.length > 0 ? [appUser[0].email] : [this.core.getUsername()];
    if (appUser.length > 0) {
      if (this.core.isAdmin && (appUser[0].email != this.core.getUsername())) {
        ccAddress.push(this.core.getUsername());
        this.getUserName(this.core.getUserAccountName())
        description = "<b>" + this.adminUserName + " (ADMIN)</b> has released a  " + emailProgramTypeText + " <b>" + this.programForm.controls.ShortName.value + "</b> (<b>" + programCode + "</b>) so you can Approve, or post questions to the Program Manager.";
      }
    }

    this.approversEmailOptions = this.emailService.buildActionEmailTemplate(this.actionMessages._1, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, null, bundledProgramList);
  }

  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.adminUserName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }

  createApproversEmailList(programId, programCode, programType, isApprover) {
    this.approversEmails = [];
    this.routingApproversEmails = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.getdatafromService('/api/Program/Routing/Details/' + programId + '/' + programType + '/' + isApprover + '/' + false + '/' + this.isChangeException + '/' + this.progCode.toString()).toPromise().then((response) => {
      if (response != null) {
        this.doaUsers = response;
        if (this.doaUsers.length > 0) {
          this.doaUsers.forEach(du => {
            if (this.childBundleRoutingProgramList.length > 0) {
              this.buildBundleRoutedApproversEmail(du.email, programCode);
            }
            else {
              this.buildApproversEmail(du.email, programCode);
            }
            this.routingApproversEmails.push({ programId: programId, programType: programType, emailDefinitionId: this.emailDefinitionIds.PROGRAMMGR_TO_DOAAPPROVER, programApproverId: du.appUserId, emailOptions: JSON.parse(JSON.stringify(this.approversEmailOptions)), changeExceptionId: this.changeExceptionId, changeException: this.isChangeException },);
          })
          if (this.routingApproversEmails.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');

            this.context.postdatatoservice("/api/Email/Table/ApproverEmail", JSON.stringify(this.routingApproversEmails), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.openSuccessSnackBar('Form Updated Successfully', 'x', 3000);
                  this.routingApproversEmails = [];
                }
              });
          }
        }
      }
    });
  }

  sendEmailNotificationToApprovers(programId, programCode, programType) {
    this.createApproversEmailList(programId, programCode, programType, true);
  }

  bindProductMangersAccrualsStatus() {
    let url = '/api/Program/Option/ProgramEmailNotification/' + this.progCode.toString() + '/' + moment(new Date(this.programForm.controls.BeginDate.value)).format('MM-DD-YYYY') + '/' + moment(new Date(this.programForm.controls.EndDate.value)).format('MM-DD-YYYY');
    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response != null) {
        this.emailNotifications = response;
        this.dataSourceEmailNotifications = new MatTableDataSource(this.emailNotifications);
        this.dataSourceEmailNotifications.paginator = this.paginator;
        this.dataSourceEmailNotifications.sort = this.sort;
      }
    });
  }

  async binddropdowns() {
    this.spinner.show();
    this.context.getdatafromService('/api/Program/Option/Channel').toPromise().then((response) => {
      if (response != null) {
        this.channelList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/TypeDefinition').toPromise().then((response) => {
      if (response != null) {
        this.programTypeList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/BusinessSegment').toPromise().then((response) => {
      if (response != null) {
        this.businessSegmentList = response;
      }
    });
    this.context.getdatafromService('/api/Program/Option/ProgramYear').toPromise().then((response) => {
      if (response != null) {
        this.yearList = response;
      }
    });

    this.context.getdatafromService('/api/Program/Option/AppUser').toPromise().then((response) => {
      if (response != null) {
        this.appUserList = response;
      }
    });

    await this.context.getdatafromService('/api/Program/Option/FunctionalArea').toPromise().then((response) => {
      if (response != null) {
        this.companyCodeList = response;
      }
    });
 
    await this.context.getdatafromService('/api/Program/Filter').toPromise().then((response) => {
      if (response != null) {
        this.statusList = response.status;
        this.programManagerList = response.programManagers;
        this.consistentNameList = response.consistentName;
        this.filteredconsistentNameList = this.programForm.controls.ConsistentName.valueChanges.pipe(
          startWith(''),
          map(value => this.consistentNameFilter(value))
        );
      }
    });

    if (this.progCode.toString().includes('-')) {
      this.changeExceptionPrepopulateProgram(this.progCode);
    }
    else {
      this.prepopulateProgram(this.progCode);
    }

  }

  onChangeNewExplanation(event: MatCheckboxChange): void {
    if (!event.checked) {
      this.isNonDOANewExplanation = false;
      let currentTotalExposure = this.programForm.controls.CurrentTotalExposure.value != null ? this.programForm.controls.CurrentTotalExposure.value.toString().includes(',') ? parseInt(this.programForm.controls.CurrentTotalExposure.value.replaceAll(',', '')) : this.programForm.controls.CurrentTotalExposure.value : null;
      let currentTotalSales = this.programForm.controls.CurrentTotalSales.value != null ? this.programForm.controls.CurrentTotalSales.value.toString().includes(',') ? parseInt(this.programForm.controls.CurrentTotalSales.value.replaceAll(',', '')) : this.programForm.controls.CurrentTotalSales.value : null;
      currentTotalExposure = currentTotalExposure === "" ? null : currentTotalExposure;
      currentTotalSales = currentTotalSales === "" ? null : currentTotalSales;
      if (this.startDate.toDateString() != new Date(this.programForm.controls.BeginDate.value).toDateString() ||
        this.endDate.toDateString() != new Date(this.programForm.controls.EndDate.value).toDateString() ||
        this.programForm.controls.ProgramYear.value != this.programYear ||
        currentTotalSales != this.currentTotalSales ||
        currentTotalExposure != this.currentTotalExposure ||
        (this.programForm.controls.CalculatedTotals.value != this.isCalculatedTotals && this.programTableType === this.programTableTypeIds.Program)) {
        if (this.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
          event.source.checked = true;
        }
      }
      else {
        this.isNewExplanation = false;
      }
    }
    else {
      this.isNonDOANewExplanation = true;
      this.isNewExplanation = true;
    }
    this.formControlChange();
  }

  truncateText(businessSegmentList: string) {
    return businessSegmentList.split(',').length > 1 ? businessSegmentList.split(',')[0] + '...' : businessSegmentList;
  }
  closedailog() {
    this.dialog.closeAll();
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }

  restrictChar(event: KeyboardEvent) {
    return String.fromCharCode(event.charCode).match(/[^0-9]/g) === null
  }

  restrictDifferenceChar(event: KeyboardEvent) {
    return event.key.match(/[^0-9]/g) === null || event.key === "-"
  }

  startDateChange() {
    this.checkAccrualStatusAndSetDatesChangestatusDefinitionId();
    this.formControlChange();
  }
  endDateChange() {
    this.checkAccrualStatusAndSetDatesChangestatusDefinitionId();
    this.formControlChange();
  }

  checkAccrualStatusAndSetDatesChangestatusDefinitionId() {
    this.warningMessages = [];
    if (this.programTableType === this.programTableTypeIds.Program && (new Date(this.startDate) < this.programForm.controls.BeginDate.value._d || new Date(this.endDate) > this.programForm.controls.EndDate.value._d)) {
      switch (this.statusDefinitionId) {
        case this.statusDefinitionIds.DRAFT:
          this.buttonText = false;
          break;
        case this.statusDefinitionIds.ACCRUALS_PENDING:
          this.buttonText = false;
          break;
        case this.statusDefinitionIds.ACCRUALS_COMPLETED:
          this.buttonText = true;
          break;
      }

      if (this.programTableType === this.programTableTypeIds.Program && (new Date(this.startDate) < this.programForm.controls.BeginDate.value._d || new Date(this.endDate) > this.programForm.controls.EndDate.value._d)) {
        this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.accrualsMessages._5, error: false });
      }
      this.isProgramAccrualsTotalSalesAndTotalExposureUpdate = true;
    }
    else {
      this.isProgramAccrualsTotalSalesAndTotalExposureUpdate = false;
    }
    if (this.programTableType === this.programTableTypeIds.Program) {
      this.getProgramStatus(false,true);
    }

  }

  getProgramStatus(isChangeExceptionDraftDateChangeEmail,callFormControlChange) {    
    let programPendingStatus: ProgramStatus[] = [];
    let programCompletedStatus: ProgramStatus[] = [];
    let uniqueProgramAndStatusDefinition = [];
    let url = '/api/Accrual/Details/GetProgramAccrualsStatus/' + this.programId + '/' + moment(new Date(this.programForm.controls.BeginDate.value)).format('MM-DD-YYYY') + '/' + moment(new Date(this.programForm.controls.EndDate.value)).format('MM-DD-YYYY');
    this.context.getdatafromService(url).toPromise().then((response) => {
      if (response != null) {
        if (response.length > 0) {
          uniqueProgramAndStatusDefinition = response.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.statusDefinitionId === v.statusDefinitionId)) === i);
          programPendingStatus = uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING);
          programCompletedStatus = uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED);
          if (programPendingStatus.length > 0) {
            this.buttonText = false;
            this.sendDateChangeEmail = true;
            this.datesChangeStatusDefinitionId = this.statusDefinitionIds.ACCRUALS_PENDING;
            if(callFormControlChange){
              this.formControlChange();
            }
          }
          else {
            this.buttonText = true;
            this.sendDateChangeEmail = false;
            this.datesChangeStatusDefinitionId = this.statusDefinitionIds.ACCRUALS_COMPLETED;
            if (callFormControlChange) {
              this.formControlChange();
            }
          }
          if (this.sendDateChangeEmail && isChangeExceptionDraftDateChangeEmail && this.isChangeException && (this.statusDefinitionId === this.statusDefinitionIds.DRAFT || this.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING)) {
            this.buildDateChangeProductManagerEmail();
            this.updateProgram();
          }
          if (this.isChangeException && this.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED) {
            this.updateProgram();
          }
        }
      }
    });
  }

  updateProgramTotalSalesAndTotalExposure() {
    const headers = new HttpHeaders().set('content-type', 'application/json');
      let programTotalSalesAndTotalExposure: ProgramTotalSalesAndTotalExposureV2[] = []
      this.context.getdatafromService(`/api/Program/Option/ProgramFinancialAssumptionsV2/${this.progCode.toString()}/${this.programForm.controls.CalculatedTotals.value}/${this.programId}`).toPromise().then((response) => {
        if (response != null) {
          programTotalSalesAndTotalExposure = response;         
          const headers = new HttpHeaders().set('content-type', 'application/json');
          this.context.postdatatoservice("/api/Program/Table/TotalSalesAndTotalExposureUpdateV2", JSON.stringify(programTotalSalesAndTotalExposure), headers)
            .toPromise().then((response) => {
              if (response != null) {

              }
            });          
        }
      });
  }
  navigateToProgram(programCode) {
    this.router.navigateByUrl('programs/edit-program/' + programCode.toString());        
  }

  getProductsAndAccrualsSummary() {
    this.routeParamsService.clearProgramEditNavigationSettings();
    this.routeParamsService.sendProgramEditNavigationRouteParams("Edit");
    this.router.navigateByUrl('/accruals/accruals-products-summary-v2/' + this.progCode.toString());    
  }

  onBack(): void {
    this.availableStatusDefinitions.length = 0;
    this.location.back();
  }

  onSaveAndRelease(isOnlySaveAndRelease) {
    this.validateTotalExposure(isOnlySaveAndRelease, this.isChangeException);
  }

  validateRouteForApprovalSaveRelease(): boolean {
    return !(this.parentProgramStatusDefinitionId != 0 && (this.parentProgramStatusDefinitionId == StatusDefinitionIds.ACCRUALS_PENDING || this.parentProgramStatusDefinitionId == StatusDefinitionIds.ACCRUALS_COMPLETED || this.parentProgramStatusDefinitionId == StatusDefinitionIds.DRAFT) && this.bundleRoute);
  }

  validateTotalExposure(isOnlySaveAndRelease, isChangeExceptionSave) {
    this.programForm.controls.ChangeException.setValue(isChangeExceptionSave);
    this.saveAndProceed = true;
    this.isFormControlChange = false;
    if ((this.changeExceptionExplanation && ( this.programForm.controls.Explanation.value === null || this.programForm.controls.Explanation.value.trim().length === 0)|| this.isNewChangeException) && this.programForm.valid) {
      this.saveAndProceed = false;
      let changeExceptionExplanationCode = this.isNewChangeException ? this.f.NewProgramCode.value : this.progCode;
      this.openChangeExceptionExplanationDialog(isOnlySaveAndRelease, changeExceptionExplanationCode)
    }
    if (this.saveAndProceed && this.programForm.valid) {
      this.proceedSubmit(isOnlySaveAndRelease);
    }
  }

  openWarningMessageDialog(isOnlySaveAndRelease): void {
    const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '550px',
      data: { dataMessage: this.warningMessages, dataContinueText: 'Save', dataConfirm: this.saveAndProceed, dataShowErrorResolveMessage: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.saveAndProceed = result['dataConfirm']
        if (this.warningMessages.some(element => element.error)) {
          this.saveAndProceed = false;
        }
        else if (this.saveAndProceed) {
            if (this.isNewChangeException) {
              this.statusDefinitionId = this.statusDefinitionIds.DRAFT;
            }
            this.onSubmit(this.statusDefinitionId);
          }
        this.warningMessages = [];
      }
    });
  }

  openChangeExceptionExplanationDialog(isOnlySaveAndRelease, changeExceptionProgramCode) {
    let isRequired = isOnlySaveAndRelease;
    const dialogRef = this.dialog.open(DialogChangeExceptionExplanationComponent, {
      disableClose: true,
      height: '400px',
      width: '1150px',
      data: { explanation: this.programForm.controls.Explanation.value, required: isRequired, changeExceptionProgramCode: changeExceptionProgramCode, dataConfirm: true }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.saveAndProceed = result['dataConfirm']
        this.programForm.controls.Explanation.setValue(result.explanation);
        if (this.saveAndProceed) {
          this.proceedSubmit(isOnlySaveAndRelease);
        }
      }
    });
  }

    proceedSubmit(isOnlySaveAndRelease){
        const TotalExposure = parseInt(this.f.TotalExposure.value.split(',').join(''));
        const TotalSales = parseInt(this.f.TotalSales.value.split(',').join(''));
        if (this.isChangeException) {
          const currentTotalExposure = parseInt(this.f.TotalExposure.value.toString().split(',').join(''));
          const currentTotalSales = parseInt(this.f.TotalSales.value.toString().split(',').join(''));
          this.f.CurrentTotalExposure.setValue(currentTotalExposure);
          this.f.CurrentTotalSales.setValue(currentTotalSales);
          if (currentTotalExposure >= currentTotalSales && ((this.f.CurrentTotalExposure.value != "0" && this.f.CurrentTotalSales.value != "0")||(this.f.CurrentTotalExposure.value != "0" && this.f.CurrentTotalSales.value == "0"))) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.financialAssumptionErrors._3, error: true });
            this.saveAndProceed = false;
          }
          if (currentTotalExposure == 0 && currentTotalSales == 0) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.financialAssumptionErrors._4, error: false });
          }
          if (this.startDate.toDateString() == new Date(this.programForm.controls.BeginDate.value).toDateString() &&
            this.endDate.toDateString() == new Date(this.programForm.controls.EndDate.value).toDateString() &&
            this.programForm.controls.ProgramYear.value == this.programYear &&
            this.programForm.controls.ProgramDesc.value == this.programDesc &&
            currentTotalSales == this.currentTotalSales &&
            currentTotalExposure == this.currentTotalExposure &&
            this.programForm.controls.CalculatedTotals.value == this.isCalculatedTotals) {
            if (this.statusDefinitionId == this.statusDefinitionIds.APPROVED) {
              this.warningMessages = [];
            }
          }
        }
        else {
          if (TotalExposure >= TotalSales && ((this.f.TotalExposure.value != "0" && this.f.TotalSales.value != "0") ||(this.f.TotalExposure.value != "0" && this.f.TotalSales.value == "0"))) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.financialAssumptionErrors._1, error: true });
            this.saveAndProceed = false;
          }
          if (this.f.TotalExposure.value == "0" && this.f.TotalSales.value == "0" && this.statusDefinitionId !== this.statusDefinitionIds.APPROVED) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.financialAssumptionErrors._2, error: false });
          }
        }
        if (this.programTableType === this.programTableTypeIds.Program && (new Date(this.startDate) < this.programForm.controls.BeginDate.value._d || new Date(this.endDate) > this.programForm.controls.EndDate.value._d)) {
          if (!this.warningMessages.some(element => element.message === this.accrualsMessages._5 && element.error === false)) {
            this.warningMessages.push({ messageId: this.warningMessages.length + 1, message: this.accrualsMessages._5, error: false });
          }
        }
    
        if (this.warningMessages.length > 0) {
          this.openWarningMessageDialog(isOnlySaveAndRelease);
        } else {
          if(this.isNewChangeException){
            this.statusDefinitionId = this.statusDefinitionIds.DRAFT;            
          }
          this.onSubmit(this.statusDefinitionId);
        }
  }
}

export interface ProgramTotalSalesAndTotalExposure {
  programId: number;
  totalSales: number;
  totalExposure: number;
}

export interface ProgramTotalSalesAndTotalExposureV2 {
  programId: number;
  totalSales: number;
  totalExposure: number;
  differenceExposure: number;
}

export interface EmailNotification {
  productManager: string;
  email: string;
  status: string;
  daysOldSinceEmailNotification: number;
}
export interface ProgramStatus {
  programId: number;
  statusDefinitionId: number;
}
export interface StatusDefinition {
  id: number;
  code: string;
}
export interface ChangeExceptionProgramDetails {
  code: string;
  approvedDate?: any;
  statusDefinitionId: number;
}

export interface Section {
  itemName: string;
  updatedBy: string;
  updatedDate: Date;
}

export class Histories {
  histories: Section[] = [
  ];
}

export class Approvals {
  approvals: Section[] = [
  ];
}

export interface DoaApprovers {
  userName: string;
  doaRole: string;
  statusDefinitionId?: number;
  emailNotificationId?: number;
  approvalOrder: number;
  dateCompleted?: Date;
  daysWaiting?: number;
  emailNotificationInsertDate?: Date;
  approver: boolean;
  active: boolean;
}

export class AdminProgram {
  id: number;
  programCode: string;
  shortName: string;
  consistentName: string;
  programDesc: string;
  statusDefinition: string;
  beginDate: string;
  endDate: string;
  programYear: string;
  totalSales: number;
  totalExposure: number;
  changeException: boolean;
  programCategory: string;
  calculatedTotals: boolean;
  explanation: string;
}

const dropdownStatusDefinitions: StatusDefinition[] = [
  {
    id: 5,
    code: 'CANCELED'
  }
]
