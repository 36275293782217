console.log(`${new Date()}: app.modules starting...`);

// angular modules
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, DoBootstrap, ApplicationRef, Injectable, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// routing modules
import { AppRoutingModule } from 'src/app/app-routing.module';

// app settings service
import { AppSettingsService } from './core/services/app-settings/app-settings.service';

// open api modules
import { ApiModule } from './core/server-api';

// additional services
import { FeatureFlagsService } from './core/services/feature-flag.service';
import { TitleService } from 'src/app/core/services/title.service';
import { UrlService } from './core/services/url-service.service';

// root app component
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';

// additional imports
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxCleaveDirectiveModule } from 'ngx-cleave-directive';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';


// Components
import { ProgramsComponent } from './programs/programs.component';
import { AccrualsComponent } from './accruals/accruals.component';
import { ReportsComponent } from './reports/reports.component';
import { ProgPortalContextService } from './core/services/progportalcontextservice';
import { CreateProgramComponent } from './programs/create-program/create-program.component';
import { EditProgramComponent } from './programs/edit-program/edit-program.component';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthorizationInterceptor } from './core/interceptors/authorization.interceptor';
import { AuthorizationService } from './core/services/authorization/authorization.service';
import { EmailService } from './core/services/email/email.service';
import { EditAccrualsComponent } from './accruals/edit-accruals/edit-accruals.component';
import { DialogAccrualsAddProductsComponent } from './accruals/dialog-accruals-addproducts/dialog-accruals-addproducts.component';
import { DialogAccrualsAssignRatesComponent } from './accruals/dialog-accruals-assignrates/dialog-accruals-assignrates.component';
import { DialogAccrualsRemoveProductsComponent } from 'src/app/accruals/dialog-accruals-removeproducts/dialog-accruals-removeproducts.component';
import { DialogProgramComponent } from './programs/dialog-program/dialog-program.component';
import { ReviewProgramComponent } from './programs/review-program/review-program.component';
import { DialogReviewApproveComponent } from './programs/dialog-review-approve/dialog-review-approve.component';
import { DialogWarningMessageComponent } from './dialogs/dialog-warning-message/dialog-warning-message.component';
import { routeforApprovalComponent } from './programs/route-for-approval/route-for-approval.component';
import { FileUploadComponent } from './programs/app-file-upload/app-file-upload.component';
import { RouteParamsService } from './core/services/route-params.service';
import { AccrualsProductsSummaryComponent } from './accruals/accruals-products-summary/accruals-products-summary.component';
import { AccrualsProductsSummaryComponentV2 } from './accruals/accruals-products-summary/accruals-products-summary-v2.component';
import { DialogReviewDenyComponent } from './programs/dialog-review-deny/dialog-review-deny.component';
import { DialogConfirmDeleteProgramComponent } from './programs/dialog-confirm-delete-program/dialog-confirm-delete-program.component';
import { UserImpersonationComponent } from './user-impersonation/user-impersonation.component';
import { DialogAccrualsErrorAndWarningDetailsComponent } from 'src/app/accruals/dialog-accruals-error-warning-details/dialog-accruals-error-warning-details.component';
import { EditProgramRouteforapprovalComponent } from './programs/edit-program-routeforapproval/edit-program-routeforapproval.component';
import { MonitoringErrorHandler } from './core/services/monitoring-error.handler';
import { MonitoringService } from './core/services/monitoring.service';
import { DialogParentChildListComponent } from './programs/dailog-parent-child-list/dailog-parent-child-list.component';
import { DialogChangeExceptionExplanationComponent } from './programs/dialog-changeexception-explanation/dialog-changeexception-explanation.component';
import { DialogPostMessageComponent } from './programs/dialog-post-message/dialog-post-message.component';
import { DialogAccrualsCommentsComponent } from './accruals/dialog-accruals-comments/dialog-accruals-comments.component';
import { AdministrationComponent } from './administration/administration.component';
import { ProgramManagerOwnershipComponent } from './administration/program-manager-ownership/program-manager-ownership.component';
import { CopyProductsAndAccrualsComponent } from './administration/copy-products-and-accruals/copy-products-and-accruals.component';
import { ViewAppUsersComponent} from './administration/view-app-users/view-app-users.component';
import { UpdateAppUsersComponent } from './administration/update-app-users/update-app-users.component';
import { SearchProgramsComponent } from './administration/search-programs/search-programs.component';
import { AdminEditProgramComponent } from './administration/admin-edit-program/admin-edit-program.component';


// factories and initializers
let msalInstance: any;

export function msalInstanceFactory() {
  console.log(`${new Date()}: app.module.msalInstanceFactory`);

  return msalInstance;
}

export function initializeApp(appSettingsService: AppSettingsService) {
  console.log(`${new Date()}: app.module.initializeApp`);

  const promise = appSettingsService
    .load()
    .toPromise()
    .then(() => {
      msalInstance = new PublicClientApplication({
        auth: {
          clientId: AppSettingsService.variables.clientSide.appRegistrationClientId,
          authority: `https://login.microsoftonline.com/${AppSettingsService.variables.clientSide.appRegistrationTenantId}/`,
          redirectUri: window.location.origin
        }
      });
    });
  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProgramsComponent,
    AccrualsComponent,
    AccrualsProductsSummaryComponent,
    AccrualsProductsSummaryComponentV2,
    EditAccrualsComponent,
    ReportsComponent,
    CreateProgramComponent,
    EditProgramComponent,
    DialogProgramComponent,
    DialogAccrualsAddProductsComponent,
    DialogAccrualsAssignRatesComponent,
    DialogAccrualsRemoveProductsComponent,
    DialogAccrualsErrorAndWarningDetailsComponent,
    routeforApprovalComponent,
    ReviewProgramComponent,
    DialogReviewApproveComponent,
    DialogWarningMessageComponent,
    FileUploadComponent,
    DialogReviewDenyComponent,
    DialogConfirmDeleteProgramComponent,
    UserImpersonationComponent,
    EditProgramRouteforapprovalComponent,
    DialogParentChildListComponent,
    EditProgramRouteforapprovalComponent,
    DialogChangeExceptionExplanationComponent,
    DialogPostMessageComponent,
    DialogAccrualsCommentsComponent,
    AdministrationComponent,
    ProgramManagerOwnershipComponent,
    CopyProductsAndAccrualsComponent,
    ViewAppUsersComponent,
    UpdateAppUsersComponent,
    SearchProgramsComponent,
    AdminEditProgramComponent
  ],
  entryComponents: [DialogProgramComponent, DialogAccrualsAddProductsComponent, DialogAccrualsAssignRatesComponent, DialogAccrualsRemoveProductsComponent, DialogAccrualsErrorAndWarningDetailsComponent, DialogParentChildListComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ApiModule.forRoot(msalInstanceFactory),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatChipsModule,
    MatNativeDateModule,
    NgxCleaveDirectiveModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTreeModule,
    DragDropModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatExpansionModule
  ],
  providers: [
    AppComponent,
    ProgPortalContextService,
    AppSettingsService,
    {
      provide: PublicClientApplication,
      useFactory: msalInstanceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    },
    AuthorizationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppSettingsService],
      multi: true
    },
    FeatureFlagsService,
    TitleService,
    EmailService,
    UrlService,
    RouteParamsService,
    MonitoringService,
    { 
      provide: ErrorHandler,
      useClass: MonitoringErrorHandler
    }
  ]
})

export class AppModule implements DoBootstrap {
  constructor() { }

  ngDoBootstrap(ref: ApplicationRef) {
    msalInstance.handleRedirectPromise().then((resp) => {
      const accounts = msalInstance.getAllAccounts();

      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);

        ref.bootstrap(AppComponent);
      } else if (resp == null) {
        msalInstance.loginRedirect();
      } else {
        console.error('Authentication Failed', resp);
      }
    });
  }
}

console.log(`${new Date()}: app.modules complete`);
