import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgPortalContextService } from 'src/app/core/services/progportalcontextservice';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { takeUntil } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { RouteParamsService } from 'src/app/core/services/route-params.service';
import { MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { DialogAccrualsAddProductsComponent, ProductNode, MaterialHierarchyCommercial } from 'src/app/accruals/dialog-accruals-addproducts/dialog-accruals-addproducts.component';
import { DialogAccrualsAssignRatesComponent } from 'src/app/accruals/dialog-accruals-assignrates/dialog-accruals-assignrates.component';
import { DialogAccrualsRemoveProductsComponent } from 'src/app/accruals/dialog-accruals-removeproducts/dialog-accruals-removeproducts.component';
import { DialogWarningMessageComponent, Messages } from 'src/app/dialogs/dialog-warning-message/dialog-warning-message.component';
import { HttpHeaders } from '@angular/common/http';
import { StatusDefinitionIds } from 'src/app/core/enums/statusDefinitionEnums';
import { AccrualsMessages } from 'src/app/core/enums/messageEnums';
import { ProgramTableTypeIds } from 'src/app/core/enums/programTableTypeEnums';
import { EmailDefinitionIds } from 'src/app/core/enums/emailDefinitionEnums';
import { ActionMessages } from 'src/app/core/enums/emailEnums';
import { MasterMaterialHierarchyLevels } from 'src/app/core/enums/masterMaterialHierarchyEnums';
import { CoreRepository } from 'src/app/core/core.repository';
import { EmailService } from 'src/app/core/services/email/email.service';
import { EmailOptions } from 'src/app/core/services/email/models/email-options.model';
import { AccrualsErrorAndWarnings, AccrualsHierarchyErrorAndWarnings } from 'src/app/accruals/dialog-accruals-error-warning-details/dialog-accruals-error-warning-details.component';
import { DialogAccrualsCommentsComponent } from '../dialog-accruals-comments/dialog-accruals-comments.component';
import {MatRadioChange} from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';


export interface AccrualsRemoveProducts {
  programName: string;
  programId: number;
  removeProducts: RemoveProducts[];
}

export interface RemoveProducts {
  materialCode: string;
  materialName: string;
}

export interface AccrualsAssignRates {
  programId: number;
  programName: string;
  autoFillAssignRates: AutoFillAssignRates[];
}

export interface AutoFillAssignRates {
  programId: number;
  materialHierarchyCommercialId?: number;
  materialCode: string;
  materialName: string;
  tradeProductCode?: string;
  tradeProductName?: string;
  performanceCenterCode?: string
  productCenterCode?: string
  plannedProductCode?: string
  performanceCenterName?: string
  productCenterName?: string
  plannedProductName?: string;
  selectedHierarchyLevel?: string;
  isAutoAssign: boolean;
  volume?: number;
  payRate?: number;
  accrual?: number;
  startDate: string;
  endDate: string;
  dates: date[];
  startColumn: number;
  endColumn: number;
  assignProducts?: AssignProducts[]
}

export interface date {
  value: number;
  viewValue: string;
}

export interface AssignProducts {
  materialCode: string;
  materialName: string;
}

export class ProgramNode {
  id?: number;
  programCode?: number;
  programName?: string;
  programManagerId?: number;
  programManagerEmail?: string;
  statusDefinitionId?: number;
  materialHierarchyCommercialId?: number;
  materialCode?: string;
  parentHierarchyCode?: string;
  name: string;
  startDate?: string;
  endDate?: string;
  children?: ProgramNode[];
  isAdded: boolean;
  numberOfColumns?: number;
  isSelected?: boolean;
  isProgramLevelDraft?: boolean;
  isProductLevelDraft?: boolean;
  isNewMonthAdded?: boolean;
  isPastEndDate: boolean;
  isCommentRequired: boolean;
}

export interface ItemPrograms {
  id: number;
  code?: number;
  programName?: string;
  programManagerId: number;
  programManagerEmail?: string;
  statusDefinitionId?: number;
  startDate: string;
  endDate: string;
  isPastEndDate: boolean;
  isCommentRequired: boolean;
}

export interface ProgramStatus {
  programId: number;
  changeExceptionProgramId?: number;
  changeException: boolean;
  statusDefinitionId: number;
}

export interface ProgramToProductManagerAssoc {
  programId: number;
  productManagerId: number;
  openEnrolled: boolean;
  updateBy: string;
}

export interface ProgramTotalSalesAndTotalExposure {
  programId: number;
  totalSales: number;
  totalExposure: number;
}

export interface ProgramProductManager {
  programId: number;
  ProgramManagerId: number;
}

export interface ProgramAccrualsMaterialAssignRates {
  programId: number;
  materialHierarchyCommercialId?: number;
  materialCode: string;
  materialName: string;
  header: boolean;
  assignRateType: string;
  _1stMonthAssignRate: string;
  _2ndMonthAssignRate: string;
  _3rdMonthAssignRate: string;
  _4thMonthAssignRate: string;
  _5thMonthAssignRate: string;
  _6thMonthAssignRate: string;
  _7thMonthAssignRate: string;
  _8thMonthAssignRate: string;
  _9thMonthAssignRate: string;
  _10thMonthAssignRate: string;
  _11thMonthAssignRate: string;
  _12thMonthAssignRate: string;
}

export interface ProgramAccrualRatesByMonth {
  id?: number;
  programId: number;
  materialHierarchyCommercialId: number;
  year: number;
  month: number;
  volumePercentage: number;
  payRatePercentage: number;
  accrualPercentage: number;
  statusDefinitionId: number;
  monthAndYearColumn?: number;
}


/** Flat to-do product node with expandable and level information */
export interface ProductFlatNode {
  id?: number;
  product: string;
  productHierarchyLevel?: number;
  productHierarchyCode?: string;
  parentHierarchyCode?: string;
  isProductSelected?: boolean;
  level: number;
  expandable: boolean;
  isHierarchySelected?: boolean;
}

export class ProgramFlatNode {
  expandable: boolean;
  id?: number;
  programCode?: number;
  programName?: string;
  programManagerEmail?: string;
  programManagerId?: number;
  statusDefinitionId?: number;
  materialHierarchyCommercialId?: number;
  materialCode?: string;
  parentHierarchyCode?: string;
  name: string;
  startDate?: string;
  endDate?: string;
  isAdded?: boolean;
  numberOfColumns?: number;
  level: number;
  isSelected?: boolean;
  isProgramLevelDraft?: boolean;
  isProductLevelDraft?: boolean;
  isNewMonthAdded?: boolean;
  isPastEndDate: boolean;
  isCommentRequired: boolean;
}

export interface ProgramsEmail {
  programId: number;
  programType: number;
  emailDefinitionId: number;
  programManagerId: number;
  emailOptions?: any;
}

export interface ToolTipMessages {
  programId: number;
  materialHierarchyCommercialId: number;
  messageId?: number;
  message: string;
  error: boolean;
}

export interface NewProgramAccrualMonth {
  programId: number;
  materialHierarchyCommercialId: number;
  assignMonth: string;
}

export interface SubmittedOrRemovedAccrualPrograms{
  programId: number;
  programName: string;
  disabled: boolean;
  selected: boolean;
  isCommentRequired: boolean;
  comment?:string;
}

export interface ProgramAccrualRatesComments{
  programId: number,
  materialHierarchyCommercialId: number,
  year: number,
  month: number
  message?: string;
}

export class ProgramAccrual {
  id : number[];
  productManager: string;
}

@Component({
  selector: 'app-edit-accruals',
  templateUrl: './edit-accruals.component.html',
  styleUrls: ['./edit-accruals.component.scss']
})
export class EditAccrualsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { read: MatPaginator }) paginator: MatPaginator;
  @ViewChild('sort', { read: MatSort }) sort: MatSort;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<ProgramAccrualsMaterialAssignRates>>;

  innerDisplayedColumns: string[] = ['assignRateType', '_1stMonthAssignRate', '_2ndMonthAssignRate', '_3rdMonthAssignRate',
    '_4thMonthAssignRate', '_5thMonthAssignRate', '_6thMonthAssignRate', '_7thMonthAssignRate',
    '_8thMonthAssignRate', '_9thMonthAssignRate', '_10thMonthAssignRate', '_11thMonthAssignRate',
    '_12thMonthAssignRate'];
  assignRatesItems = ["VOLUME %", "PAY RATE %", "ACCRUAL %"];
  checked: boolean;
  displayedColumns: string[] = ['select', 'name', 'startDate', 'endDate'];

  cancelSubject = new Subject();
  removable = true;
  selectable = true;
  selection = new SelectionModel<ProgramFlatNode>(true, []);
  productSelection = new SelectionModel<ProgramNode>(true);
  selectAddProductsCounter = 0;
  selectAssignRatesOrRemoveProductsCounter = 0;
  allAccrualsProgramFlatNodes: ProgramFlatNode[] = [];
  selectedAssignRatesOrRemoveProducts: ProgramFlatNode[] = [];
  uniqueSelectAssignRatesOrRemoveProducts: ProgramFlatNode[] = [];
  isAddProductsButtonEnable: boolean = true;
  isAssignRatesOrRemoveButtonEnable: boolean = true;
  programsAccrualsAssignRates: AccrualsAssignRates[] = [];
  accrualPrograms: ProgramNode[] = [];
  hierarchySelectionChangeAccrualPrograms: ProgramNode[] = [];
  accrualsProducts: ProductNode[] = [];
  programAccrualsExistingProducts: MaterialHierarchyCommercial[] = [];
  programAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  existingDeletedProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  programAccrualRatesByMonthAllProductManagers: ProgramAccrualRatesByMonth[] = [];
  existingProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  deleteProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  insertProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  updateProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  addedNewProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
  newProgramAccrualRatesMonth: NewProgramAccrualMonth[] = [];
  materialHierarchyCommercialIds: number[] = [];
  programsFilteredAccrualsAssignRates: AccrualsAssignRates[] = [];
  programsAccrualsRemoveProducts = [];
  assignRates: ProgramAccrualsMaterialAssignRates[] = [];
  nonUpdatedAssignRates: ProgramAccrualsMaterialAssignRates[] = [];
  uniqueProgramandMaterialHierarchyCommercial: ProgramAccrualRatesByMonth[] = [];
  saveAndSubmitConfirm: boolean = true;
  saveDraft: boolean = true;
  warningMessages: Messages[] = [];
  selectedProducts = [];
  id: string = '';
  ids: number[] = [];
  programs: ItemPrograms[] = [];
  programStatus: ProgramStatus[] = [];
  updateProgramsStatus: ProgramStatus[] = [];
  uniqueProgramAndStatusDefinition: ProgramStatus[] = [];
  programTotalSalesAndTotalExposure: ProgramTotalSalesAndTotalExposure[] = [];
  programProductManager: ProgramProductManager[] = [];
  isUpdatedAutoAssignRates: boolean = false;
  isFormDirty: boolean = false;
  addedNewMonths: boolean = false;
  draftStatusDefinitionIdCount = 0;
  expandCollapseAllAtHierarchyLevel = false;
  statusDefinitionIds = StatusDefinitionIds;
  accrualsMessages = AccrualsMessages;
  programTableTypeIds = ProgramTableTypeIds;
  emailDefinitionIds = EmailDefinitionIds;
  actionMessages = ActionMessages;
  masterMaterialHierarchyLevels = MasterMaterialHierarchyLevels;
  emailOptions = new EmailOptions();
  multipleProgramEmails: any;
  programManagerEmail: any[] = [];
  strProgramManagersIds: any;
  programsEmails: ProgramsEmail[] = [];
  multipleEmailOptions: any[] = [];
  chipErrorAndWarningMessages: Messages[] = [];
  toolTipMessages: ToolTipMessages[] = [];
  programsAccrualsErrorAndWarningsMessages: ToolTipMessages[] = [];
  programsAccrualsErrorAndWarnings: AccrualsErrorAndWarnings[] = [];
  submittedOrRemovedAccrualPrograms: SubmittedOrRemovedAccrualPrograms[] = [];
  programAccrualRatesComments: ProgramAccrualRatesComments[] = [];
  allProductManagers: any[] = [];
  programAccrual = new ProgramAccrual();
  masterSelectedHierarchyLevel:number;
  materialHierarchyCommercials: MaterialHierarchyCommercial[] = [];
  selectedHierarchy: string;
  removeProducts:boolean;
  userName: string;
  hierarchys: string[] = [this.masterMaterialHierarchyLevels._0, this.masterMaterialHierarchyLevels._1, this.masterMaterialHierarchyLevels._2, this.masterMaterialHierarchyLevels._3,this.masterMaterialHierarchyLevels._4];
  openEnrolledProgramAccrual = new ProgramAccrual();
  isFeatureFlagEnabledAssignProductManagerToProgramForAdmin: boolean = false;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ProgramFlatNode, ProgramNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<ProgramNode, ProgramFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: ProgramFlatNode | null = null;


  private transformer = (node: ProgramNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new ProgramFlatNode();
    flatNode.name = node.name;
    flatNode.endDate = node.endDate;
    flatNode.startDate = node.startDate;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.isAdded = node.isAdded;
    flatNode.id = node.id;
    flatNode.programCode = flatNode.programCode;
    flatNode.programName = node.programName;
    flatNode.materialCode = node.materialCode;
    flatNode.parentHierarchyCode = node.parentHierarchyCode;
    flatNode.numberOfColumns = node.numberOfColumns;
    flatNode.isSelected = node.isSelected;
    flatNode.isProgramLevelDraft = node.isProgramLevelDraft;
    flatNode.isProductLevelDraft = node.isProductLevelDraft;
    flatNode.isNewMonthAdded = node.isNewMonthAdded;
    flatNode.materialHierarchyCommercialId = node.materialHierarchyCommercialId;
    flatNode.programManagerEmail = node.programManagerEmail;
    flatNode.statusDefinitionId = node.statusDefinitionId;
    flatNode.programManagerId = node.programManagerId;
    flatNode.isPastEndDate = node.isPastEndDate;
    flatNode.isCommentRequired = node.isCommentRequired;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }
  /** The new item's name */
  treeControl = new FlatTreeControl<ProgramFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level,
    node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  dataSourceAssignRates = new MatTableDataSource(this.assignRates);
  /** The selection for checklist */
  checklistSelection = new SelectionModel<ProgramFlatNode>(true /* multiple */);


  getLevel = (node: ProgramFlatNode) => node.level;

  isExpandable = (node: ProgramFlatNode) => node.expandable;

  getChildren = (node: ProgramNode): ProgramNode[] => node.children;

  hasChild = (_: number, _nodeData: ProgramFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: ProgramFlatNode) => _nodeData.name === '';

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ProgramFlatNode): boolean {
    //this.todoItemSelectionToggle(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ProgramFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: ProgramFlatNode): void {
    //this.todoLeafItemSelectionToggle(node);
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: ProgramFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  treeControlToggleExpand(data) {
    this.spinner.show();
    if (this.expandCollapseAllAtHierarchyLevel) {
      this.treeControl.expandDescendants(data);
    }
    else {
      this.treeControl.expand(data);
    }
    this.spinner.hide();
  }

  treeControlToggleCollapse(data) {
    this.spinner.show();
    const descendants = this.treeControl.getDescendants(data);
    if (this.expandCollapseAllAtHierarchyLevel) {
      this.treeControl.collapseDescendants(data);
    }
    else {
      this.treeControl.collapse(data);
      descendants.forEach(d => {
        this.treeControl.collapse(d);
      })
    }
    this.spinner.hide();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ProgramFlatNode): void {
    let parent: ProgramFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ProgramFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: ProgramFlatNode): ProgramFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  constructor(public context: ProgPortalContextService,
    private spinner: NgxSpinnerService,
    public app: AppComponent,
    public dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private changeDetectorRefs: ChangeDetectorRef,
    private router: Router,
    private routeParamsService: RouteParamsService,
    public emailService: EmailService,
    private core: CoreRepository) {
    this.selectedHierarchy = this.masterMaterialHierarchyLevels._3;
    this.masterSelectedHierarchyLevel = 3;
    this.removeProducts = false;
    this.dataSource.data = this.accrualPrograms;
    this.dataSourceAssignRates.data = this.assignRates;
    this.selection.changed.subscribe(item => {
      this.isAddProductsButtonEnable = this.selection.selected.length == 0;
      this.selectAddProductsCounter = this.selection.selected.length;
      this.accrualPrograms.forEach(ap => {
        ap.isSelected = false;
      })
      this.selection.selected.forEach(node => {
        this.accrualPrograms.forEach(ap => {
          if (node.id === ap.id && !node.isPastEndDate) {
            ap.isSelected = true;
          }
        })
      })
      this.changeDetectorRefs.detectChanges();
    })

    this.checklistSelection.changed.subscribe(item => {
      this.programsAccrualsAssignRates.forEach(paar => {
        paar.autoFillAssignRates.forEach(afar => {
          afar.isAutoAssign = false;
          afar.selectedHierarchyLevel = '';
        })
      });

      this.isAssignRatesOrRemoveButtonEnable = this.checklistSelection.selected.filter(row => row.expandable === false).length == 0;
      this.programsFilteredAccrualsAssignRates = [];
      this.selectedAssignRatesOrRemoveProducts = this.checklistSelection.selected.filter(row => row.expandable === false);
      this.uniqueSelectAssignRatesOrRemoveProducts = this.selectedAssignRatesOrRemoveProducts.filter((v, i, a) => a.findIndex(t => (t.id === v.id && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);

      this.selectAssignRatesOrRemoveProductsCounter = this.uniqueSelectAssignRatesOrRemoveProducts.length;
      if (this.selectAssignRatesOrRemoveProductsCounter > 0) {
        this.updateRemoveProducts();
        this.uniqueSelectAssignRatesOrRemoveProducts.forEach(sarorp => {
          this.programsAccrualsAssignRates.forEach(paar => {
            if (sarorp.id === paar.programId) {
              paar.autoFillAssignRates.forEach(afar => {
                if (sarorp.materialCode === afar.materialCode) {
                  afar.isAutoAssign = true;
                  afar.selectedHierarchyLevel = '';
                }
              })
            }
          })
        })
      }
      else {
        this.programsAccrualsAssignRates.forEach(paar => {
          paar.autoFillAssignRates.forEach(afar => {
            afar.isAutoAssign = false;
            afar.selectedHierarchyLevel = '';
          })
        })
      }
    })

    this.routeParamsService.getProgramIdsRouteParams().subscribe(params => {
      if (params && this.ids.length === 0) {
        this.id = params;
        var arrayIds = params.split(',');
        arrayIds.forEach(arr => {
          this.ids.push(Number(arr));
        })
        this.routeParamsService.clearProgramIdsSettings();
        this.routeParamsService.setProgramIdsSettings(this.ids);
      }
    });

    if(this.isAdmin){
      this.routeParamsService.getAdminSelectedProductManagerRouteParams().subscribe(params => {
        if (params) {
          this.programAccrual.productManager = params;
         
          this.routeParamsService.clearAdminSelectedProductManagerSettings();
          this.routeParamsService.setAdminSelectedProductManagerSettings(this.programAccrual.productManager);
        }
      });
    }   
  }

  ngOnInit(): void {
    this.spinner.show();
    this.loadAccrualsRates();
    this.checked = false;
    this.CheckFeatures();
  }

  CheckFeatures() {
    this.context
      .getdatafromService('/api/FeatureFlag')
      .toPromise()
      .then((response) => {
        const data = response;
        this.isFeatureFlagEnabledAssignProductManagerToProgramForAdmin = data.filter(x => x.featureFlagName === "562327_FixAssignProductManagerToProgramForAdmin")[0].enabled;
      });
  }

  ngOnDestroy() {
    this.routeParamsService.clearProgramIdsSettings();
  }

  canViewAccrual(): boolean {
    return this.core.canViewAccrual;
  }

  canEditProgram(): boolean {
    return this.core.canEditProgram;
  }

  isAdmin(): boolean {
    return this.core.isAdmin;
  }
 
  hierarchyChange($event: MatRadioChange) {
    this.spinner.show()
    if ($event.source.name === 'radioHierarchy') {
      switch ($event.value) {
        case this.masterMaterialHierarchyLevels._0:
          this.masterSelectedHierarchyLevel = 0;
          break;
        case this.masterMaterialHierarchyLevels._1:
          this.masterSelectedHierarchyLevel = 1;

          break;
        case this.masterMaterialHierarchyLevels._2:
          this.masterSelectedHierarchyLevel = 2;

          break;
        case this.masterMaterialHierarchyLevels._3:
          this.masterSelectedHierarchyLevel = 3;

          break;
        case this.masterMaterialHierarchyLevels._4:
          this.masterSelectedHierarchyLevel = 4;
          break;
      }
      this.treeControl.collapseAll();
      this.expandCollapseAllAtHierarchyLevel = false;
      this.bindMasterHierarchySelectionChangeMaterialHierarchyCommercials(this.masterSelectedHierarchyLevel);
    }
}

onRemoveToggleChange($event: MatSlideToggleChange) {
  this.warningMessages = [];
 if($event.checked){
  this.insertProgramAccrualRatesByMonth = [];
  this.existingProgramAccrualRatesByMonth = []
  this.updateProgramAccrualRatesByMonth = [];
  this.insertFormatProgramAccrualRatesByMonth();
  this.updateFormatProgramAccrualRatesByMonth();
  if(this.insertProgramAccrualRatesByMonth.length > 0 || this.updateProgramAccrualRatesByMonth.length > 0){
    this.warningMessages.push({ messageId: 6, message: this.accrualsMessages._6, error: false });
    this.openRemoveProductsToggleWarningMessageDialog();
  }
  this.selection.clear();
 }
} 

  loadAccrualsRates() {
    if (this.ids.length === 0) {
      this.ids = this.routeParamsService.getProgramIdsSettings();
      this.programAccrual.productManager = this.routeParamsService.getAdminSelectedProductManagerSettings();
    }
    this.bindtabledata(this.ids);
  }

  openAddProductsDialog(): void {
    const dialogRef = this.dialog.open(DialogAccrualsAddProductsComponent, {
      disableClose: true,
      height: '100%',
      width: '30%',
      position: { right: '0px', top: '60px' },
      data: { dataAccrualPrograms: this.accrualPrograms, dataAssignRates: this.assignRates, dataAccrualsAssignRates: this.programsAccrualsAssignRates, dataCheckSelectedList: this.uniqueSelectAssignRatesOrRemoveProducts,dataMasterSelectedHierarchyLevel:this.masterSelectedHierarchyLevel, dataProductManager: this.programAccrual.productManager, dataAdmin: this.isAdmin() }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.accrualPrograms = result['dataAccrualPrograms'];
        this.programsAccrualsAssignRates = result['dataAccrualsAssignRates'];
        this.dataSourceAssignRates = result['dataAssignRates'];
        this.assignRates = result['dataAssignRates'];
        this.dataSource.data = this.accrualPrograms;
        this.updateAfterAddProductsAssignRates();
        this.visualSelectedAssignRatesOrRemoveProducts(result['dataCheckSelectedList']);
      }
      this.selection.clear();
      this.setIsFormDirty();
    });
  }
  openAssignRatesDialog(): void {
    this.isUpdatedAutoAssignRates = false;
    this.programsFilteredAccrualsAssignRates = JSON.parse(JSON.stringify(this.programsAccrualsAssignRates));
    this.programsFilteredAccrualsAssignRates.forEach(fpaar => {
      fpaar.autoFillAssignRates = fpaar.autoFillAssignRates.filter(row => row.isAutoAssign === true);
      fpaar.autoFillAssignRates.forEach(afar => {
        afar.volume = null;
        afar.payRate = null;
        afar.accrual = null;
        afar.startColumn = afar.dates[0].value;
        afar.endColumn = afar.dates[afar.dates.length - 1].value;
        afar.startDate = afar.dates[0].viewValue;
        afar.endDate = afar.dates[afar.dates.length - 1].viewValue;
      })
    })

    const dialogRef = this.dialog.open(DialogAccrualsAssignRatesComponent, {
      disableClose: true,
      height: '700px',
      width: '893px',
      data: { dataProgramsFilteredAccrualsAssignRates: this.programsFilteredAccrualsAssignRates, dataIsUpdatedAutoAssignRates: this.isUpdatedAutoAssignRates,dataMasterSelectedHierarchyLevel:this.masterSelectedHierarchyLevel }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.isUpdatedAutoAssignRates = result['dataIsUpdatedAutoAssignRates'];
        if (this.isUpdatedAutoAssignRates) {
          this.programsFilteredAccrualsAssignRates = result['dataProgramsFilteredAccrualsAssignRates'];;
          this.programsFilteredAccrualsAssignRates.forEach(fpaar => {
            this.programsAccrualsAssignRates.forEach(paar => {
              if (paar.programId === fpaar.programId) {
                paar.autoFillAssignRates.forEach(afar => {
                  fpaar.autoFillAssignRates.forEach(fafar => {
                    if (afar.materialCode === fafar.materialCode && afar.isAutoAssign === fafar.isAutoAssign) {
                      afar.payRate = fafar.payRate;
                      afar.volume = fafar.volume;
                      afar.accrual = fafar.accrual;
                      afar.startColumn = fafar.startColumn;
                      afar.endColumn = fafar.endColumn;
                    }
                  })
                })
              }
            })
          })
          this.updateAssignRates();
          this.checklistSelection.clear();
        }
        this.setIsFormDirty();
      }
    });
  }

  updateAfterAddProductsAssignRates() {
    this.programsAccrualsAssignRates.forEach(paar => {
      paar.autoFillAssignRates.forEach(afar => {
        if (!this.uniqueSelectAssignRatesOrRemoveProducts.some(usarrp => usarrp.id === afar.programId && usarrp.materialHierarchyCommercialId === afar.materialHierarchyCommercialId)) {
          this.assignRates.forEach(ar => {
            if (afar.programId === ar.programId && afar.materialCode === ar.materialCode && afar.isAutoAssign) {
              if (ar.header === false) {
                for (var prop in ar) {
                  var columnName = prop;
                  if (columnName.substr(0, 1) === '_') {
                    var columnNumber = 0;
                    if (!isNaN(+columnName.substr(1, 2))) {
                      columnNumber = Number(columnName.substr(1, 2));
                    }
                    else {
                      columnNumber = Number(columnName.substr(1, 1));
                    }
                    if ((columnNumber + 1 >= afar.startColumn) && (columnNumber + 1 <= afar.endColumn)) {
                      switch (ar.assignRateType) {
                        case 'VOLUME %':
                          ar[columnName] = afar.volume;
                          break;
                        case 'PAY RATE %':
                          ar[columnName] = afar.payRate;
                          break;
                        case 'ACCRUAL %':
                          if (afar.volume !== null && afar.payRate !== null && afar.volume.toString().length > 0 && afar.payRate.toString().length > 0) {
                            ar[columnName] = ((afar.volume * afar.payRate) / 100).toString();
                          }
                          else {
                            ar[columnName] = '';
                          }
                          break;
                      }
                    }
                  }
                }
              }
            }
          })
        }
      })
    })
  }

  updateAssignRates() {
    this.programsAccrualsAssignRates.forEach(paar => {
      paar.autoFillAssignRates.forEach(afar => {
        this.assignRates.forEach(ar => {
          if (afar.programId === ar.programId && afar.materialCode === ar.materialCode && afar.isAutoAssign) {
            if (ar.header === false) {
              for (var prop in ar) {
                var columnName = prop;
                if (columnName.substr(0, 1) === '_') {
                  var columnNumber = 0;
                  if (!isNaN(+columnName.substr(1, 2))) {
                    columnNumber = Number(columnName.substr(1, 2));
                  }
                  else {
                    columnNumber = Number(columnName.substr(1, 1));
                  }
                  if ((columnNumber + 1 >= afar.startColumn) && (columnNumber + 1 <= afar.endColumn)) {
                    switch (ar.assignRateType) {
                      case 'VOLUME %':
                        ar[columnName] = afar.volume;
                        break;
                      case 'PAY RATE %':
                        ar[columnName] = afar.payRate;
                        break;
                      case 'ACCRUAL %':
                        if (afar.volume !== null && afar.payRate !== null && afar.volume.toString().length > 0 && afar.payRate.toString().length > 0) {
                          ar[columnName] = ((afar.volume * afar.payRate) / 100).toString();
                        }
                        else {
                          ar[columnName] = '';
                        }
                        break;
                    }
                  }
                }
              }
            }
          }
        })
      })
    })
  }

  updateRemoveProducts() {
    this.programsAccrualsRemoveProducts = [];
    if (this.uniqueSelectAssignRatesOrRemoveProducts.length > 0) {
      this.uniqueSelectAssignRatesOrRemoveProducts.forEach(sarrp => {
        if (this.programsAccrualsRemoveProducts.length == 0) {
          this.programsAccrualsRemoveProducts.push({
            programName: sarrp.programName, programId: sarrp.id,
            removeProducts: [
              {
                materialCode: sarrp.materialCode, materialName: sarrp.name
              },
            ]
          })
        }
        else {
          if (this.programsAccrualsRemoveProducts.some(element => element.programId == sarrp.id)) {
            this.programsAccrualsRemoveProducts.forEach(parp => {
              if (sarrp.id === parp.programId) {
                if (!parp.removeProducts.some(rp => rp.materialCode === sarrp.materialCode))
                  parp.removeProducts.push({
                    materialCode: sarrp.materialCode, materialName: sarrp.name
                  })
              }
            })
          }
          else {
            this.programsAccrualsRemoveProducts.push({
              programName: sarrp.programName, programId: sarrp.id,
              removeProducts: [
                {
                  materialCode: sarrp.materialCode, materialName: sarrp.name
                },]
            })
          }
        }
      })
    }
  }
  
  openRemoveProductsDialog(): void {
    const dialogRef = this.dialog.open(DialogAccrualsRemoveProductsComponent, {
      disableClose: true,
      height: '400px',
      width: '550px',
      data: { dataProgramAccrualRatesByMonth: this.programAccrualRatesByMonth, dataProgramsAccrualsRemoveProducts: this.programsAccrualsRemoveProducts, dataAccrualPrograms: this.accrualPrograms, dataAssignRates: this.assignRates, dataAccrualsAssignRates: this.programsAccrualsAssignRates, dataMasterSelectedHierarchyLevel:this.masterSelectedHierarchyLevel,dataConfirmRemoval: false,submittedOrRemovedAccrualPrograms: this.submittedOrRemovedAccrualPrograms,isAdmin: this.core.isAdmin,productManager:this.programAccrual.productManager }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.accrualPrograms = result['dataAccrualPrograms'];
        this.programsAccrualsAssignRates = result['dataAccrualsAssignRates'];
        this.assignRates = result['dataAssignRates'];
        this.dataSourceAssignRates = result['dataAssignRates'];
        if (result['dataConfirmRemoval']) {
          this.submittedOrRemovedAccrualPrograms = result["submittedOrRemovedAccrualPrograms"];
          this.submitRemovalProducts();
          this.checklistSelection.clear();
        }
        this.setIsFormDirty();
      }
    });
  }

  openRemoveProductsToggleWarningMessageDialog(): void {
    const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '550px',
      data: { dataMessage: this.warningMessages, dataContinueText: 'Continue', dataConfirm: true, dataShowErrorResolveMessage: true, dataShowDetails: false, dataProgramsAccrualsErrorAndWarningProducts: this.programsAccrualsErrorAndWarnings,dataMasterSelectedHierarchyLevel:this.masterSelectedHierarchyLevel }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if (!result['dataConfirm']) {
          this.removeProducts = false;
        }
        else {
          this.bindtabledata(this.ids);
        }
      }
    });
  }

  openSaveDraftOrSaveAndSubmitWarningMessageDialog(statusDefinitionId: any): void {
    const dialogRef = this.dialog.open(DialogWarningMessageComponent, {
      disableClose: true,
      height: '340px',
      width: '550px',
      data: { dataMessage: this.warningMessages, dataContinueText: 'Save and Submit', dataConfirm: this.saveAndSubmitConfirm, dataShowErrorResolveMessage: true, dataShowDetails: true, dataProgramsAccrualsErrorAndWarningProducts: this.programsAccrualsErrorAndWarnings,dataMasterSelectedHierarchyLevel:this.masterSelectedHierarchyLevel }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.saveAndSubmitConfirm = result['dataConfirm'];
        if (this.saveAndSubmitConfirm) {
          if (this.warningMessages.some(element => element.error)) {
            this.saveAndSubmitProceed(false, statusDefinitionId);
          }
          else {
            this.openSaveAndSubmitProceedCommentDialog(true, statusDefinitionId);
          }
        }
      }
    });
  }

  openSaveAndSubmitProceedCommentDialog(isAccrualRatesValid, statusDefinitionId) {
   this.setForSaveAndSubmitProceedCommentDialog(statusDefinitionId);
    const dialogRef = this.dialog.open(DialogAccrualsCommentsComponent, {
      disableClose: true,
      height: '480px',
      width: '650px',
      data: { submittedOrRemovedAccrualPrograms: this.submittedOrRemovedAccrualPrograms,dataConfirm:true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        if(result['dataConfirm'] === true){
          this.submittedOrRemovedAccrualPrograms = result["submittedOrRemovedAccrualPrograms"];
          this.saveAndSubmitProceed(isAccrualRatesValid, statusDefinitionId);
        }
      }
    });
  }
 
  setForSaveAndSubmitProceedCommentDialog(statusDefinitionId) {
    this.submittedOrRemovedAccrualPrograms = [];
    let programIds:any = [];
    let uniqueProgramIds;
    let uniqueUpdateProgramIds = [...new Set(this.updateProgramAccrualRatesByMonth.map(item => item.programId))];
    let uniqueInsertProgramIds = [...new Set(this.insertProgramAccrualRatesByMonth.map(item => item.programId))];
    if(uniqueUpdateProgramIds.length > 0){
      programIds.push(uniqueUpdateProgramIds);
    }

    if(uniqueInsertProgramIds.length > 0){
      if(programIds.length > 0){
        uniqueInsertProgramIds.forEach(item => {
          if(!programIds[0].includes(item)){
            programIds[0].push(item);
          }
        })
      }
      else{
        programIds.push(uniqueInsertProgramIds);
      }
    }
    
    if(statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED){
      let uniqueDraftProgramIds = [...new Set(this.programAccrualRatesByMonth.filter(parbm => parbm.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING).map(item => item.programId))];
      if(uniqueDraftProgramIds.length > 0){
        if(programIds.length > 0){
          uniqueDraftProgramIds.forEach(item => {
            if(!programIds[0].includes(item)){
              programIds[0].push(item);
            }
          })
        }
        else{
          programIds.push(uniqueDraftProgramIds);
        }
      }
    }
   
    uniqueProgramIds =  [...new Set(programIds.map(item => item))];

      this.accrualPrograms.forEach(ap => {
        var isCommentRequired =false;
        isCommentRequired = (ap.isCommentRequired && !this.isAdmin() &&!this.saveDraft) ||  this.isAdmin();
        if (uniqueProgramIds.length > 0) {
          if(uniqueProgramIds[0].includes(ap.id)){
            if(!this.submittedOrRemovedAccrualPrograms.some(srap => srap.programId === ap.id)){
              this.submittedOrRemovedAccrualPrograms.push({
                programId: ap.id,
                programName: ap.name,
                disabled: isCommentRequired,
                selected: isCommentRequired,
                isCommentRequired: isCommentRequired
              });
            }
          }
        }
      });
  }

  submitRemovalProducts() {
    this.spinner.show();
    this.deleteProgramAccrualRatesByMonth = [];
    this.deleteFormatProgramAccrualRatesByMonth();

    if (this.deleteProgramAccrualRatesByMonth.length > 0) {
      const deleteProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
      this.deleteProgramAccrualRatesByMonth.forEach(dparbm => {
        deleteProgramAccrualRatesByMonth.push({ id: dparbm.id, programId: dparbm.programId, materialHierarchyCommercialId: dparbm.materialHierarchyCommercialId, month: dparbm.month, year: dparbm.year, volumePercentage: dparbm.volumePercentage, payRatePercentage: dparbm.payRatePercentage, accrualPercentage: dparbm.accrualPercentage, statusDefinitionId: dparbm.statusDefinitionId })
      })
      const headers = new HttpHeaders().set('content-type', 'application/json');

      this.context.postdatatoservice("/api/Accrual/Table/DeleteProgramAccrualRates", JSON.stringify(deleteProgramAccrualRatesByMonth), headers)
        .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
          this.insertProductManagerComments(true);
          this.updateProgramTotalSalesAndTotalExposure();
          this.updateProgramToProductManagerAssoc();
          this.newProgramAccrualRatesMonth = [];
          this.sendEmailNotificationToProgramManager(true);
          this.loadAccrualsRates();
          this.addedNewMonths = false;
          this.openSuccessSnackBar('Successfully removed accrual rates.', 'x', 3000);
        },
          msg => { // Error
            this.openErrorSnackBar('Error in removing accrual rates.', 'x', 3000);
          })
        .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
    }
    else {
      this.dataSource.data = this.accrualPrograms;
    }
    this.spinner.hide();
  }

  calculateAccrualRate(materialCode, header, programId, assignRateType, colName, colValue) {
    let volumeAssignRate = '';
    let payRate = '';
    this.programsAccrualsAssignRates.forEach(paar => {
      this.assignRates.forEach(ar => {
        if (ar.programId === programId && ar.materialCode === materialCode && ar.header === header) {
          for (var prop in ar) {
            var columnName = prop;
            if (columnName === colName) {
              if (assignRateType === 'VOLUME %') {
                volumeAssignRate = colValue;
              }
              else if (ar.assignRateType === 'VOLUME %') {
                volumeAssignRate = ar[columnName];
              }
              if (assignRateType === 'PAY RATE %') {
                payRate = colValue;
              }
              else if (ar.assignRateType === 'PAY RATE %') {
                payRate = ar[columnName];
              }
              if (ar.assignRateType === 'ACCRUAL %') {
                if (!isNaN(+volumeAssignRate) && !isNaN(+payRate) && volumeAssignRate.length > 0 && payRate.length > 0) {
                  ar[columnName] = (parseFloat(volumeAssignRate) * parseFloat(payRate) / 100).toString();
                }
                else {
                  ar[columnName] = '';
                }
              }
            }
          }
          ar[colName] = parseFloat(ar[colName]).toString();
          if (ar.assignRateType === 'ACCRUAL %') {
            ar[colName] = parseFloat(ar[colName]).toString();
          }
        }
      })
    })
    this.setIsFormDirty();
  }

  onCancel() {
    this.routeParamsService.sendAccrualsTabIndexRouteParams("2");
    this.routeParamsService.sendProgramIdsRouteParams("");
  }
  onBack() {
    this.routeParamsService.sendIsEditAccrualsRouteParams("true");
  }

  saveProgramAccrualRatesByMonth(statusDefinitionId) {
    this.saveAndSubmitConfirm = true;

    let isAccrualRatesValid = true;
    this.insertProgramAccrualRatesByMonth = [];
    this.existingProgramAccrualRatesByMonth = []
    this.updateProgramAccrualRatesByMonth = [];
    this.insertFormatProgramAccrualRatesByMonth();
    this.updateFormatProgramAccrualRatesByMonth();
    this.addedNewProgramAccrualRatesByMonth.forEach(anparbm => {
      this.assignRates.forEach(ar => {
        if (!ar.header) {
          if (anparbm.programId === ar.programId && anparbm.materialHierarchyCommercialId === ar.materialHierarchyCommercialId) {
            switch (anparbm.monthAndYearColumn) {
              case 1:
                if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, ar._1stMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, null, ar.assignRateType, false);
                }
                break;
              case 2:
                if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, ar._2ndMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, null, ar.assignRateType, false);
                }
                break;
              case 3:
                if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, ar._3rdMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, null, ar.assignRateType, false);
                }
                break;
              case 4:
                if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, ar._4thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, null, ar.assignRateType, false);
                }
                break;
              case 5:
                if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, ar._5thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, null, ar.assignRateType, false);
                }
                break;
              case 6:
                if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, ar._6thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, null, ar.assignRateType, false);
                }
                break;
              case 7:
                if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, ar._7thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, null, ar.assignRateType, false);
                }
                break;
              case 8:
                if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, ar._8thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, null, ar.assignRateType, false);
                }
                break;
              case 9:
                if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, ar._9thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, null, ar.assignRateType, false);
                }
                break;
              case 10:
                if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, ar._10thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, null, ar.assignRateType, false);
                }
                break;
              case 11:
                if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, ar._11thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, null, ar.assignRateType, false);
                }
                break;
              case 12:
                if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, ar._12thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, null, ar.assignRateType, false);
                }
                break;
            }
          }
        }
      })
    })
    this.addedNewProgramAccrualRatesByMonth.forEach(anparbm => {
      this.insertProgramAccrualRatesByMonth.push(anparbm);
    })
    if (this.verifyForWarningMessage(statusDefinitionId)) {
      this.addAccrualsErrorAndWarnings();
      this.saveAndSubmitConfirm = false;
      this.openSaveDraftOrSaveAndSubmitWarningMessageDialog(statusDefinitionId);
    }

  
    if (this.saveAndSubmitConfirm) {
        this.openSaveAndSubmitProceedCommentDialog(isAccrualRatesValid, statusDefinitionId);
    }
  }

  onSaveDraft() {
    this.saveDraft = true;
    this.saveProgramAccrualRatesByMonth(this.statusDefinitionIds.ACCRUALS_PENDING);
  }

  onSaveandSubmit() {
    this.saveDraft = false;
    this.saveProgramAccrualRatesByMonth(this.statusDefinitionIds.ACCRUALS_COMPLETED);
  }

  saveAndSubmitProceed(isAccrualRatesValid, statusDefinitionId) {
    if (statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED) {
      this.programAccrualRatesByMonth.forEach(parbm => {
        if (parbm.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING) {
          if (!this.updateProgramAccrualRatesByMonth.some(element => element.programId === parbm.programId && element.materialHierarchyCommercialId === parbm.materialHierarchyCommercialId
            && element.year === parbm.year && element.month === parbm.month)) {
            this.updateProgramAccrualRatesByMonth.push(parbm);
          }
        }
      })
    }

    this.checkAddingNewAccrualsExistsInPreviousDeletedAccruals();
    this.removeExistingDeletedAccrualsFromInsertNewAccruals();
    if (!isAccrualRatesValid && this.saveAndSubmitConfirm) {
      this.openErrorSnackBar('Invalid accrual rates -- all nulls', 'x', 3000);
    }
    this.spinner.show();
    const headers = new HttpHeaders().set('content-type', 'application/json');

    if (this.saveAndSubmitConfirm) {
      if (this.insertProgramAccrualRatesByMonth.length > 0) {
        const addProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];

        this.insertProgramAccrualRatesByMonth.forEach(iparbm => {
          addProgramAccrualRatesByMonth.push({ id: null, programId: iparbm.programId, materialHierarchyCommercialId: iparbm.materialHierarchyCommercialId, month: iparbm.month, year: iparbm.year, volumePercentage: iparbm.volumePercentage, payRatePercentage: iparbm.payRatePercentage, accrualPercentage: iparbm.accrualPercentage, statusDefinitionId: statusDefinitionId })
        })
        this.uniqueProgramandMaterialHierarchyCommercial = addProgramAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);
        let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let existingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let emptyExistingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let existingAllZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let updatedProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];

        this.uniqueProgramandMaterialHierarchyCommercial.forEach(upm => {
          if (isAccrualRatesValid) {
            let numberOfRateColumns = this.getNumberOfAssignRatesMonth(upm.programId, upm.materialHierarchyCommercialId);

            updatedProgramAccrualRatesByMonths = this.updateProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
            if (updatedProgramAccrualRatesByMonths.length > 0) {
              existingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
              emptyExistingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
              existingAllZeroProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
            }
            else {
              existingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
              emptyExistingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
              existingAllZeroProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
            }

            programAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
            emptyProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
            allZeroProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
            let newMaterialRatesCount = programAccrualRatesByMonths.length;
            let existingMaterialRatesCount = existingProgramAccrualRatesByMonths.length;

            if (numberOfRateColumns === newMaterialRatesCount && existingMaterialRatesCount === 0) {
              if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length) {
                isAccrualRatesValid = false;
                this.openErrorSnackBar('Invalid new accrual rates -- all nulls', 'x', 3000);
              }
            }
            else {
              if (programAccrualRatesByMonths.length > 0 && existingMaterialRatesCount > 0 && existingMaterialRatesCount !== numberOfRateColumns) {
                if ((newMaterialRatesCount === emptyProgramAccrualRatesByMonths.length) && (existingMaterialRatesCount === emptyExistingProgramAccrualRatesByMonths.length)) {
                  isAccrualRatesValid = false;
                  this.openErrorSnackBar('Invalid new accrual rates -- all nulls', 'x', 3000);
                }
              }
            }
          }
        })
        if (isAccrualRatesValid) {
          addProgramAccrualRatesByMonth.forEach(aparbm => {
            if (aparbm.volumePercentage === null) {
              aparbm.volumePercentage = 0;
              aparbm.accrualPercentage = 0;
            }
            if (aparbm.payRatePercentage === null) {
              aparbm.payRatePercentage = 0;
              aparbm.accrualPercentage = 0;
            }
          })
          this.context.postdatatoservice("/api/Accrual/Table/InsertProgramAccrualRates", JSON.stringify(addProgramAccrualRatesByMonth), headers)
            .toPromise().then((response) => {
              if (this.updateProgramAccrualRatesByMonth.length === 0) {
                this.insertProductManagerComments(false);
                this.updateProgramTotalSalesAndTotalExposure();
                this.updateProgramToProductManagerAssoc();
                if (statusDefinitionId == this.statusDefinitionIds.ACCRUALS_COMPLETED) {
                  this.sendEmailNotificationToProgramManager(false);
                }
                this.newProgramAccrualRatesMonth = [];
                this.loadAccrualsRates();
                this.addedNewMonths = false;
              }
              this.openSuccessSnackBar('Successfully saved accrual rates.', 'x', 3000);
            },
              msg => { // Error
                this.openErrorSnackBar('Error in adding accrual rates.', 'x', 3000);
              })
            .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
        }
      }
      if (isAccrualRatesValid) {
        if (this.updateProgramAccrualRatesByMonth.length > 0) {
          const updateProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
          this.updateProgramAccrualRatesByMonth.forEach(uparbm => {
            updateProgramAccrualRatesByMonth.push({ id: uparbm.id, programId: uparbm.programId, materialHierarchyCommercialId: uparbm.materialHierarchyCommercialId, month: uparbm.month, year: uparbm.year, volumePercentage: uparbm.volumePercentage, payRatePercentage: uparbm.payRatePercentage, accrualPercentage: uparbm.accrualPercentage, statusDefinitionId: statusDefinitionId })
          })         
          this.uniqueProgramandMaterialHierarchyCommercial = updateProgramAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);
          let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
          let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
          let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
          this.uniqueProgramandMaterialHierarchyCommercial.forEach(upm => {
            if (isAccrualRatesValid) {
              programAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
              emptyProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
              allZeroProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
              if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length) {
                isAccrualRatesValid = false;
                this.openErrorSnackBar('Invalid existing accrual rates -- all nulls', 'x', 3000);
              }
            }
          })
          if (isAccrualRatesValid) {
            updateProgramAccrualRatesByMonth.forEach(aparbm => {
              if (aparbm.volumePercentage === null) {
                aparbm.volumePercentage = 0;
                aparbm.accrualPercentage = 0;
              }
              if (aparbm.payRatePercentage === null) {
                aparbm.payRatePercentage = 0;
                aparbm.accrualPercentage = 0;
              }
            })
            this.context.postdatatoservice("/api/Accrual/Table/UpdateProgramAccrualRates", JSON.stringify(updateProgramAccrualRatesByMonth), headers)
              .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
                  this.insertProductManagerComments(false);
                  this.updateProgramTotalSalesAndTotalExposure();
                  this.updateProgramToProductManagerAssoc();
                  if (statusDefinitionId == this.statusDefinitionIds.ACCRUALS_COMPLETED) {
                    this.sendEmailNotificationToProgramManager(false);
                  }
                  this.newProgramAccrualRatesMonth = [];
                  this.loadAccrualsRates();
                  this.addedNewMonths = false;
                this.openSuccessSnackBar('Successfully saved accrual rates.', 'x', 3000);
              },
                msg => { // Error
                  this.openErrorSnackBar('Error in updating accrual rates.', 'x', 3000);
                })
              .catch(error => this.openErrorSnackBar(error.message, 'x', 3000));
          }
        }
      }    
    }

    this.selection.clear();
    this.checklistSelection.clear();
    this.spinner.hide();
  }
  verifyForWarningMessage(statusDefinitionId): boolean {
    this.warningMessages = [];
    this.programsAccrualsErrorAndWarningsMessages = [];
    if (this.insertProgramAccrualRatesByMonth.length > 0) {
      const addProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
      this.insertProgramAccrualRatesByMonth.forEach(iparbm => {
        addProgramAccrualRatesByMonth.push({ id: null, programId: iparbm.programId, materialHierarchyCommercialId: iparbm.materialHierarchyCommercialId, month: iparbm.month, year: iparbm.year, volumePercentage: iparbm.volumePercentage, payRatePercentage: iparbm.payRatePercentage, accrualPercentage: iparbm.accrualPercentage, statusDefinitionId: statusDefinitionId })
      })

      this.uniqueProgramandMaterialHierarchyCommercial = addProgramAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);

      let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let existingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let emptyExistingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let existingAllZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let updatedProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      this.uniqueProgramandMaterialHierarchyCommercial.forEach(upm => {
        let numberOfRateColumns = this.getNumberOfAssignRatesMonth(upm.programId, upm.materialHierarchyCommercialId);

        updatedProgramAccrualRatesByMonths = this.updateProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
        if (updatedProgramAccrualRatesByMonths.length > 0) {
          existingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
          emptyExistingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
          existingAllZeroProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        }
        else {
          existingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
          emptyExistingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
          existingAllZeroProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        }

        programAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
        emptyProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
        allZeroProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        let newMaterialRatesCount = programAccrualRatesByMonths.length;
        let existingMaterialRatesCount = existingProgramAccrualRatesByMonths.length;
        if (numberOfRateColumns === newMaterialRatesCount && existingMaterialRatesCount === 0) {
          if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length) {
            if (!this.warningMessages.some(wm => wm.messageId === 1)) {
              this.warningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
            }
            this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
          }
          else {
            if (programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length) {
              if (!this.warningMessages.some(wm => wm.messageId === 3)) {
                this.warningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
              }
              this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
            }
            else {
              if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
                if (!this.warningMessages.some(wm => wm.messageId === 4)) {
                  this.warningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
                }
                this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
              }
              if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
                if (!this.warningMessages.some(wm => wm.messageId === 2)) {
                  this.warningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
                }
                this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
              }
            }
          }
        }
        else {
          if (programAccrualRatesByMonths.length > 0 && existingMaterialRatesCount > 0 && existingMaterialRatesCount !== numberOfRateColumns) {
            if ((newMaterialRatesCount === emptyProgramAccrualRatesByMonths.length) && (existingMaterialRatesCount === emptyExistingProgramAccrualRatesByMonths.length)) {
              if (!this.warningMessages.some(wm => wm.messageId === 1)) {
                this.warningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
              }
              this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
            }
            else {
              if (programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length && existingAllZeroProgramAccrualRatesByMonths.length === existingMaterialRatesCount) {
                if (!this.warningMessages.some(wm => wm.messageId === 3)) {
                  this.warningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
                }
                this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
              }
              else {
                if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null) || existingProgramAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
                  if (!this.warningMessages.some(wm => wm.messageId === 4)) {
                    this.warningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
                  }
                  this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
                }
                if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100) || existingProgramAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
                  if (!this.warningMessages.some(wm => wm.messageId === 2)) {
                    this.warningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
                  }
                  this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
                }
              }
            }
          }
        }
      })
    }

    if (this.updateProgramAccrualRatesByMonth.length > 0) {
      const updateProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
      this.updateProgramAccrualRatesByMonth.forEach(uparbm => {
        updateProgramAccrualRatesByMonth.push({ id: uparbm.id, programId: uparbm.programId, materialHierarchyCommercialId: uparbm.materialHierarchyCommercialId, month: uparbm.month, year: uparbm.year, volumePercentage: uparbm.volumePercentage, payRatePercentage: uparbm.payRatePercentage, accrualPercentage: uparbm.accrualPercentage, statusDefinitionId: statusDefinitionId })
      })
      this.uniqueProgramandMaterialHierarchyCommercial = updateProgramAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);
      let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
      let materialRatesCount = 0;
      this.uniqueProgramandMaterialHierarchyCommercial.forEach(upm => {

        programAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId);
        emptyProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
        allZeroProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === upm.programId && element.materialHierarchyCommercialId === upm.materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        programAccrualRatesByMonths.forEach(element => {
          if (this.existingDeletedProgramAccrualRatesByMonth.some(edparbm => edparbm.programId === element.programId && edparbm.materialHierarchyCommercialId === element.materialHierarchyCommercialId && edparbm.month === element.month && edparbm.year === element.year)) {
            materialRatesCount = programAccrualRatesByMonths.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId).length;
          }
          else {
            materialRatesCount = this.programAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId).length;
          }
        });

        if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length && emptyProgramAccrualRatesByMonths.length === materialRatesCount) {
          if (!this.warningMessages.some(wm => wm.messageId === 1)) {
            this.warningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
          }
          this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
        }
        else {
          if (programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length && allZeroProgramAccrualRatesByMonths.length === materialRatesCount) {
            if (!this.warningMessages.some(wm => wm.messageId === 3)) {
              this.warningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
            }
            this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
          }
          else {
            if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
              if (!this.warningMessages.some(wm => wm.messageId === 4)) {
                this.warningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
              }
              this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
            }
            if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
              if (!this.warningMessages.some(wm => wm.messageId === 2)) {
                this.warningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
              }
              this.programsAccrualsErrorAndWarningsMessages.push({ programId: upm.programId, materialHierarchyCommercialId: upm.materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
            }
          }
        }
      })
    }
    if (this.warningMessages.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  onAssignRates() {
    this.openAssignRatesDialog();
  }
  onAddProducts() {
    this.openAddProductsDialog();
  }

  onRemove() {
    this.openRemoveProductsDialog();
  }

  bindtabledata(ids) {
    this.programAccrual.id = ids;
    this.spinner.show();
    const headers = new HttpHeaders().set('content-type', 'application/json');
    if (ids === null || ids.length === 0) {
      this.router.navigate(['/accruals'])
    }
    else {
      this.context.postdatatoservice("/api/Accrual/Details/GetDeletedProgramAccrualRates", JSON.stringify(this.programAccrual), headers).toPromise().then((response) => {
        if (response != null) {
          this.existingDeletedProgramAccrualRatesByMonth = response;
        }
      });

      this.context.postdatatoservice("/api/Accrual/Details/GetProgramAccrualRates", JSON.stringify(this.programAccrual), headers).toPromise().then((response) => {
        if (response != null) {
          this.programAccrualRatesByMonth = response;
          if (this.programAccrualRatesByMonth && this.programAccrualRatesByMonth.length > 0) {
            this.materialHierarchyCommercialIds = [...new Set(this.programAccrualRatesByMonth.map(item => item.materialHierarchyCommercialId))];
            if (this.materialHierarchyCommercialIds && this.materialHierarchyCommercialIds.length > 0) {
              this.context.postdatatoservice("/api/Accrual/Details/GetAccuralsMaterialHierarchyCommercial", JSON.stringify(this.materialHierarchyCommercialIds), headers).toPromise().then((response) => {
                if (response != null) {
                  this.programAccrualsExistingProducts = response;
                  if (this.accrualPrograms.length === 0) {
                    this.programs = [];
                    this.context.postdatatoservice("/api/Accrual/Details/AccrualPrograms", JSON.stringify(ids), headers)
                      .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
                        if (response != null) {
                          this.programs = response;
                            this.bindPrograms(true);
                        }
                      });
                  }
                  else{
                    this.bindPrograms(true);
                  }
                }
              });
            }
          }
          else {
            this.context.postdatatoservice("/api/Accrual/Details/AccrualPrograms", JSON.stringify(ids), headers)
          .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
            if (response != null) {
              this.programs = response;
              if (this.accrualPrograms.length === 0) {
                this.bindPrograms(false);
              }
              else{
                this.nonUpdatedAssignRates = JSON.parse(JSON.stringify(this.assignRates));
                this.setProgramChipColors();
                this.checkSaveAndSubmit();
                this.dataSource.data = this.accrualPrograms;
                this.dataSourceAssignRates.data = this.assignRates;
                this.isFormDirty = false;
                this.setIsFormDirty();
                this.spinner.hide();
              }
            }
          });
          }
        }
        else {
          this.context.postdatatoservice("/api/Accrual/Details/AccrualPrograms", JSON.stringify(ids), headers)
          .pipe(takeUntil(this.cancelSubject)).toPromise().then((response) => {
            if (response != null) {
              this.programs = response;
              if (this.accrualPrograms.length === 0) {
                this.bindPrograms(false);
              }              
            }
          });
        } 
      });
    }
  }

  buildMaterialHierarchyCommercialsAndAccrualPrograms(masterSelectedHierarchyLevel,isLoadAccrualRates) {
    this.buildMaterialHierarchyCommercials(masterSelectedHierarchyLevel, isLoadAccrualRates);
    this.addSelectedProductsToSelectedAccrualsPrograms(this.accrualPrograms, this.accrualsProducts);
   
      if (this.programAccrualRatesByMonth.length > 0) {
        this.programAccrualRatesByMonth.forEach(parbm => {
          this.accrualPrograms.forEach(ap => {
            if (parbm.programId === ap.id) {
              this.programAccrualsExistingProducts.forEach(paep => {
                if (paep.id === parbm.materialHierarchyCommercialId) {
                  this.addAssignRates(ap, paep);
                }
              });
            }
          })
        })
      }
      
   
    this.updateAutoFillAssignRates();
    this.updateAssignRatesFromRatesByMonth();
    this.insertFormatProgramAccrualRatesByMonth();
    this.addedNewProgramAccrualRatesByMonth = [];
    this.addedNewProgramAccrualRatesByMonth = JSON.parse(JSON.stringify(this.existingProgramAccrualRatesByMonth.filter(eparbm => eparbm.accrualPercentage === null && eparbm.volumePercentage === null && eparbm.payRatePercentage === null)));
    this.newProgramAccrualRatesMonth = [];
    this.addedNewProgramAccrualRatesByMonth.forEach(anparbm => {
      this.assignRates.forEach(ar => {
        if (ar.header) {
          if (anparbm.programId === ar.programId && anparbm.materialHierarchyCommercialId === ar.materialHierarchyCommercialId) {
            switch (anparbm.monthAndYearColumn) {
              case 1:
                if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._1stMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._1stMonthAssignRate })
                  }
                }
                break;
              case 2:
                if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._2ndMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._2ndMonthAssignRate })
                  }
                }
                break;
              case 3:
                if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._3rdMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._3rdMonthAssignRate })
                  }
                }
                break;
              case 4:
                if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._4thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._4thMonthAssignRate })
                  }
                }
                break;
              case 5:
                if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._5thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._5thMonthAssignRate })
                  }
                }
                break;
              case 6:
                if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._6thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._6thMonthAssignRate })
                  }
                }
                break;
              case 7:
                if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._7thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._7thMonthAssignRate })
                  }
                }
                break;
              case 8:
                if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._8thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._8thMonthAssignRate })
                  }
                }
                break;
              case 9:
                if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._9thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._9thMonthAssignRate })
                  }
                }
                break;
              case 10:
                if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._10thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._10thMonthAssignRate })
                  }
                }
                break;
              case 11:
                if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._11thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._11thMonthAssignRate })
                  }
                }
                break;
              case 12:
                if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._12thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._12thMonthAssignRate })
                  }
                }
                break;
            }
          }
        }
      })
    })
  }

  getAllAccrualProgramsMaterialHierarchyCommercialIds() {
    this.materialHierarchyCommercialIds = [];
    this.accrualPrograms.forEach(aprg => {
      this.assignRates.forEach(ar => {
        if (aprg.id === ar.programId) {
          if (this.materialHierarchyCommercialIds.length > 0) {
            if (!this.materialHierarchyCommercialIds.includes(ar.materialHierarchyCommercialId)) {
              this.materialHierarchyCommercialIds.push(ar.materialHierarchyCommercialId)
            }
          }
          else {
            this.materialHierarchyCommercialIds.push(ar.materialHierarchyCommercialId)
          }
        }
      }
      );
    });
  }

  bindMasterHierarchySelectionChangeMaterialHierarchyCommercials(masterSelectedHierarchyLevel) {
    this.getAllAccrualProgramsMaterialHierarchyCommercialIds();
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.context.postdatatoservice("/api/Accrual/Details/GetAccuralsMaterialHierarchyCommercial", JSON.stringify(this.materialHierarchyCommercialIds), headers).toPromise().then((response) => {
      if (response != null) {
        this.materialHierarchyCommercials = response;
        this.buildMaterialHierarchyCommercials(masterSelectedHierarchyLevel, false);
        this.bindMasterHierarchySelectionChangeAccrualsPrograms();
        if (this.expandCollapseAllAtHierarchyLevel) {
          this.allAccrualsProgramFlatNodes = this.treeControl.dataNodes;
        this.allAccrualsProgramFlatNodes.forEach(pfn => {
        this.treeControl.expandDescendants(pfn);
      })
        }
         this.spinner.hide();
      }
    });
  }

  bindMasterHierarchySelectionChangeAccrualsPrograms() {
    this.hierarchySelectionChangeAccrualPrograms = [];

    this.programs.forEach(p => {
      this.hierarchySelectionChangeAccrualPrograms.push(
        {
          id: p.id,
          programCode: p.code,
          name: p.programName,
          programManagerId: p.programManagerId,
          programManagerEmail: p.programManagerEmail,
          statusDefinitionId: p.statusDefinitionId,
          startDate: this.formatDate(p.startDate),
          endDate: this.formatDate(p.endDate),
          children: [],
          isAdded: false,
          numberOfColumns: this.numberOfColumns(p.startDate, p.endDate),
          isSelected: false,
          isProgramLevelDraft: null,
          isPastEndDate: p.isPastEndDate,
          isCommentRequired: p.isCommentRequired
        },
      )
    })

    this.hierarchySelectionChangeAccrualPrograms.forEach(hscap => {
      this.accrualPrograms.forEach(aprg => {
        if (hscap.id === aprg.id) {
          hscap.isSelected = aprg.isSelected;
        }
      });
    });
    this.accrualPrograms = this.hierarchySelectionChangeAccrualPrograms;
 
    this.hierarchySelectionChangeAddSelectedProductsToSelectedAccrualsPrograms(this.accrualPrograms, this.accrualsProducts);
    this.setProgramChipColors();
    this.checkSaveAndSubmit();
    this.dataSource.data = this.accrualPrograms;
    this.dataSourceAssignRates.data = this.assignRates;
    this.visualSelectedAssignRatesOrRemoveProducts(this.uniqueSelectAssignRatesOrRemoveProducts)
    this.isFormDirty = false;
    this.setIsFormDirty();
  }

  buildMaterialHierarchyCommercials(masterSelectedHierarchyLevel,isLoadAccrualRates) {
    this.accrualsProducts = [];
      if(isLoadAccrualRates){
        this.materialHierarchyCommercials = this.programAccrualsExistingProducts;
      }
    if (this.materialHierarchyCommercials.length > 0) {
      switch (masterSelectedHierarchyLevel) {
        case 0:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({id:m.id, product: m.performanceCenterName, productHierarchyLevel: 0, productHierarchyCode: m.performanceCenterCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.performanceCenterCode && element.id === m.id))) {
              this.accrualsProducts.push({id:m.id, product: m.performanceCenterName, productHierarchyLevel: 0, productHierarchyCode: m.performanceCenterCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.performanceCenterCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 1:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({id:m.id, product: m.productCenterName, productHierarchyLevel: 0, productHierarchyCode: m.productCenterCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.productCenterCode && element.id === m.id))) {
              this.accrualsProducts.push({ id:m.id,product: m.productCenterName, productHierarchyLevel: 0, productHierarchyCode: m.productCenterCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.productCenterCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 2:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({ id:m.id,product: m.plannedProductName, productHierarchyLevel: 0, productHierarchyCode: m.plannedProductCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.plannedProductCode && element.id === m.id))) {
              this.accrualsProducts.push({ id:m.id,product: m.plannedProductName, productHierarchyLevel: 0, productHierarchyCode: m.plannedProductCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.plannedProductCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 3:
         
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({id:m.id, product: m.tradeProductName, productHierarchyLevel: 0, productHierarchyCode: m.tradeProductCode, isHierarchySelected: false, children: [] });
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.tradeProductCode && element.id === m.id))) {
              this.accrualsProducts.push({id:m.id, product: m.tradeProductName, productHierarchyLevel: 0, productHierarchyCode: m.tradeProductCode, isHierarchySelected: false, children: [] });
            }
          });
          this.accrualsProducts.forEach(ap => {
            this.materialHierarchyCommercials.forEach(m => {
              if (ap.productHierarchyCode === m.tradeProductCode) {
                this.searchAndAddMaterialHierarchyCommercial(ap, m);
              }
            });
          });
          break;
        case 4:
          this.materialHierarchyCommercials.forEach(m => {
            if (this.accrualsProducts.length === 0) {
              this.accrualsProducts.push({id: m.id,product: m.materialName, productHierarchyLevel: 0, productHierarchyCode: m.materialCode,parentHierarchyCode:m.tradeProductCode, isProductSelected: false })
            }
            if (!(this.accrualsProducts.some(element => element.productHierarchyCode === m.materialCode && element.id === m.id))) {
              this.accrualsProducts.push({ id: m.id, product: m.materialName, productHierarchyLevel: 0, productHierarchyCode: m.materialCode,parentHierarchyCode:m.tradeProductCode, isProductSelected: false });
            }
          });
          break;
      }
    }
  }

  visualSelectedAssignRatesOrRemoveProducts(selectedList) {
    this.checklistSelection.clear();
    selectedList.forEach(sarrp => {
      for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
        if (this.treeControl.dataNodes[i].id == sarrp.id && this.treeControl.dataNodes[i].materialHierarchyCommercialId == sarrp.materialHierarchyCommercialId) {
          this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
          this.treeControl.expand(this.treeControl.dataNodes[i])
        }
      }
    })
  }

  updateAssignRatesFromRatesByMonth() {
    if (this.programAccrualRatesByMonth.length > 0) {
      this.programAccrualRatesByMonth.forEach(parbm => {
        var identifiedMonth = '';
        this.assignRates.forEach(ar => {
          if (parbm.materialHierarchyCommercialId === ar.materialHierarchyCommercialId && parbm.programId === ar.programId) {
            if (ar.header === true) {
              let assignRateMonth = String(parbm.month).padStart(2, '0') + '/' + parbm.year.toString();

              switch (assignRateMonth) {
                case ar._1stMonthAssignRate:
                  identifiedMonth = '_1stMonthAssignRate';
                  break;
                case ar._2ndMonthAssignRate:
                  identifiedMonth = '_2ndMonthAssignRate';
                  break;
                case ar._3rdMonthAssignRate:
                  identifiedMonth = '_3rdMonthAssignRate';
                  break;
                case ar._4thMonthAssignRate:
                  identifiedMonth = '_4thMonthAssignRate';
                  break;
                case ar._5thMonthAssignRate:
                  identifiedMonth = '_5thMonthAssignRate';
                  break;
                case ar._6thMonthAssignRate:
                  identifiedMonth = '_6thMonthAssignRate';
                  break;
                case ar._7thMonthAssignRate:
                  identifiedMonth = '_7thMonthAssignRate';
                  break;
                case ar._8thMonthAssignRate:
                  identifiedMonth = '_8thMonthAssignRate';
                  break;
                case ar._9thMonthAssignRate:
                  identifiedMonth = '_9thMonthAssignRate';
                  break;
                case ar._10thMonthAssignRate:
                  identifiedMonth = '_10thMonthAssignRate';
                  break;
                case ar._11thMonthAssignRate:
                  identifiedMonth = '_11thMonthAssignRate';
                  break;
                case ar._12thMonthAssignRate:
                  identifiedMonth = '_12thMonthAssignRate';
                  break;
              }
            }
            else {
              switch (ar.assignRateType) {
                case 'VOLUME %':
                  ar[identifiedMonth] = parbm.volumePercentage.toString();
                  break;
                case 'PAY RATE %':
                  ar[identifiedMonth] = parbm.payRatePercentage.toString();
                  break;
                case 'ACCRUAL %':
                  ar[identifiedMonth] = parbm.accrualPercentage.toString();
                  break;
              }
            }
          }
        })
      })
    }
  }

  searchAndAddMaterialHierarchyCommercial(ap, MaterialHierarchyCommercial) {
    var childrenCounter = 0;
    var childrenChildCounter = 0;
    var childrenChildrenChildCounter = 0;
    switch (this.masterSelectedHierarchyLevel) {
      case 0:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.performanceCenterCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.productCenterCode  && element.id === MaterialHierarchyCommercial.id))) {
                ap.children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.productCenterName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.productCenterCode, parentHierarchyCode: MaterialHierarchyCommercial.performanceCenterCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.productCenterName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.productCenterCode, parentHierarchyCode: MaterialHierarchyCommercial.performanceCenterCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.productCenterCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode  && element.id === MaterialHierarchyCommercial.id))) {
                  ap.children[childrenCounter - 1].children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
        childrenChildCounter = ap.children[childrenCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyLevel === 2) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode && element.id === MaterialHierarchyCommercial.id))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
    
        childrenChildrenChildCounter = ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].productHierarchyLevel === 3) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 4, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children[childrenChildrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 4, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 1:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.productCenterCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode  && element.id === MaterialHierarchyCommercial.id))) {
                ap.children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.plannedProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, parentHierarchyCode: MaterialHierarchyCommercial.productCenterCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode && element.id === MaterialHierarchyCommercial.id))) {
                  ap.children[childrenCounter - 1].children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
          }
        }
        childrenChildCounter = ap.children[childrenCounter - 1].children.length;
        if (typeof ap.children[childrenCounter - 1].children[childrenChildCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyLevel === 2) {
            if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode  && element.id === MaterialHierarchyCommercial.id))) {
                  ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children[childrenChildCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 3, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 2:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.plannedProductCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode && element.id === MaterialHierarchyCommercial.id))) {
                ap.children.push({id:MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
              }
            }
            else {
              ap.children.push({ id:MaterialHierarchyCommercial.id,product: MaterialHierarchyCommercial.tradeProductName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, parentHierarchyCode: MaterialHierarchyCommercial.plannedProductCode, isHierarchySelected: false, children: [] })
            }
          }
        }
        childrenCounter = ap.children.length;
        if (typeof ap.children[childrenCounter - 1] === 'object') {
          if (ap.children[childrenCounter - 1].productHierarchyLevel === 1) {
            if (ap.children[childrenCounter - 1].productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
              if (ap.children[childrenCounter - 1].children.length > 0) {
                if (!(ap.children[childrenCounter - 1].children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode && element.id === MaterialHierarchyCommercial.id))) {
                  ap.children[childrenCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
                }
              }
              else {
                ap.children[childrenCounter - 1].children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 2, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
          }
        }
        break;
      case 3:
        if (ap.productHierarchyLevel === 0) {
          if (ap.productHierarchyCode === MaterialHierarchyCommercial.tradeProductCode) {
            if (ap.children.length > 0) {
              if (!(ap.children.some(element => element.productHierarchyCode === MaterialHierarchyCommercial.materialCode && element.id === MaterialHierarchyCommercial.id))) {
                ap.children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
              }
            }
            else {
              ap.children.push({ id: MaterialHierarchyCommercial.id, product: MaterialHierarchyCommercial.materialName, productHierarchyLevel: 1, productHierarchyCode: MaterialHierarchyCommercial.materialCode, parentHierarchyCode: MaterialHierarchyCommercial.tradeProductCode, isProductSelected: false })
            }
          }
        }
        break;
    }
  }

  addSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, accrualsProducts) {
    accrualsProducts.forEach(ap => {
      this.searchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap)
      if (typeof ap.children === 'object') {
        this.addSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, ap.children);
      }
    });
  }
  
  hierarchySelectionChangeAddSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, accrualsProducts) {
    accrualsProducts.forEach(ap => {
      this.hierarchySelectionChangeSearchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap)
      if (typeof ap.children === 'object') {
        this.hierarchySelectionChangeAddSelectedProductsToSelectedAccrualsPrograms(accrualsPrograms, ap.children);
      }
    });
  }

  searchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap) {
    accrualsPrograms.forEach(aprg => {
      if (this.programAccrualRatesByMonth.length > 0) {
        this.programAccrualRatesByMonth.forEach(parbm => {
          if (aprg.id === parbm.programId && ap.id === parbm.materialHierarchyCommercialId) {
            switch (this.masterSelectedHierarchyLevel) {
              case 0:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 3:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                          if (aprgChildChildChild.children.length > 0) {
                                            if (!(aprgChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                              aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                            }
                                          }
                                          else {
                                            aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                          }
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 4:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.children.length > 0) {
                                          aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                            if (typeof aprgChildChildChildChild.children === 'object') {
                                              if (aprgChildChildChildChild.materialCode === ap.parentHierarchyCode) {
                                                if (aprgChildChildChildChild.children.length > 0) {
                                                  if (!(aprgChildChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                                    aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                                  }
                                                }
                                                else {
                                                  aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                                }
                                              }
                                            }
                                          })
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 1:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 3:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                          if (aprgChildChildChild.children.length > 0) {
                                            if (!(aprgChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                              aprgChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                            }
                                          }
                                          else {
                                            aprgChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                          }
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 2:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired,numberOfColumns: aprg.numberOfColumns });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 3:
                switch (ap.productHierarchyLevel) {
                  case 0:                  
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 4:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                      }
                    }
                    break;
                }
                break;
            }
          }
        })
      }
    });
  }

  hierarchySelectionChangeSearchAndAddMaterialHierarchytoAccrualsPrograms(accrualsPrograms, ap) {
    accrualsPrograms.forEach(aprg => {
      if (this.assignRates.length > 0) {
        this.assignRates.forEach(ar => {
          if (aprg.id === ar.programId && ar.header && ar.materialHierarchyCommercialId === ap.id) {
            switch (this.masterSelectedHierarchyLevel) {
              case 0:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 3:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                          if (aprgChildChildChild.children.length > 0) {
                                            if (!(aprgChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                              aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                            }
                                          }
                                          else {
                                            aprgChildChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                          }
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 4:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.children.length > 0) {
                                          aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                                            if (typeof aprgChildChildChildChild.children === 'object') {
                                              if (aprgChildChildChildChild.materialCode === ap.parentHierarchyCode) {
                                                if (aprgChildChildChildChild.children.length > 0) {
                                                  if (!(aprgChildChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                                    aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                                  }
                                                }
                                                else {
                                                  aprgChildChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                                }
                                              }
                                            }
                                          })
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 1:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 3:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.children.length > 0) {
                                    aprgChildChild.children.forEach(aprgChildChildChild => {
                                      if (typeof aprgChildChildChild.children === 'object') {
                                        if (aprgChildChildChild.materialCode === ap.parentHierarchyCode) {
                                          if (aprgChildChildChild.children.length > 0) {
                                            if (!(aprgChildChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                              aprgChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                            }
                                          }
                                          else {
                                            aprgChildChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                          }
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 2:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                  case 2:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.children.length > 0) {
                              aprgChild.children.forEach(aprgChildChild => {
                                if (typeof aprgChildChild.children === 'object') {
                                  if (aprgChildChild.materialCode === ap.parentHierarchyCode) {
                                    if (aprgChildChild.children.length > 0) {
                                      if (!(aprgChildChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                        aprgChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                      }
                                    }
                                    else {
                                      aprgChildChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                    }
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 3:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialCode: ap.productHierarchyCode, programName: aprg.name, name: ap.product, isAdded: false, isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, children: [] });
                      }
                    }
                    break;
                  case 1:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        aprg.children.forEach(aprgChild => {
                          if (typeof aprgChild.children === 'object') {
                            if (aprgChild.materialCode === ap.parentHierarchyCode) {
                              if (aprgChild.children.length > 0) {
                                if (!(aprgChild.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                                  aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                                }
                              }
                              else {
                                aprgChild.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                              }
                            }
                          }
                        })
                      }
                    }
                    break;
                }
                break;
              case 4:
                switch (ap.productHierarchyLevel) {
                  case 0:
                    if (typeof aprg.children === 'object') {
                      if (aprg.children.length > 0) {
                        if (!(aprg.children.some(element => element.id === aprg.id && element.materialCode === ap.productHierarchyCode))) {
                          aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                        }
                      }
                      else {
                        aprg.children.push({ id: aprg.id, materialHierarchyCommercialId: ap.id, materialCode: ap.productHierarchyCode, parentHierarchyCode: ap.parentHierarchyCode, programName: aprg.name, name: ap.product, isAdded: true,isPastEndDate: aprg.isPastEndDate, isCommentRequired: aprg.isCommentRequired, numberOfColumns: aprg.numberOfColumns });
                      }
                    }
                    break;
                }
                break;
            }
          }
        })
      }
    });
  }

  addAssignRates(accrualProgram, materialHierarchyCommercial) {
    if (!(this.assignRates.some(element => element.materialCode === materialHierarchyCommercial.materialCode && element.programId === accrualProgram.id && element.materialHierarchyCommercialId === materialHierarchyCommercial.id))) {
      this.assignRates.push({
        programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, header: true, assignRateType: '', _1stMonthAssignRate: accrualProgram.startDate, _2ndMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 1, accrualProgram.endDate), _3rdMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 2, accrualProgram.endDate), _4thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 3, accrualProgram.endDate)
        , _5thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 4, accrualProgram.endDate), _6thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 5, accrualProgram.endDate), _7thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 6, accrualProgram.endDate), _8thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 7, accrualProgram.endDate), _9thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 8, accrualProgram.endDate)
        , _10thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 9, accrualProgram.endDate), _11thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 10, accrualProgram.endDate), _12thMonthAssignRate: this.assignRateNextMonth(accrualProgram.startDate, 11, accrualProgram.endDate)
      },
        {
          programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, header: false, assignRateType: 'VOLUME %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
          , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
          , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
        },
        {
          programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, header: false, assignRateType: 'PAY RATE %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
          , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
          , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
        },
        {
          programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, header: false, assignRateType: 'ACCRUAL %', _1stMonthAssignRate: '', _2ndMonthAssignRate: '', _3rdMonthAssignRate: '', _4thMonthAssignRate: ''
          , _5thMonthAssignRate: '', _6thMonthAssignRate: '', _7thMonthAssignRate: '', _8thMonthAssignRate: '', _9thMonthAssignRate: ''
          , _10thMonthAssignRate: '', _11thMonthAssignRate: '', _12thMonthAssignRate: ''
        },
      );
    }
    if (!(this.programsAccrualsAssignRates.some(element => element.programId === accrualProgram.id))) {
      this.programsAccrualsAssignRates.push({
        programId: accrualProgram.id, programName: accrualProgram.name,
        autoFillAssignRates: [
          {
            programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, performanceCenterCode: materialHierarchyCommercial.performanceCenterCode, performanceCenterName: materialHierarchyCommercial.performanceCenterName, productCenterCode: materialHierarchyCommercial.productCenterCode, productCenterName: materialHierarchyCommercial.productCenterName, plannedProductCode: materialHierarchyCommercial.plannedProductCode, plannedProductName: materialHierarchyCommercial.plannedProductName, tradeProductCode: materialHierarchyCommercial.tradeProductCode, tradeProductName: materialHierarchyCommercial.tradeProductName, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, isAutoAssign: false, volume: null, payRate: null, accrual: null, startDate: accrualProgram.startDate, endDate: accrualProgram.endDate, dates: [{ value: 2, viewValue: accrualProgram.startDate },
            { value: 3, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 1, accrualProgram.endDate) }, { value: 4, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 2, accrualProgram.endDate) }, { value: 5, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 3, accrualProgram.endDate) },
            { value: 6, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 4, accrualProgram.endDate) }, { value: 7, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 5, accrualProgram.endDate) }, { value: 8, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 6, accrualProgram.endDate) },
            { value: 9, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 7, accrualProgram.endDate) }, { value: 10, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 8, accrualProgram.endDate) }, { value: 11, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 9, accrualProgram.endDate) },
            { value: 12, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 10, accrualProgram.endDate) }, { value: 13, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 11, accrualProgram.endDate) }], startColumn: 2, endColumn: this.assignRateEndColumn(accrualProgram.startDate, accrualProgram.endDate)
          },
        ]
      })
    }
    else {
      this.programsAccrualsAssignRates.forEach(aar => {
        if (aar.programId === accrualProgram.id) {
          if (!(aar.autoFillAssignRates.some(element => element.materialCode === materialHierarchyCommercial.materialCode))) {
            aar.autoFillAssignRates.push({
              programId: accrualProgram.id, materialHierarchyCommercialId: materialHierarchyCommercial.id, performanceCenterCode: materialHierarchyCommercial.performanceCenterCode, performanceCenterName: materialHierarchyCommercial.performanceCenterName, productCenterCode: materialHierarchyCommercial.productCenterCode, productCenterName: materialHierarchyCommercial.productCenterName, plannedProductCode: materialHierarchyCommercial.plannedProductCode, plannedProductName: materialHierarchyCommercial.plannedProductName, tradeProductCode: materialHierarchyCommercial.tradeProductCode, tradeProductName: materialHierarchyCommercial.tradeProductName, materialCode: materialHierarchyCommercial.materialCode, materialName: materialHierarchyCommercial.materialName, isAutoAssign: false, volume: null, payRate: null, accrual: null, startDate: accrualProgram.startDate, endDate: accrualProgram.endDate, dates: [{ value: 2, viewValue: accrualProgram.startDate },
              { value: 3, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 1, accrualProgram.endDate) }, { value: 4, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 2, accrualProgram.endDate) }, { value: 5, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 3, accrualProgram.endDate) },
              { value: 6, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 4, accrualProgram.endDate) }, { value: 7, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 5, accrualProgram.endDate) }, { value: 8, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 6, accrualProgram.endDate) },
              { value: 9, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 7, accrualProgram.endDate) }, { value: 10, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 8, accrualProgram.endDate) }, { value: 11, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 9, accrualProgram.endDate) },
              { value: 12, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 10, accrualProgram.endDate) }, { value: 13, viewValue: this.assignRateNextMonth(accrualProgram.startDate, 11, accrualProgram.endDate) }], startColumn: 2, endColumn: this.assignRateEndColumn(accrualProgram.startDate, accrualProgram.endDate)
            });
          }
        }
      })
    }
  }

  assignRateNextMonth(startDate, addMonths, endDate): string {
    var result = '';
    var startMonth = startDate.substring(0, 2);
    var startYear = startDate.substring(3);
    var endMonth = endDate.substring(0, 2);
    var endYear = endDate.substring(3);

    var d1 = new Date(startMonth + '/01/' + startYear);
    var d2 = new Date(endMonth + '/01/' + endYear);
    d1 = new Date(d1.setMonth(d1.getMonth() + addMonths))

    if (d1 < d2) {
      result = (d1.getMonth() + 1).toString().padStart(2, '0') + '/' + d1.getFullYear().toString();
    }
    if (d1.getTime() === d2.getTime()) {
      result = endDate;
    }
    return result;
  }

  assignRateEndColumn(startDate, endDate): number {
    var result = 0;
    var startMonth = startDate.substring(0, 2);
    var startYear = startDate.substring(3);
    var endMonth = endDate.substring(0, 2);
    var endYear = endDate.substring(3);

    var d1 = new Date(startMonth + '/01/' + startYear);
    var d2 = new Date(endMonth + '/01/' + endYear);
    result = (d2.getMonth() - d1.getMonth() + (12 * (d2.getFullYear() - d1.getFullYear()))) + 2;
    return result;
  }

  updateAutoFillAssignRates() {
    this.programsAccrualsAssignRates.forEach(aar => {
      aar.autoFillAssignRates.forEach(afar => {
        afar.dates.forEach(d => {
          if (d.viewValue.length === 0) {
            var index = afar.dates.indexOf(d);
            var deleteCount = afar.dates.length - index;
            afar.dates.splice(index, deleteCount);
          }
        })
      })
    })
  }

  bindPrograms(isbuildMaterialHierarchyCommercials) {
    this.accrualPrograms = [];
    this.programs.forEach(p => {
      this.accrualPrograms.push(
        {
          id: p.id,
          programCode: p.code,
          name: p.programName,
          programManagerId: p.programManagerId,
          programManagerEmail: p.programManagerEmail,
          statusDefinitionId: p.statusDefinitionId,
          startDate: this.formatDate(p.startDate),
          endDate: this.formatDate(p.endDate),
          children: [],
          isAdded: false,
          numberOfColumns: this.numberOfColumns(p.startDate, p.endDate),
          isSelected: false,
          isProgramLevelDraft: null,
          isPastEndDate: p.isPastEndDate,
          isCommentRequired: p.isCommentRequired
        },
      )
    })

    if (isbuildMaterialHierarchyCommercials) {
      this.buildMaterialHierarchyCommercialsAndAccrualPrograms(this.masterSelectedHierarchyLevel,true);
    }
    this.nonUpdatedAssignRates = JSON.parse(JSON.stringify(this.assignRates));

    this.setProgramChipColors();
    this.checkSaveAndSubmit();
    this.dataSource.data = this.accrualPrograms;
    this.dataSourceAssignRates.data = this.assignRates;
    this.isFormDirty = false;
    this.setIsFormDirty();
    this.spinner.hide();
  }

  numberOfColumns(startDate, endDate): number {
    var result = 0;
    var d1 = new Date(startDate);
    var d2 = new Date(endDate);
    result = (d2.getMonth() - d1.getMonth() + (12 * (d2.getFullYear() - d1.getFullYear()))) + 2;
    return result;
  }
  formatDate(date): string {
    var result = '';
    var newDate = new Date(date);
    var dd = String(newDate.getDate()).padStart(2, '0');
    var mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = newDate.getFullYear().toString();
    result = mm + '/' + yyyy;
    return result;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource._flattenedData.value.filter(row => row.level === 0 && !row.isPastEndDate).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource._flattenedData.value.filter(row => row.level === 0 && !row.isPastEndDate).forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: ProgramFlatNode): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  restrictChar(event: KeyboardEvent) {
    return String.fromCharCode(event.charCode).match(/[^0-9.]/g) === null
  }
  checkAssignRates(data, element, assignRatesItems): boolean {
    let result = false;
    if (data.id === element.programId && data.materialCode === element.materialCode && !element.header && !assignRatesItems) {
      result = true;
    }
    return result;
  }

  checkAssignRatesTopRow(data, element, assignRatesItems): boolean {
    let result = false;
    if (data.id === element.programId && data.materialCode === element.materialCode && (element.header || assignRatesItems)) {
      result = true;
    }
    return result;
  }
  hiddenRows(data, row): boolean {
    let result = false;
    if (data.id === row.programId && data.materialCode === row.materialCode) {
      result = true;
    }
    return result;
  }

  insertFormatProgramAccrualRatesByMonth() {
    this.assignRates.forEach(ar => {
      if (!(this.programAccrualRatesByMonth.some(element => element.programId === ar.programId && element.materialHierarchyCommercialId === ar.materialHierarchyCommercialId))) {
        if (ar.header) {
          for (var column = 2; column < 14; column++) {
            switch (column) {
              case 2:
                if (ar._1stMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._1stMonthAssignRate.substr(0, 2)), year: Number(ar._1stMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 1 })
                }
                break;
              case 3:
                if (ar._2ndMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._2ndMonthAssignRate.substr(0, 2)), year: Number(ar._2ndMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 2 })
                }
                break;
              case 4:
                if (ar._3rdMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._3rdMonthAssignRate.substr(0, 2)), year: Number(ar._3rdMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 3 })
                }
                break;
              case 5:
                if (ar._4thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._4thMonthAssignRate.substr(0, 2)), year: Number(ar._4thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 4 })
                }
                break;
              case 6:
                if (ar._5thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._5thMonthAssignRate.substr(0, 2)), year: Number(ar._5thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 5 })
                }
                break;
              case 7:
                if (ar._6thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._6thMonthAssignRate.substr(0, 2)), year: Number(ar._6thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 6 })
                }
                break;
              case 8:
                if (ar._7thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._7thMonthAssignRate.substr(0, 2)), year: Number(ar._7thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 7 })
                }
                break;
              case 9:
                if (ar._8thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._8thMonthAssignRate.substr(0, 2)), year: Number(ar._8thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 8 })
                }
                break;
              case 10:
                if (ar._9thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._9thMonthAssignRate.substr(0, 2)), year: Number(ar._9thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 9 })
                }
                break;
              case 11:
                if (ar._10thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._10thMonthAssignRate.substr(0, 2)), year: Number(ar._10thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 10 })
                }
                break;
              case 12:
                if (ar._11thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._11thMonthAssignRate.substr(0, 2)), year: Number(ar._11thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 11 })
                }
                break;
              case 13:
                if (ar._12thMonthAssignRate.length > 0) {
                  this.insertProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._12thMonthAssignRate.substr(0, 2)), year: Number(ar._12thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 12 })
                }
                break;
            }
          }
        }
        else {
          for (var column = 2; column < 14; column++) {
            switch (column) {
              case 2:
                if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, ar._1stMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 3:
                if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, ar._2ndMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 4:
                if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, ar._3rdMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 5:
                if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, ar._4thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 6:
                if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, ar._5thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 7:
                if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, ar._6thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 8:
                if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, ar._7thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 9:
                if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, ar._8thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 10:
                if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, ar._9thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 11:
                if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, ar._10thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 12:
                if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, ar._11thMonthAssignRate, ar.assignRateType, false);
                }
                break;
              case 13:
                if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
                  this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, ar._12thMonthAssignRate, ar.assignRateType, false);
                }
                break;
            }
          }
        }
      }
      else {
        if (ar.header) {
          for (var column = 2; column < 14; column++) {
            switch (column) {
              case 2:
                if (ar._1stMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._1stMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._1stMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 1)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._1stMonthAssignRate.substr(0, 2)), year: Number(ar._1stMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 1 })
                  }
                }
                break;
              case 3:
                if (ar._2ndMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._2ndMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._2ndMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 2)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._2ndMonthAssignRate.substr(0, 2)), year: Number(ar._2ndMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 2 })
                  }
                }
                break;
              case 4:
                if (ar._3rdMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._3rdMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._3rdMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 3)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._3rdMonthAssignRate.substr(0, 2)), year: Number(ar._3rdMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 3 })
                  }
                }
                break;
              case 5:
                if (ar._4thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._4thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._4thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 4)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._4thMonthAssignRate.substr(0, 2)), year: Number(ar._4thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 4 })
                  }
                }
                break;
              case 6:
                if (ar._5thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._5thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._5thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 5)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._5thMonthAssignRate.substr(0, 2)), year: Number(ar._5thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 5 })
                  }
                }
                break;
              case 7:
                if (ar._6thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._6thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._6thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 6)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._6thMonthAssignRate.substr(0, 2)), year: Number(ar._6thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 6 })
                  }
                }
                break;
              case 8:
                if (ar._7thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._7thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._7thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 7)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._7thMonthAssignRate.substr(0, 2)), year: Number(ar._7thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 7 })
                  }
                }
                break;
              case 9:
                if (ar._8thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._8thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._8thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 8)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._8thMonthAssignRate.substr(0, 2)), year: Number(ar._8thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 8 })
                  }
                }
                break;
              case 10:
                if (ar._9thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._9thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._9thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 9)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._9thMonthAssignRate.substr(0, 2)), year: Number(ar._9thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 9 })
                  }
                }
                break;
              case 11:
                if (ar._10thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._10thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._10thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 10)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._10thMonthAssignRate.substr(0, 2)), year: Number(ar._10thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 10 })
                  }
                }
                break;
              case 12:
                if (ar._11thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._11thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._11thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 11)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._11thMonthAssignRate.substr(0, 2)), year: Number(ar._11thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 11 })
                  }
                }
                break;
              case 13:
                if (ar._12thMonthAssignRate.length > 0) {
                  if (!this.existingProgramAccrualRatesByMonth.some(eparbm => eparbm.programId == ar.programId && eparbm.materialHierarchyCommercialId == ar.materialHierarchyCommercialId && eparbm.month == Number(ar._12thMonthAssignRate.substr(0, 2)) && eparbm.year == Number(ar._12thMonthAssignRate.substr(3, 4)) && eparbm.volumePercentage == null && eparbm.payRatePercentage == null && eparbm.accrualPercentage == null && eparbm.statusDefinitionId == null && eparbm.monthAndYearColumn == 12)) {
                    this.existingProgramAccrualRatesByMonth.push({ programId: ar.programId, materialHierarchyCommercialId: ar.materialHierarchyCommercialId, month: Number(ar._12thMonthAssignRate.substr(0, 2)), year: Number(ar._12thMonthAssignRate.substr(3, 4)), volumePercentage: null, payRatePercentage: null, accrualPercentage: null, statusDefinitionId: null, monthAndYearColumn: 12 })
                  }
                }
                break;
            }
          }
        }
        else {
          for (var column = 2; column < 14; column++) {
            switch (column) {
              case 2:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, ar._1stMonthAssignRate, ar.assignRateType, true);
                break;
              case 3:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, ar._2ndMonthAssignRate, ar.assignRateType, true);
                break;
              case 4:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, ar._3rdMonthAssignRate, ar.assignRateType, true);
                break;
              case 5:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, ar._4thMonthAssignRate, ar.assignRateType, true);
                break;
              case 6:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, ar._5thMonthAssignRate, ar.assignRateType, true);
                break;
              case 7:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, ar._6thMonthAssignRate, ar.assignRateType, true);
                break;
              case 8:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, ar._7thMonthAssignRate, ar.assignRateType, true);
                break;
              case 9:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, ar._8thMonthAssignRate, ar.assignRateType, true);
                break;
              case 10:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, ar._9thMonthAssignRate, ar.assignRateType, true);
                break;
              case 11:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, ar._10thMonthAssignRate, ar.assignRateType, true);
                break;
              case 12:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, ar._11thMonthAssignRate, ar.assignRateType, true);
                break;
              case 13:
                this.updateInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, ar._12thMonthAssignRate, ar.assignRateType, true);
                break;
            }
          }
        }
      }
    })
  }

  updateFormatProgramAccrualRatesByMonth() {
    this.updateProgramAccrualRatesByMonth = [];
    this.programAccrualRatesByMonth.forEach(parbm => {
      this.existingProgramAccrualRatesByMonth.forEach(eparbm => {
        if (eparbm.programId === parbm.programId && eparbm.materialHierarchyCommercialId === parbm.materialHierarchyCommercialId && eparbm.month === parbm.month && eparbm.year === parbm.year) {
          if (parbm.volumePercentage !== eparbm.volumePercentage || parbm.payRatePercentage !== eparbm.payRatePercentage) {
            eparbm.id = parbm.id;
            this.updateProgramAccrualRatesByMonth.push(eparbm);
          }
        }
      })
    })
  }

  deleteFormatProgramAccrualRatesByMonth() {
    this.deleteProgramAccrualRatesByMonth = [];
    this.programAccrualRatesByMonth.forEach(parbm => {
      if (!(this.assignRates.some(element => element.programId === parbm.programId && element.materialHierarchyCommercialId === parbm.materialHierarchyCommercialId))) {
        this.deleteProgramAccrualRatesByMonth.push(parbm);
      }
    })
  }

  updateInsertProgramAccrualRatesByMonthPercentages(programId, materialHierarchyCommercialId, monthAndYearColumn, monthAssignRatePercentage, assignRateType, isExistingProgramAccrualRatesByMonth) {
    if (isExistingProgramAccrualRatesByMonth) {
      this.existingProgramAccrualRatesByMonth.forEach(element => {
        if (element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.monthAndYearColumn === monthAndYearColumn) {
          switch (assignRateType) {
            case 'VOLUME %':
              if (monthAssignRatePercentage !== null) {
                element.volumePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
            case 'PAY RATE %':
              if (monthAssignRatePercentage !== null) {
                element.payRatePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
            case 'ACCRUAL %':
              if (monthAssignRatePercentage !== null) {
                element.accrualPercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
          }
        }
      })
    }
    else {
      this.insertProgramAccrualRatesByMonth.forEach(element => {
        if (element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.monthAndYearColumn === monthAndYearColumn) {
          switch (assignRateType) {
            case 'VOLUME %':
              if (monthAssignRatePercentage !== null) {
                element.volumePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
            case 'PAY RATE %':
              if (monthAssignRatePercentage !== null) {
                element.payRatePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
            case 'ACCRUAL %':
              if (monthAssignRatePercentage !== null) {
                element.accrualPercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
              }
              break;
          }
        }
      })
    }
  }
  updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(programId, materialHierarchyCommercialId, monthAndYearColumn, monthAssignRatePercentage, assignRateType, isExistingProgramAccrualRatesByMonth) {
    this.addedNewProgramAccrualRatesByMonth.forEach(element => {
      if (element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.monthAndYearColumn === monthAndYearColumn) {
        switch (assignRateType) {
          case 'VOLUME %':
            if (monthAssignRatePercentage !== null) {
              element.volumePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
            }
            else {
              element.volumePercentage = null;
            }
            break;
          case 'PAY RATE %':
            if (monthAssignRatePercentage !== null) {
              element.payRatePercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
            }
            else {
              element.payRatePercentage = null;
            }
            break;
          case 'ACCRUAL %':
            if (monthAssignRatePercentage !== null) {
              element.accrualPercentage = monthAssignRatePercentage.toString().length > 0 ? parseFloat(monthAssignRatePercentage.toString()) : null;
            }
            else {
              element.accrualPercentage = null;
            }
            break;
        }
      }
    })
  }

  setIsFormDirty() {
    let exitForEach = false;
    this.addedNewMonths = false;
    let uniqueProgramAccrualRatesByMonth = this.programAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);
    if (!exitForEach) {
      uniqueProgramAccrualRatesByMonth.forEach(upm => {
        let materialRatesCount = this.programAccrualRatesByMonth.filter(row => row.programId === upm.programId && row.materialHierarchyCommercialId === upm.materialHierarchyCommercialId).length;
        let numberOfRateColumns = this.getNumberOfAssignRatesMonth(upm.programId, upm.materialHierarchyCommercialId);
        if (materialRatesCount !== numberOfRateColumns) {
          this.addedNewMonths = true;
          exitForEach = true;
        }
      })
    }
    this.addedNewMonths ? this.isFormDirty = true : this.isFormDirty = false;
    if (!this.addedNewMonths) {
      let nonUpdatedAssignRatesNonMatchResult: ProgramAccrualsMaterialAssignRates[] = [];
      let assignRatesNonMatchResult: ProgramAccrualsMaterialAssignRates[] = [];
      nonUpdatedAssignRatesNonMatchResult = this.nonUpdatedAssignRates.filter(({ programId: programId1, materialCode: materialCode1 }) => !this.assignRates.some(({ programId: programId2, materialCode: materialCode2 }) => programId2 === programId1 && materialCode2 === materialCode1));
      assignRatesNonMatchResult = this.assignRates.filter(({ programId: programId1, materialCode: materialCode1 }) => !this.nonUpdatedAssignRates.some(({ programId: programId2, materialCode: materialCode2 }) => programId2 === programId1 && materialCode2 === materialCode1));
      if (nonUpdatedAssignRatesNonMatchResult.length > 0 || assignRatesNonMatchResult.length > 0) {
        this.isFormDirty = true;
      }
      else if (nonUpdatedAssignRatesNonMatchResult.length === 0 && assignRatesNonMatchResult.length === 0) {
        this.isFormDirty = false;
        this.nonUpdatedAssignRates.forEach(nuar => {
          if (!exitForEach) {
            this.assignRates.forEach(ar => {
              if (!exitForEach) {
                if (nuar.programId === ar.programId && nuar.materialCode === ar.materialCode && nuar.assignRateType.length > 0 && ar.assignRateType.length > 0 && nuar.assignRateType === ar.assignRateType) {
                  if (!(nuar._1stMonthAssignRate === ar._1stMonthAssignRate.toString()
                    && nuar._2ndMonthAssignRate === ar._2ndMonthAssignRate.toString()
                    && nuar._3rdMonthAssignRate === ar._3rdMonthAssignRate.toString()
                    && nuar._4thMonthAssignRate === ar._4thMonthAssignRate.toString()
                    && nuar._5thMonthAssignRate === ar._5thMonthAssignRate.toString()
                    && nuar._6thMonthAssignRate === ar._6thMonthAssignRate.toString()
                    && nuar._7thMonthAssignRate === ar._7thMonthAssignRate.toString()
                    && nuar._8thMonthAssignRate === ar._8thMonthAssignRate.toString()
                    && nuar._9thMonthAssignRate === ar._9thMonthAssignRate.toString()
                    && nuar._10thMonthAssignRate === ar._10thMonthAssignRate.toString()
                    && nuar._11thMonthAssignRate === ar._11thMonthAssignRate.toString()
                    && nuar._12thMonthAssignRate === ar._12thMonthAssignRate.toString())) {
                    this.isFormDirty = true;
                    exitForEach = true;
                  }
                  else {
                    this.isFormDirty = false;
                  }
                }
              }
            })
          }
        })
      }
    }  
  }

  updateProgramStatus() {
    let programPendingStatus: ProgramStatus[] = [];
    let programCompletedStatus: ProgramStatus[] = [];
    this.uniqueProgramAndStatusDefinition = [];
    this.updateProgramsStatus = [];
    this.programStatus = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.postdatatoservice("/api/Accrual/Details/GetProgramAccrualsStatus", JSON.stringify(this.ids), headers)
      .toPromise().then((response) => {
        if (response != null) {
          this.programStatus = response;
          if (this.programStatus.length > 0) {
            this.uniqueProgramAndStatusDefinition = this.programStatus.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.changeExceptionProgramId === v.changeExceptionProgramId && t.changeException === v.changeException && t.statusDefinitionId === v.statusDefinitionId)) === i);
            programPendingStatus = this.uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING);
            programCompletedStatus = this.uniqueProgramAndStatusDefinition.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_COMPLETED);

            programPendingStatus.forEach(parbmps => {
              this.updateProgramsStatus.push({ programId: parbmps.programId, changeExceptionProgramId: parbmps.changeExceptionProgramId, changeException: parbmps.changeException, statusDefinitionId: parbmps.statusDefinitionId },);
            });

            programCompletedStatus.forEach(parbmcs => {
              if (!this.updateProgramsStatus.some(ps => ps.programId === parbmcs.programId)) {
                this.updateProgramsStatus.push({ programId: parbmcs.programId, changeExceptionProgramId: parbmcs.changeExceptionProgramId, changeException: parbmcs.changeException, statusDefinitionId: parbmcs.statusDefinitionId },);
              }
            });
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/StatusUpdate", JSON.stringify(this.updateProgramsStatus), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.programStatus = [];
                }
              });
          }
        }
      });
  }

  updateProgramTotalSalesAndTotalExposure() {
    this.programTotalSalesAndTotalExposure = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');

    this.context.postdatatoservice("/api/Accrual/Details/GetProgramAccrualRatesTotalSalesAndTotalExposure", JSON.stringify(this.ids), headers)
      .toPromise().then((response) => {
        if (response != null) {
          this.programTotalSalesAndTotalExposure = response;
          this.ids.forEach(id => {
            if (this.programTotalSalesAndTotalExposure.length > 0) {
              if (!this.programTotalSalesAndTotalExposure.some(ptste => ptste.programId == id)) {
                this.programTotalSalesAndTotalExposure.push({ programId: id, totalSales: 0, totalExposure: 0 })
              }
            }
          })
          if (this.programTotalSalesAndTotalExposure.length > 0) {
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/TotalSalesAndTotalExposureUpdate", JSON.stringify(this.programTotalSalesAndTotalExposure), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.programStatus = [];
                }
              });
          }
        }
      });
  }

  sendEmailNotificationToProgramManager(isOnlyRemove) {
    this.createProgramsEmailList(isOnlyRemove);
    const headers = new HttpHeaders().set('content-type', 'application/json');

    if (this.programsEmails.length > 0) {
      this.context.postdatatoservice("/api/Email/Table/ProgramManagerEmail", JSON.stringify(this.programsEmails), headers)
        .toPromise().then((response) => {
          if (response != null) {
            this.programsEmails = [];
          }
        });
    }
  }

  updateProgramToProductManagerAssoc() {
    let programToProductManagerAssoc: ProgramToProductManagerAssoc[] = [];
    let uniqueProgramAndProductManager: ProgramToProductManagerAssoc[] = [];
    const headers = new HttpHeaders().set('content-type', 'application/json');
    this.openEnrolledProgramAccrual = this.programAccrual;
    this.openEnrolledProgramAccrual.productManager = this.programAccrual.productManager;

    if (this.isFeatureFlagEnabledAssignProductManagerToProgramForAdmin) {
      this.context.postdatatoservice("/api/Accrual/Details/GetOpenEnrolledProgramToProductManagerAssocV2", JSON.stringify(this.openEnrolledProgramAccrual), headers)
      .toPromise().then((response) => {
        if (response != null) {
          programToProductManagerAssoc = response;
          if (programToProductManagerAssoc.length > 0) {
            uniqueProgramAndProductManager = programToProductManagerAssoc.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.productManagerId === v.productManagerId)) === i);
            const headers = new HttpHeaders().set('content-type', 'application/json');
            this.context.postdatatoservice("/api/Program/Table/ProgramToProductManagerAssocUpdate", JSON.stringify(uniqueProgramAndProductManager), headers)
              .toPromise().then((response) => {
                if (response != null) {
                  this.updateProgramStatus();
                }
              });
          }
          else {
            this.updateProgramStatus();
          }
        }
      });
    }
    else {
      this.context.postdatatoservice("/api/Accrual/Details/GetOpenEnrolledProgramToProductManagerAssoc", JSON.stringify(this.ids), headers)
        .toPromise().then((response) => {
          if (response != null) {
            programToProductManagerAssoc = response;
            if (programToProductManagerAssoc.length > 0) {
              uniqueProgramAndProductManager = programToProductManagerAssoc.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.productManagerId === v.productManagerId)) === i);
              const headers = new HttpHeaders().set('content-type', 'application/json');
              this.context.postdatatoservice("/api/Program/Table/ProgramToProductManagerAssocUpdate", JSON.stringify(uniqueProgramAndProductManager), headers)
                .toPromise().then((response) => {
                  if (response != null) {
                    this.updateProgramStatus();
                  }
                });
            }
            else {
              this.updateProgramStatus();
            }
          }
        });
      }
  }

  insertProductManagerComments(isOnlyRemoveProducts) {
    this.programAccrualRatesComments = [];
  if (!isOnlyRemoveProducts){
    this.insertProgramAccrualRatesByMonth.forEach(i => {
      this.programAccrualRatesComments.push({
        programId: i.programId,
        materialHierarchyCommercialId: i.materialHierarchyCommercialId,
        year: i.year,
        month: i.month,
        message: this.submittedOrRemovedAccrualPrograms.filter(sap => sap.programId === i.programId)[0]?.comment
      });
    });
    this.updateProgramAccrualRatesByMonth.forEach(i => {
      this.programAccrualRatesComments.push({
        programId: i.programId,
        materialHierarchyCommercialId: i.materialHierarchyCommercialId,
        year: i.year,
        month: i.month,
        message: this.submittedOrRemovedAccrualPrograms.filter(sap => sap.programId === i.programId)[0]?.comment
      });
    });
  }
  else{
    this.deleteProgramAccrualRatesByMonth.forEach(i => {
      this.programAccrualRatesComments.push({
        programId: i.programId,
        materialHierarchyCommercialId: i.materialHierarchyCommercialId,
        year: i.year,
        month: i.month,
        message: this.submittedOrRemovedAccrualPrograms.filter(sap => sap.programId === i.programId)[0]?.comment
      });
    });
  } 
   
    var programAccrualMessages = this.programAccrualRatesComments.filter(pacr => pacr.message);
    if (programAccrualMessages.length > 0) {
      const headers = new HttpHeaders().set('content-type', 'application/json');
      this.context.postdatatoservice("/api/Accrual/Table/InsertProrgamAccrualMessages", JSON.stringify(programAccrualMessages), headers)
        .toPromise().then((response) => {
          if (response != null) { }
        });
    }
  } 

  checkSaveAndSubmit() {
    this.draftStatusDefinitionIdCount = 0;
    if (this.programAccrualRatesByMonth.length > 0) {
      this.draftStatusDefinitionIdCount = this.programAccrualRatesByMonth.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING).length;
    }
    return this.isFormDirty || this.draftStatusDefinitionIdCount > 0 ? true : false;
  }
  setProgramChipColors() {
    this.accrualPrograms.forEach(ap => {
      ap.isProgramLevelDraft = false;
      ap.isProductLevelDraft = false;
      ap.isNewMonthAdded = false;
      if (!this.programAccrualRatesByMonth.some(element => element.programId === ap.id)) {
        ap.isProgramLevelDraft = true;
      }

    })
    let draftProgramAccrualRatesByMonth = this.programAccrualRatesByMonth.filter(row => row.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING);
    let programIds = [...new Set(draftProgramAccrualRatesByMonth.map(item => item.programId))];
    let uniqueDraftProgramandMaterialHierarchyCommercial = draftProgramAccrualRatesByMonth.filter((v, i, a) => a.findIndex(t => (t.programId === v.programId && t.materialHierarchyCommercialId === v.materialHierarchyCommercialId)) === i);
    uniqueDraftProgramandMaterialHierarchyCommercial.forEach(element => {
      this.searchAndUpdateMaterialHierarchyStatusFromAccrualsPrograms(element.materialHierarchyCommercialId, element.programId);
    })

    let newAddedMonthsprogramIds = [...new Set(this.newProgramAccrualRatesMonth.map(item => item.programId))];
    newAddedMonthsprogramIds.forEach(programId => {
      this.accrualPrograms.forEach(ap => {
        if (ap.id === programId) {
          ap.isNewMonthAdded = true;
        }
        else {
          ap.isNewMonthAdded = false;
        }
      })
    });

    programIds.forEach(programId => {
      this.accrualPrograms.forEach(ap => {
        if (ap.id === programId) {
          ap.isProgramLevelDraft = true;
        }
      })
    });

  }

  searchAndUpdateMaterialHierarchyStatusFromAccrualsPrograms(materialHierarchyCommercialId, programId) {
    switch (this.masterSelectedHierarchyLevel) {
      case 0:
        this.accrualPrograms.forEach(aprg => {
          if (aprg.id === programId) {
            if (aprg.children.length > 0) {
              aprg.children.forEach(aprgChild => {
                if (aprgChild.children.length > 0) {
                  aprgChild.children.forEach(aprgChildChild => {
                    if (aprgChildChild.children.length > 0) {
                      aprgChildChild.children.forEach(aprgChildChildChild => {
                        if (aprgChildChildChild.children.length > 0) {
                          aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                            if (aprgChildChildChildChild.children.length > 0) {
                              aprgChildChildChildChild.children.forEach(aprgChildChildChildChildChild => {
                                if (aprgChildChildChildChildChild.materialHierarchyCommercialId === materialHierarchyCommercialId && aprgChildChildChildChildChild.id === programId) {
                                  aprgChildChildChildChildChild.isProductLevelDraft = true;
                                }
                              })
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          }
        })
        break;
      case 1:
        this.accrualPrograms.forEach(aprg => {
          if (aprg.id === programId) {
            if (aprg.children.length > 0) {
              aprg.children.forEach(aprgChild => {
                if (aprgChild.children.length > 0) {
                  aprgChild.children.forEach(aprgChildChild => {
                    if (aprgChildChild.children.length > 0) {
                      aprgChildChild.children.forEach(aprgChildChildChild => {
                        if (aprgChildChildChild.children.length > 0) {
                          aprgChildChildChild.children.forEach(aprgChildChildChildChild => {
                            if (aprgChildChildChildChild.materialHierarchyCommercialId === materialHierarchyCommercialId && aprgChildChildChildChild.id === programId) {
                              aprgChildChildChildChild.isProductLevelDraft = true;
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })
            }
          }
        })
        break;
      case 2:
        this.accrualPrograms.forEach(aprg => {
          if (aprg.id === programId) {
            if (aprg.children.length > 0) {
              aprg.children.forEach(aprgChild => {
                if (aprgChild.children.length > 0) {
                  aprgChild.children.forEach(aprgChildChild => {
                    if (aprgChildChild.children.length > 0) {
                      aprgChildChild.children.forEach(aprgChildChildChild => {
                        if (aprgChildChildChild.materialHierarchyCommercialId === materialHierarchyCommercialId && aprgChildChildChild.id === programId) {
                          aprgChildChildChild.isProductLevelDraft = true;
                        }
                      })
                    }
                  })
                }
              })
            }
          }
        })
        break;
      case 3:
        this.accrualPrograms.forEach(aprg => {
          if (aprg.id === programId) {
            if (aprg.children.length > 0) {
              aprg.children.forEach(aprgChild => {
                if (aprgChild.children.length > 0) {
                  aprgChild.children.forEach(aprgChildChild => {
                    if (aprgChildChild.materialHierarchyCommercialId === materialHierarchyCommercialId && aprgChildChild.id === programId) {
                      aprgChildChild.isProductLevelDraft = true;
                    }
                  })
                }
              })
            }
          }
        })
        break;
      case 4:
        this.accrualPrograms.forEach(aprg => {
          if (aprg.id === programId) {
            if (aprg.children.length > 0) {
              aprg.children.forEach(aprgChild => {
                if (aprgChild.materialHierarchyCommercialId === materialHierarchyCommercialId && aprgChild.id === programId) {
                  aprgChild.isProductLevelDraft = true;
                }
              })
            }
          }
        })
        break;
    }
  }

  checkAddingNewAccrualsExistsInPreviousDeletedAccruals() {
    this.insertProgramAccrualRatesByMonth.forEach(iparbm => {
      this.existingDeletedProgramAccrualRatesByMonth.forEach(edparbm => {
        if (edparbm.programId === iparbm.programId && edparbm.materialHierarchyCommercialId === iparbm.materialHierarchyCommercialId
          && edparbm.month === iparbm.month && edparbm.year === iparbm.year) {
          this.updateProgramAccrualRatesByMonth.push({ id: edparbm.id, programId: edparbm.programId, materialHierarchyCommercialId: edparbm.materialHierarchyCommercialId, month: edparbm.month, year: edparbm.year, volumePercentage: iparbm.volumePercentage, payRatePercentage: iparbm.payRatePercentage, accrualPercentage: iparbm.accrualPercentage, statusDefinitionId: iparbm.statusDefinitionId });
        }
      });
    });
  }

  removeExistingDeletedAccrualsFromInsertNewAccruals() {
    this.existingDeletedProgramAccrualRatesByMonth.forEach(edparbm => {
      this.insertProgramAccrualRatesByMonth.forEach(iparbm => {
        if (edparbm.programId === iparbm.programId && edparbm.materialHierarchyCommercialId === iparbm.materialHierarchyCommercialId
          && edparbm.month === iparbm.month && edparbm.year === iparbm.year) {
          let index = this.insertProgramAccrualRatesByMonth.indexOf(iparbm);
          this.insertProgramAccrualRatesByMonth.splice(index, 1);
        }
      })
    });
  }

  findInProgramAccrualRatesByMonth(data) {
    if (this.programAccrualRatesByMonth.some(parbm => parbm.programId === data.id)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkIsDraftStatus(data, isproductLevel) {
    if (this.programAccrualRatesByMonth.some(parbm => parbm.programId === data.id)) {
      if (isproductLevel) {
        if (data.isProductLevelDraft && data.isProductLevelDraft !== null) {
          return true;
        }
      }
      else {
        if (data.isProgramLevelDraft !== null && data.isProgramLevelDraft) {
          return true;
        }
      }
    }
  }

  checkIsExistingProduct(programId, materialHierarchyCommercialId) {
    if (this.programAccrualRatesByMonth.length > 0) {
      if (this.programAccrualRatesByMonth.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  checkIsProductLevel(materialHierarchyCommercialId) {
    if (materialHierarchyCommercialId !== undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  checkForWarningOrError(programId, materialHierarchyCommercialId, isErrorCheck): boolean {
    this.insertProgramAccrualRatesByMonth = [];
    this.existingProgramAccrualRatesByMonth = [];
    this.updateProgramAccrualRatesByMonth = [];
    this.newProgramAccrualRatesMonth = [];
    this.insertFormatProgramAccrualRatesByMonth();
    this.updateFormatProgramAccrualRatesByMonth();
    this.addedNewProgramAccrualRatesByMonth.forEach(anparbm => {
      this.assignRates.forEach(ar => {
        if (!ar.header) {
          if (anparbm.programId === ar.programId && anparbm.materialHierarchyCommercialId === ar.materialHierarchyCommercialId) {
            switch (anparbm.monthAndYearColumn) {
              case 1:
                if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, ar._1stMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 1, null, ar.assignRateType, false);
                }
                break;
              case 2:
                if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, ar._2ndMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 2, null, ar.assignRateType, false);
                }
                break;
              case 3:
                if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, ar._3rdMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 3, null, ar.assignRateType, false);
                }
                break;
              case 4:
                if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, ar._4thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 4, null, ar.assignRateType, false);
                }
                break;
              case 5:
                if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, ar._5thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 5, null, ar.assignRateType, false);
                }
                break;
              case 6:
                if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, ar._6thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 6, null, ar.assignRateType, false);
                }
                break;
              case 7:
                if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, ar._7thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 7, null, ar.assignRateType, false);
                }
                break;
              case 8:
                if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, ar._8thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 8, null, ar.assignRateType, false);
                }
                break;
              case 9:
                if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, ar._9thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 9, null, ar.assignRateType, false);
                }
                break;
              case 10:
                if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, ar._10thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 10, null, ar.assignRateType, false);
                }
                break;
              case 11:
                if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, ar._11thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 11, null, ar.assignRateType, false);
                }
                break;
              case 12:
                if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, ar._12thMonthAssignRate, ar.assignRateType, false);
                }
                else {
                  this.updateNewlyAddedInsertProgramAccrualRatesByMonthPercentages(ar.programId, ar.materialHierarchyCommercialId, 12, null, ar.assignRateType, false);
                }
                break;
            }
          }
        }
        else {
          if (anparbm.programId === ar.programId && anparbm.materialHierarchyCommercialId === ar.materialHierarchyCommercialId) {
            switch (anparbm.monthAndYearColumn) {
              case 1:
                if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._1stMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._1stMonthAssignRate })
                  }
                }
                break;
              case 2:
                if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._2ndMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._2ndMonthAssignRate })
                  }
                }
                break;
              case 3:
                if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._3rdMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._3rdMonthAssignRate })
                  }
                }
                break;
              case 4:
                if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._4thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._4thMonthAssignRate })
                  }
                }
                break;
              case 5:
                if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._5thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._5thMonthAssignRate })
                  }
                }
                break;
              case 6:
                if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._6thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._6thMonthAssignRate })
                  }
                }
                break;
              case 7:
                if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._7thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._7thMonthAssignRate })
                  }
                }
                break;
              case 8:
                if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._8thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._8thMonthAssignRate })
                  }
                }
                break;
              case 9:
                if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._9thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._9thMonthAssignRate })
                  }
                }
                break;
              case 10:
                if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._10thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._10thMonthAssignRate })
                  }
                }
                break;
              case 11:
                if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._11thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._11thMonthAssignRate })
                  }
                }
                break;
              case 12:
                if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
                  if (!this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === anparbm.programId && nparm.materialHierarchyCommercialId === anparbm.materialHierarchyCommercialId && nparm.assignMonth === ar._12thMonthAssignRate)) {
                    this.newProgramAccrualRatesMonth.push({ programId: anparbm.programId, materialHierarchyCommercialId: anparbm.materialHierarchyCommercialId, assignMonth: ar._12thMonthAssignRate })
                  }
                }
                break;
            }
          }
        }
      })
    })
    this.addedNewProgramAccrualRatesByMonth.forEach(anparbm => {
      this.insertProgramAccrualRatesByMonth.push(anparbm);
    })
    return this.verifyForChipErrorAndWarningMessage(programId, materialHierarchyCommercialId, isErrorCheck)

  }

  checkIsNewMonth(programId, materialHierarchyCommercialId, assignMonth): boolean {
    if (this.newProgramAccrualRatesMonth.some(nparm => nparm.programId === programId && nparm.materialHierarchyCommercialId === materialHierarchyCommercialId && nparm.assignMonth === assignMonth)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkIsDraftMonth(programId, materialHierarchyCommercialId, assignMonth): boolean {
    if (this.programAccrualRatesByMonth.some(parbm => parbm.programId === programId && parbm.materialHierarchyCommercialId === materialHierarchyCommercialId && parbm.month === parseFloat(String(assignMonth).substr(0, 2)) && parbm.statusDefinitionId === this.statusDefinitionIds.ACCRUALS_PENDING)) {
      return true;
    }
    else {
      return false;
    }
  }

  getNumberOfAssignRatesMonth(programId, materialHierarchyCommercialId): number {
    let numberOfRateColumnsCounter = 0;
    let assignRatesHeader = this.assignRates.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.header);
    assignRatesHeader.forEach(ar => {
      for (var column = 2; column < 14; column++) {
        switch (column) {
          case 2:
            if (ar._1stMonthAssignRate !== null && ar._1stMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 3:
            if (ar._2ndMonthAssignRate !== null && ar._2ndMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 4:
            if (ar._3rdMonthAssignRate !== null && ar._3rdMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 5:
            if (ar._4thMonthAssignRate !== null && ar._4thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 6:
            if (ar._5thMonthAssignRate !== null && ar._5thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 7:
            if (ar._6thMonthAssignRate !== null && ar._6thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 8:
            if (ar._7thMonthAssignRate !== null && ar._7thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 9:
            if (ar._8thMonthAssignRate !== null && ar._8thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 10:
            if (ar._9thMonthAssignRate !== null && ar._9thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 11:
            if (ar._10thMonthAssignRate !== null && ar._10thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 12:
            if (ar._11thMonthAssignRate !== null && ar._11thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
          case 13:
            if (ar._12thMonthAssignRate !== null && ar._12thMonthAssignRate !== '') {
              numberOfRateColumnsCounter++;
            }
            break;
        }
      }
    })
    return numberOfRateColumnsCounter;
  }

  verifyForChipErrorAndWarningMessage(programId, materialHierarchyCommercialId, isErrorCheck): boolean {
    this.chipErrorAndWarningMessages = [];
    this.toolTipMessages = [];
    let numberOfRateColumns = this.getNumberOfAssignRatesMonth(programId, materialHierarchyCommercialId);

    if (materialHierarchyCommercialId !== undefined) {
      if (this.insertProgramAccrualRatesByMonth.length > 0) {
        const addProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
        this.insertProgramAccrualRatesByMonth.forEach(iparbm => {
          addProgramAccrualRatesByMonth.push({ id: null, programId: iparbm.programId, materialHierarchyCommercialId: iparbm.materialHierarchyCommercialId, month: iparbm.month, year: iparbm.year, volumePercentage: iparbm.volumePercentage, payRatePercentage: iparbm.payRatePercentage, accrualPercentage: iparbm.accrualPercentage, statusDefinitionId: 0 })
        })
        let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let existingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let emptyExistingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let existingAllZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let updatedProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];

        updatedProgramAccrualRatesByMonths = this.updateProgramAccrualRatesByMonth.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId);
        if (updatedProgramAccrualRatesByMonths.length > 0) {
          existingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId);
          emptyExistingProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
          existingAllZeroProgramAccrualRatesByMonths = updatedProgramAccrualRatesByMonths.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        }
        else {
          existingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId);
          emptyExistingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
          existingAllZeroProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        }

        programAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId);
        emptyProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
        allZeroProgramAccrualRatesByMonths = addProgramAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        let newMaterialRatesCount = programAccrualRatesByMonths.length;
        let existingMaterialRatesCount = existingProgramAccrualRatesByMonths.length;
        if (numberOfRateColumns === newMaterialRatesCount && existingMaterialRatesCount === 0) {
          if (programAccrualRatesByMonths.length > 0) {
            if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length) {
              if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 1)) {
                this.chipErrorAndWarningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
                if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._1 && element.error === true)) {
                  this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
                }
              }
            }
            else {
              if (programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length) {
                if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 3)) {
                  this.chipErrorAndWarningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
                  if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._3 && element.error === false)) {
                    this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
                  }
                }
              }
              else {
                if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
                  if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 4)) {
                    this.chipErrorAndWarningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
                    if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._4 && element.error === false)) {
                      this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
                    }
                  }
                }
                if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
                  if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 2)) {
                    this.chipErrorAndWarningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
                    if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._2 && element.error === true)) {
                      this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
                    }
                  }
                }
              }
            }
          }
        }
        else {
          if (programAccrualRatesByMonths.length > 0 && existingMaterialRatesCount > 0 && existingMaterialRatesCount !== numberOfRateColumns) {
            if ((newMaterialRatesCount === emptyProgramAccrualRatesByMonths.length) && (existingMaterialRatesCount === emptyExistingProgramAccrualRatesByMonths.length)) {
              if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 1)) {
                this.chipErrorAndWarningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
                if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._1 && element.error === true)) {
                  this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
                }
              }
            }
            else {
              if (programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length && existingAllZeroProgramAccrualRatesByMonths.length === existingMaterialRatesCount) {
                if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 3)) {
                  this.chipErrorAndWarningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
                  if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._3 && element.error === false)) {
                    this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
                  }
                }
              }
              else {
                if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null) || existingProgramAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
                  if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 4)) {
                    this.chipErrorAndWarningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
                    if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._4 && element.error === false)) {
                      this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
                    }
                  }
                }
                if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100) || existingProgramAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
                  if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 2)) {
                    this.chipErrorAndWarningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
                    if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._2 && element.error === true)) {
                      this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!this.addedNewProgramAccrualRatesByMonth.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId)) {
        if (this.updateProgramAccrualRatesByMonth.length > 0) {
        }
        const updateProgramAccrualRatesByMonth: ProgramAccrualRatesByMonth[] = [];
        this.updateProgramAccrualRatesByMonth.forEach(uparbm => {
          updateProgramAccrualRatesByMonth.push({ id: uparbm.id, programId: uparbm.programId, materialHierarchyCommercialId: uparbm.materialHierarchyCommercialId, month: uparbm.month, year: uparbm.year, volumePercentage: uparbm.volumePercentage, payRatePercentage: uparbm.payRatePercentage, accrualPercentage: uparbm.accrualPercentage, statusDefinitionId: 0 })
        })
        let programAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let emptyProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let allZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let nonZeroProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let allZeroExistingProgramAccrualRatesByMonths: ProgramAccrualRatesByMonth[] = [];
        let materialRatesCount = 0;

        materialRatesCount = this.programAccrualRatesByMonth.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId).length;
        allZeroExistingProgramAccrualRatesByMonths = this.programAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        programAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(row => row.programId === programId && row.materialHierarchyCommercialId === materialHierarchyCommercialId);
        nonZeroProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage !== 0 || element.payRatePercentage !== 0);
        emptyProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === null && element.payRatePercentage === null && element.accrualPercentage === null);
        allZeroProgramAccrualRatesByMonths = updateProgramAccrualRatesByMonth.filter(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.volumePercentage === 0 && element.payRatePercentage === 0 && element.accrualPercentage === 0);
        if (programAccrualRatesByMonths.length > 0) {
          if (programAccrualRatesByMonths.length === emptyProgramAccrualRatesByMonths.length && emptyProgramAccrualRatesByMonths.length === materialRatesCount) {
            if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 1)) {
              this.chipErrorAndWarningMessages.push({ messageId: 1, message: this.accrualsMessages._1, error: true });
              if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._1 && element.error === true)) {
                this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._1, error: true });
              }
            }
          }
          else {
            if ((programAccrualRatesByMonths.length === allZeroProgramAccrualRatesByMonths.length && allZeroProgramAccrualRatesByMonths.length === materialRatesCount) || (materialRatesCount === allZeroProgramAccrualRatesByMonths.length + allZeroExistingProgramAccrualRatesByMonths.length - nonZeroProgramAccrualRatesByMonths.length)) {
              if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 3)) {
                this.chipErrorAndWarningMessages.push({ messageId: 3, message: this.accrualsMessages._3, error: false });
                if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._3 && element.error === false)) {
                  this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._3, error: false });
                }
              }
            }
            else {
              if (programAccrualRatesByMonths.some(element => element.volumePercentage === null || element.volumePercentage === 0 || element.payRatePercentage === 0 || element.payRatePercentage === null)) {
                if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 4)) {
                  this.chipErrorAndWarningMessages.push({ messageId: 4, message: this.accrualsMessages._4, error: false });
                  if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._4 && element.error === false)) {
                    this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._4, error: false });
                  }
                }
              }
              if (programAccrualRatesByMonths.some(element => element.accrualPercentage >= 100)) {
                if (!this.chipErrorAndWarningMessages.some(wm => wm.messageId === 2)) {
                  this.chipErrorAndWarningMessages.push({ messageId: 2, message: this.accrualsMessages._2, error: true });
                  if (!this.toolTipMessages.some(element => element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.message === this.accrualsMessages._2 && element.error === true)) {
                    this.toolTipMessages.push({ programId: programId, materialHierarchyCommercialId: materialHierarchyCommercialId, message: this.accrualsMessages._2, error: true });
                  }
                }
              }
            }
          }
        }
      }

      if (this.chipErrorAndWarningMessages.length > 0) {
        if (isErrorCheck) {
          if (this.chipErrorAndWarningMessages.some(element => element.error === true)) {
            return true;
          }
        }
        else {
          if (this.chipErrorAndWarningMessages.some(element => element.error === false)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getAccrualsHierarchyErrorAndWarningsFromAutoFillAssignRates(programId, autoFillAssignRates): AccrualsHierarchyErrorAndWarnings[] {
    let accrualsHierarchyErrorAndWarnings: AccrualsHierarchyErrorAndWarnings[] = [];
    autoFillAssignRates.forEach(afar => {
      if (afar.programId === programId) {
        accrualsHierarchyErrorAndWarnings.push({ programId: afar.programId, materialHierarchyCommercialId: afar.materialHierarchyCommercialId, performanceCenterCode: afar.performanceCenterCode, performanceCenterName: afar.performanceCenterName, productCenterCode: afar.productCenterCode, productCenterName: afar.productCenterName, plannedProductCode: afar.plannedProductCode, plannedProductName: afar.plannedProductName, tradeProductCode: afar.tradeProductCode, tradeProductName: afar.tradeProductName, materialCode: afar.materialCode, materialName: afar.materialName, isHavingError: false, isHavingWarning: false, errorMessage: null, warningMessage: null },);
      }
    })
    this.programsAccrualsErrorAndWarningsMessages.forEach(paewm => {
      accrualsHierarchyErrorAndWarnings.forEach(aheaw => {
        if (paewm.error) {
          if (paewm.programId === aheaw.programId && paewm.materialHierarchyCommercialId === aheaw.materialHierarchyCommercialId) {
            aheaw.isHavingError = paewm.error;
            aheaw.errorMessage = paewm.message;
          }
        }
        else {
          if (paewm.programId === aheaw.programId && paewm.materialHierarchyCommercialId === aheaw.materialHierarchyCommercialId) {
            aheaw.isHavingWarning = !paewm.error;
            aheaw.warningMessage = paewm.message;
          }
        }
      })
    })
    accrualsHierarchyErrorAndWarnings = accrualsHierarchyErrorAndWarnings.filter(row => row.isHavingError || row.isHavingWarning);
    return accrualsHierarchyErrorAndWarnings;
  }

  addAccrualsErrorAndWarnings() {
    this.programsAccrualsErrorAndWarnings = [];
    this.programsAccrualsAssignRates.forEach(paar => {
      this.programsAccrualsErrorAndWarnings.push({
        programId: paar.programId, programName: paar.programName,
        accrualsHierarchyErrorAndWarnings: this.getAccrualsHierarchyErrorAndWarningsFromAutoFillAssignRates(paar.programId, paar.autoFillAssignRates)
      })
    })
    this.programsAccrualsErrorAndWarnings = this.programsAccrualsErrorAndWarnings.filter(row => row.accrualsHierarchyErrorAndWarnings.length > 0)
  }

  getToolTipMessages(programId, materialHierarchyCommercialId, isError) {
    let strMessage = '';
    this.toolTipMessages.forEach(element => {
      if (element.programId === programId && element.materialHierarchyCommercialId === materialHierarchyCommercialId && element.error === isError) {
        strMessage += element.message.toString() + '\n';
      }
    });
    return strMessage;
  }

  createProgramsEmailList(isOnlyRemove) {
    this.programsEmails = [];
    if(isOnlyRemove){
    let accrualPrograms = this.accrualPrograms.filter(ap => ap.statusDefinitionId === this.statusDefinitionIds.APPROVAL_ROUTING || ap.statusDefinitionId === this.statusDefinitionIds.APPROVED);
     let removedInRoutingOrApprovedAccrualPrograms = this.submittedOrRemovedAccrualPrograms.filter(srap => srap.isCommentRequired);
     removedInRoutingOrApprovedAccrualPrograms.forEach(riaap => {
     let  inRoutingOrApprovedAccrualPrograms = accrualPrograms.filter(ap => ap.id === riaap.programId)[0];
     if(inRoutingOrApprovedAccrualPrograms){
      let emailOptions = this.buildEmail(inRoutingOrApprovedAccrualPrograms.programManagerEmail, inRoutingOrApprovedAccrualPrograms.statusDefinitionId, inRoutingOrApprovedAccrualPrograms.programCode, inRoutingOrApprovedAccrualPrograms.name, riaap.comment,isOnlyRemove);
      this.programsEmails.push({ programId: inRoutingOrApprovedAccrualPrograms.id, programType: this.programTableTypeIds.Program, emailDefinitionId: this.emailDefinitionIds.PRODUCTMGR_TO_PROGRAMMGR, programManagerId: inRoutingOrApprovedAccrualPrograms.programManagerId, emailOptions: JSON.parse(JSON.stringify(emailOptions)) },);
      }
   });
    }
    else{
      this.accrualPrograms.forEach(ap => {
        let comments = this.submittedOrRemovedAccrualPrograms.filter(sap => sap.programId === ap.id)[0]?.comment;
        if(this.submittedOrRemovedAccrualPrograms.some(srap => srap.programId === ap.id)){
          let emailOptions = this.buildEmail(ap.programManagerEmail, ap.statusDefinitionId, ap.programCode, ap.name, comments,isOnlyRemove);
          this.programsEmails.push({ programId: ap.id, programType: this.programTableTypeIds.Program, emailDefinitionId: this.emailDefinitionIds.PRODUCTMGR_TO_PROGRAMMGR, programManagerId: ap.programManagerId, emailOptions: JSON.parse(JSON.stringify(emailOptions)) },);
          }
      })
    }
  }

  buildEmail(programManagerEmail, statusDefinitionId, programCode, programName, comments,isOnlyRemove) {
    let comment;
    let emailSubject ;
    this.getUserName(this.core.getUserAccountName());
    this.programManagerEmail = [];
    this.programManagerEmail.push(programManagerEmail);
    let description ="Review the submitted products and accruals on your program <b>" + programName + " (" + programCode + ")</b>.";
    if (comments) {
      comment = "The Product Manager's comment: " + comments;
    }
    let nextSteps = "<li>Click on the link below to review the program and submitted products and accruals.</li>";
    let buttonLinkUrl = "";

    switch (statusDefinitionId) {
      case this.statusDefinitionIds.APPROVAL_ROUTING:
        buttonLinkUrl = 'programs/review-program/' + programCode;
        break;
      case this.statusDefinitionIds.DRAFT:
      case this.statusDefinitionIds.ACCRUALS_PENDING:
      case this.statusDefinitionIds.ACCRUALS_COMPLETED:
        buttonLinkUrl = this.canEditProgram() ? 'programs/edit-program/' + programCode : 'programs/review-program/' + programCode;
        break;
    }

    let buttonText = "Go to Program page";
   emailSubject = (!isOnlyRemove ? "New accruals submitted" : "Product removal") + " from" + this.userName;

    let toAddress = this.programManagerEmail;
    let ccAddress = [this.core.getUsername()];
    return this.emailService.buildActionEmailTemplate(!isOnlyRemove?this.actionMessages._1:this.actionMessages._5, description, nextSteps, buttonLinkUrl, buttonText, emailSubject, toAddress, ccAddress, comment);
  }

  getUserName(userName) {
    var splitUserName = userName.split(',')
    this.userName = splitUserName.slice(1, 2) + " " + splitUserName.slice(0, 1)
  }

  openSuccessSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['success-snackbar'] });
  }

  openErrorSnackBar(message: string, action: string, duration?: number) {
    this.matSnackBar.open(message, action, { duration, panelClass: ['error-snackbar'] });
  }
}


window.onbeforeunload = () => {
  let session = sessionStorage.getItem('ref');

  if (session == null) {

    localStorage.removeItem('Id_Settings');

  }
  sessionStorage.setItem('ref', "1");
}
